import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class OrganizationDataService {

  constructor(private http: HttpClient) { }

  baseUrl = environment.APIEndpoints;

  initHeaders() {
    let headers = new HttpHeaders()
    return headers;
  }

  getOrganizationName(organizationId: string){
    let headers = this.initHeaders();
    headers = headers.set('Accept', 'application/json');

    return this.http.get(this.baseUrl + '/test-manager/getOrganization/' + organizationId, { headers: headers, responseType:'text' });    
  }
 
}
