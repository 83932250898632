import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SubmissionPopupComponent } from '../../../commons/submission-popup/submission-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { FromDataService } from '../../../../service/FromDataService.service';
import { HttpClient } from '@angular/common/http';
import { ApplicationStateService } from '../../../../service/application-state.service';
import { TermsConditionsComponent } from '../../../commons/footer/terms-conditions/terms-conditions.component';

import { EmailJSResponseStatus, send } from 'emailjs-com';
import { LanguageService } from '../../../../service/LanguageService.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css'],
})
export class ContactFormComponent implements AfterViewInit {
  public contactForm: FormGroup;
  formService: FromDataService | any;
  sending: boolean = false;

  public isMobileResolution: boolean;

  constructor(
    private _httpClient: HttpClient,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private applicationStateService: ApplicationStateService,
    private languageService: LanguageService
  ) {
    this.isMobileResolution = applicationStateService.getIsMobileResolution();
    this.contactForm = this.formBuilder.group({
      name: '',
      company: '',
      email: '',
      phone: '',
      comments: '',
    });
  }

  ngAfterViewInit(): void {
    this.formService = new FromDataService(this._httpClient);
  }

  onSubmit(contactData: any) {
    this.sending = true;
    send('service_zh5k08e', 'template_4o0pqif', {
      ...contactData,
      form_name: 'Contact Form',
    }).then(
      () => {
        this.sending = false;
        this.openDialog();        
        console.log('Contact data has been submitted');
      },
      (error) => {
        this.sending = false;
        alert(
          this.languageService.currentLang === 'es'
            ? 'Ha ocurrido de un error y no se han enviado los datos. Inténtalo de nuevo o envía un correo a info@honey-ai.com'
            : 'An error has occurred and the data has not been sent. Please try again or send an email to info@honey-ai.com'
        );
        console.log('Error submitting:', (error as EmailJSResponseStatus).text);
      }
    );
  }

  openDialog() {
    const data = {
      data:
        this.languageService.currentLang === 'es'
          ? {
              title: 'Gracias por completar nuestro formulario de contacto',
              body: '¡Nos pondremos en contacto contigo muy pronto con más información!',
              textButton: 'Volver a inicio',
            }
          : {
              title: 'Thank you for completing our contact form',
              body: 'We will come back to you very soon with more information!',
              textButton: 'Back to home',
            },
    };
    this.dialog.open(SubmissionPopupComponent, data);
  }

  showPrivacyPolicy() {
    this.dialog.open(TermsConditionsComponent);
  }
}
