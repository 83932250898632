import { Component, OnInit } from '@angular/core';
import { ApplicationStateService } from './service/application-state.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'Microfy.AI';

  public isMobileResolution: boolean;

  constructor(
    private applicationStateService: ApplicationStateService,
    private translateService: TranslateService,
    private router: Router
  ) {
    this.isMobileResolution = applicationStateService.getIsMobileResolution();
  }

  ngOnInit(): void {
    const lang = this.router.url.split('/')[1];

    if (lang === 'es' || lang === 'en') {
      this.translateService.use(lang);
    } else if (lang !== '') {
      
      this.translateService.use('es');
      this.router.navigateByUrl('/es/home');
    }
  }

  onActivate(e, outlet) {
    outlet.scrollTop = 0;
  }
}
