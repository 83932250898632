<div [class]="!isMobileResolution? 'contact-us-cards-div': 'contact-us-cards-div-mobile'">
  <!-- Visit us Card -->
  <mat-card [class]="!isMobileResolution? 'contact-card': 'contact-card-mobile'">
    <img mat-card-image
         src="assets/commons/contact-us/visitu-us-icon.svg"
         alt="Visit us - icon">
    <mat-card-content>
      <p [class]="!isMobileResolution? 'card-content-title': 'home-info-subtitle-mobile'"> {{'Visit us' | translate }} </p>
      <p [class]="!isMobileResolution? 'card-content-body': 'home-info-body-mobile'" >
        {{'Avenida Carrilet 243, 1º 2ª' | translate }} <br>
        {{'08907 Hospitalet de Llobregat' | translate }} <br>
        {{'Barcelona, Spain.' | translate }}
      </p>
    </mat-card-content>
    <mat-card-actions>
      <a style="width: 100%;" target="_blank" href="https://www.google.com/maps/place/Microfy+Systems,+S.L./@41.3597951,2.1064029,17z/data=!3m1!4b1!4m5!3m4!1s0x12a49945aee0ae15:0xff1dd7f17ba0c55d!8m2!3d41.3598706!4d2.1085386">
        <button mat-button class="card-buttons card-button-text">{{'Google Maps' | translate }}</button>
      </a>
    </mat-card-actions>
  </mat-card>

  <!-- Let's talk Card -->
  <mat-card [class]="!isMobileResolution? 'contact-card': 'contact-card-mobile'">
    <img mat-card-image
         src="assets/commons/contact-us/lets-talk-icon.svg"
         alt="Let's talk - icon">
    <mat-card-content>
      <p [class]="!isMobileResolution? 'card-content-title': 'home-info-subtitle-mobile'"> {{'Let\'s talk' | translate }}</p>
      <p [class]="!isMobileResolution? 'card-content-body': 'home-info-body-mobile'">
        info@honey-ai.com <br>
        +34 623 000 199 <br>
      </p>
    </mat-card-content>
    <mat-card-actions>
      <a href="tel:+34623000199" style="width: 100%;">
        <button mat-button class="card-buttons card-button-text">{{'Call' | translate }}</button>
      </a>
      <a href="mailto:info@honey-ai.com" style="width: 100%;">
        <button mat-button class="card-buttons card-button-text">{{'Email' | translate }}</button>
      </a>
    </mat-card-actions>
  </mat-card>

  <!-- FAQs Card -->
  <mat-card [class]="!isMobileResolution? 'contact-card': 'contact-card-mobile'">
    <img mat-card-image
         src="assets/commons/contact-us/FAQs-icon.svg"
         alt="FAQs - icon">
    <mat-card-content>
      <p [class]="!isMobileResolution? 'card-content-title': 'home-info-subtitle-mobile'"> {{'FAQs' | translate }} </p>
      <p [class]="!isMobileResolution? 'card-content-body': 'home-info-body-mobile'">
        {{'Check our section of' | translate }} <br>
        {{'Frequently Asked Questions' | translate }} <br>
        {{'to solve your doubts.' | translate }}
      </p>
    </mat-card-content>
    <mat-card-actions>
      <a [routerLink]="languageService.getPath('/FAQs')" style="width: 100%">
        <button mat-button class="card-buttons card-button-text">{{'Visit FAQs' | translate }}</button>
      </a>
    </mat-card-actions>
  </mat-card>

  <!-- Newsletter Card -->
  <mat-card class="newsletter-card" [class]="!isMobileResolution? 'contact-card': 'contact-card-mobile'">
    <img mat-card-image
         src="assets/commons/contact-us/newsletter.png"
         alt="Newsletter - icon">
    <mat-card-content>
      <p [class]="!isMobileResolution? 'card-content-title': 'home-info-subtitle-mobile'"> Newsletter </p>
      <p [class]="!isMobileResolution? 'card-content-body': 'home-info-body-mobile'">
        {{'newsletter' | translate }}
      </p>
    </mat-card-content>
    <mat-card-actions>

      <input class="c-checkbox" type="checkbox" id="checkbox">

      <div class="newsletter-form-container">
        <form class="newsletter-form" [formGroup]="newsletterForm" (ngSubmit)="onSubmitNewsletter()">

          <input class="newsletter-form__input" placeholder="E-mail" type="email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$" formControlName="email" required>
          <label class="newsletter-form__buttonLabel" for="checkbox">
            <button class="newsletter-form__button" type="submit">

              <img
              src="assets/commons/contact-us/send.png"
              alt="send - icon">

            </button>
          </label>
          <label class="newsletter-form__toggle" for="checkbox">{{'subscribeme' | translate}}</label>
          <label [style.visibility]="thanksSubmitNewsletterVisibility" class="newsletter-form__toggle-thanks" for="checkbox">{{'thanks!' | translate}}</label>
        </form>
      </div>

    </mat-card-actions>
  </mat-card>
</div>
