<div
  class="pre-orders-content"
  *ngIf="!isMobileResolution; else mobilePreOrdersFormContent"
  style="border: solid #e9bd02; padding: 3%; margin: 4% 30% 4% 30% !important"
>
  <div class="centered-honey-text-div flex">
    <p class="text-title-honey text-align-center">
      {{ "sing-up-form" | translate }}
    </p>
    <p class="text-subtitle-honey text-align-center">
      {{ companyName }}
    </p>
    <p class="text-content-honey text-align-center">
      {{ "sing-up-form-body" | translate }}
    </p>
  </div>

  <form (ngSubmit)="onSubmit(preOrdersForm.value)" [formGroup]="preOrdersForm">
    <div class="flex">
      <input
        type="text"
        class="form-control form-input-honey form-small-input-height"
        id="firstName"
        required
        formControlName="firstName"
        placeholder="{{ 'Name' | translate }}"
      />
    </div>

    <div class="flex">
      <input
        type="text"
        class="form-control form-input-honey form-small-input-height"
        id="lastName"
        required
        formControlName="lastName"
        placeholder="{{ 'Surname' | translate }}"
      />
    </div>
    <div class="flex">
      <input
        type="email"
        class="form-control form-input-honey form-small-input-height"
        id="email"
        required
        formControlName="email"
        placeholder="{{ 'E-mail' | translate }}"
      />
    </div>
    <div class="flex">
      <input
        type="text"
        class="form-control form-input-honey form-small-input-height"
        id="username"
        required
        formControlName="username"
        placeholder="{{ 'Username' | translate }}"
      />
    </div>
    <div class="flex">
      <input
        type="password"
        class="form-control form-input-honey form-small-input-height"
        id="password"
        required
        formControlName="password"
        placeholder="{{ 'Password' | translate }}"
      />
      <span
        class="password-toggle"
        (click)="togglePasswordVisibility('password')"
        style="position: absolute; right: 35%;margin-top: 1%;cursor: pointer;"
      >
        <img 
          src="../../../../assets/password-icons/eye.png"
          style="max-width: 35px;"
          
        >
      </span>
    </div>
    <div class="flex">
      <input
        type="password"
        class="form-control form-input-honey form-small-input-height"
        id="password1"
        required
        formControlName="password1"
        placeholder="{{ 'Repeat password' | translate }}"
      />
      <span
        class="password-toggle"
        (click)="togglePasswordVisibility('password1')"
        style="position: absolute; right: 35%;margin-top: 1%;cursor: pointer;"
      >
        <img 
          src="../../../../assets/password-icons/eye.png"
          style="max-width: 35px;"
          
        >
      </span>
    </div>
    <div class="flex place-content-center margin-top">
      <button type="submit" class="yellow-honey-button" style="width: 30%">
        {{ "Send" | translate }}
      </button>
    </div>
  </form>

  <div class="terms-conditions-checkbox">
    <section>
      <mat-checkbox [checked]="false" [disabled]="false"> </mat-checkbox>
    </section>
    <p class="font-family" style="margin-left: 10px">
      {{ "I have read and agree to the" | translate }}
      <!--        <button class="terms-button" (click)="showTermsConditions()"> {{'terms-and-conditions' | translate}}</button>-->
      <!--        {{'and' | translate}}-->
      <button class="terms-button" (click)="showPrivacyPolicy()">
        {{ "privacy-policy" | translate }}
      </button>
    </p>
  </div>
</div>

<ng-template #mobilePreOrdersFormContent>
  <div class="centered-honey-text-div flex">
    <p class="text-title-honey text-align-center">
      {{ "sing-up-form" | translate }}
    </p>
    <p class="text-subtitle-honey text-align-center">
      {{ companyName }}
    </p>
    <p class="text-content-honey text-align-center">
      {{ "sing-up-form-body" | translate }}
    </p>
  </div>

  <form (ngSubmit)="onSubmit(preOrdersForm.value)" [formGroup]="preOrdersForm">
    <div class="flex">
      <input
        type="text"
        class="form-control form-input-honey form-small-input-height"
        id="name"
        required
        formControlName="name"
        placeholder="{{ 'Name' | translate }}"
      />
    </div>

    <div class="flex">
      <input
        type="text"
        class="form-control form-input-honey form-small-input-height"
        id="surname"
        required
        formControlName="surname"
        placeholder="{{ 'Surname' | translate }}"
      />
    </div>
    <div class="flex">
      <input
        type="email"
        class="form-control form-input-honey form-small-input-height"
        id="email"
        required
        formControlName="email"
        placeholder="{{ 'E-mail' | translate }}"
      />
    </div>
    <div class="flex">
      <input
        type="text"
        class="form-control form-input-honey form-small-input-height"
        id="username"
        required
        formControlName="username"
        placeholder="{{ 'Username' | translate }}"
      />
    </div>
    <div class="flex">
      <input
        type="password"
        class="form-control form-input-honey form-small-input-height"
        id="password"
        required
        formControlName="password"
        placeholder="{{ 'Password' | translate }}"
      />
      <span
        class="password-toggle"
        (click)="togglePasswordVisibility('password')"
        style="position: absolute; right: 7%;margin-top: 3%;cursor: pointer;"
      >
        <img 
          src="../../../../assets/password-icons/eye.png"
          style="max-width: 35px;"
          
        >
      </span>
    </div>
    <div class="flex">
      <input
        type="password"
        class="form-control form-input-honey form-small-input-height"
        id="password1"
        required
        formControlName="password1"
        placeholder="{{ 'Repeat password' | translate }}"
      />
      <span
        class="password-toggle"
        (click)="togglePasswordVisibility('password1')"
        style="position: absolute; right: 7%;margin-top: 3%;cursor: pointer;"
      >
        <img 
          src="../../../../assets/password-icons/eye.png"
          style="max-width: 35px;"
          
        >
      </span>
    </div>
    <div class="flex place-content-center margin-top">
      <button type="submit" class="yellow-honey-button" style="width: 30%">
        {{ "Send" | translate }}
      </button>
    </div>
  </form>

  <div class="terms-conditions-checkbox">
    <section>
      <mat-checkbox [checked]="false" [disabled]="false"> </mat-checkbox>
    </section>
    <p class="font-family" style="margin-left: 10px">
      {{ "I have read and agree to the" | translate }}
      <!--        <button class="terms-button" (click)="showTermsConditions()"> {{'terms-and-conditions' | translate}}</button>-->
      <!--        {{'and' | translate}}-->
      <button class="terms-button" (click)="showPrivacyPolicy()">
        {{ "privacy-policy" | translate }}
      </button>
    </p>
  </div>
</ng-template>
