<div class="cristallization-degree-container">

    <div class="header-title">
        <h1> {{ 'crystallization-degree-title' | translate }} </h1>
    </div>

    <div class="what-is-container-video">

        <div class="container-video">
            <video style="width: 100%" autoplay [muted]="'muted'">
                <source src="../../../../assets/commons/pexels-roman-odintsov.mov" />
                Your browser does not support the video tag.
            </video>
        </div>

        <div class="container-text">
            <div class="left-side">
                <h3 class="subtitle">
                    {{ 'crystallization-degree-what-is' | translate }}
                </h3>

                <div class="text">
                    {{ 'crystallization-degree-what-is-text-1' | translate }}
                </div>

                <div class="text">
                    {{ 'crystallization-degree-what-is-text-2' | translate }}
                </div>
            </div>
        </div>
    </div>

    <div class="flip-cards-container">

        <h3 class="subtitle">
            {{ 'crystallization-degree-flip-cards-title' | translate }}
        </h3>

        <div class="benefits-cards">

            <!-- Cards -->
            <app-flip-card *ngFor="let cardData of vFlipCardData" [itemFlipCard]="cardData"></app-flip-card>

        </div>

    </div>


    <div class="how-it-helps">

        <div class="left-side">
            <div class="gif-content">
                <img src="../../../../assets/crystalization-dregree/crystalization-scan.png" />
            </div>
        </div>

        <div class="right-side">
            <div class="container-text">
                <div class="subtitle">
                    {{ 'cystallization-degree-how-helps-title' | translate }}
                </div>

                <div class="text" [innerHTML]="'cystallization-degree-how-helps-text-1' | translate"></div>

                <div class="text">
                    {{ 'cystallization-degree-how-helps-text-2' | translate }}
                </div>

                <div class="list">
                    <div class="list-item">
                        <span></span>{{ 'cystallization-degree-how-helps-text-3' | translate }}
                    </div>
                    <div class="list-item">
                        <span></span>{{ 'cystallization-degree-how-helps-text-4' | translate }}
                    </div>
                    <div class="list-item">
                        <span></span>{{ 'cystallization-degree-how-helps-text-5' | translate }}
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="download-example-crystallization-degree">

        <div class="text-download-example-content">
            {{ 'cystallization-degree-want-to-see-example' | translate }}
        </div>

        <div class="button-download-content">
            <a class="button-download-pdf"
                href="../../../../assets/pdf/Ejemplo Cristales.pdf"
                download>
                {{ 'download-pdf-here' | translate }}
            </a>
        </div>

    </div>

    <div class="video-crystallization-degree">
        <div class="left-side">
            <div class="subtitle">
                {{ 'cystallization-degree-best-control-title' | translate }}
            </div>
            <div class="text">
                {{ 'cystallization-degree-best-control-text' | translate }}
            </div>
        </div>

        <div class="right-side">
            <div class="video">
                <img class="border-video-top" src="../../../../assets/commons/border-video-yt.png" />
                <iframe width="720" height="405"
                    src="https://www.youtube.com/embed/sRmqsA5fCpI?controls=0&showinfo=0&rel=0"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen></iframe>
                <img class="border-video-bottom" src="../../../../assets/commons/border-video-yt.png" />
            </div>
            <div class="arrow-content">
                <div class="text">
                    {{ 'cystallization-degree-best-control-video-text' | translate }}
                </div>
                <div class="arrow">
                    <img src="../../../../assets/crystalization-dregree/curved-white-arrow.png" />
                </div>
            </div>
        </div>
    </div>


</div>
