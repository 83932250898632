import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { FLIP_CARD_PARAGRAPH_TYPE, FlipCardData } from '../../commons/flip-card/flip-card.component';
import { Router } from '@angular/router';

interface HexColorIconData {
  iconName: string;
  text: string;
  squareText: string;
}

@Component({
  selector: 'app-tech-pfund-color-grading',
  templateUrl: './tech-pfund-color-grading.component.html',
  styleUrls: ['./tech-pfund-color-grading.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TechPfundColorGradingComponent implements OnInit {

  pFundTitle: string = 'pfund-title';
  pFundSubTitle: string = 'pfund-subtitle';
  pFundVText: string[] = [
    'pfund-header-text-1',
    'pfund-header-text-2',
    'pfund-header-text-3'
  ];
  pFundVCarousel: string[] = [
    'pfund-hex-carousel-0.png',
    'pfund-hex-carousel-1.png',
    'pfund-hex-carousel-2.png',
    'pfund-hex-carousel-3.png',
    'pfund-hex-carousel-4.png'
  ]

  // Flip Cards
  vFlipCardData: FlipCardData[] = [
    {
      iconName: 'circle-icon-correcta-clasificacion.png',
      cardName: 'pfund-flip-card-1-title',
      vString: [
        { text: 'pfund-flip-card-1-text-1', type: FLIP_CARD_PARAGRAPH_TYPE },
      ]
    },
    {
      iconName: 'circle-icon-evitar-problemas-lineales.png',
      cardName: 'pfund-flip-card-2-title',
      vString: [
        { text: 'pfund-flip-card-2-text-1', type: FLIP_CARD_PARAGRAPH_TYPE },
      ]
    },
    {
      iconName: 'circle-icon-estudiar-comparar-mieles.png',
      cardName: 'pfund-flip-card-3-title',
      vString: [
        { text: 'pfund-flip-card-3-text-1', type: FLIP_CARD_PARAGRAPH_TYPE },
        { text: 'pfund-flip-card-3-text-2', type: FLIP_CARD_PARAGRAPH_TYPE },
      ]
    }
  ]

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    // Scroll to top
    window.scroll({ top: 0, left: 0 });
  }

  @HostListener('click', ['$event'])
  processLinks(e) {
    const element: HTMLElement = e.target;
    if (element.nodeName === 'A') {
      if (element.textContent === 'Pack de Inicio' || element.textContent === 'Starter Pack') {
        e.preventDefault();
        localStorage.setItem('goToStarterPack', 'true');
        this.router.navigate(['/pricing'], { fragment: 'starter-pack-panel'});
      }
    }
  }
}
