<div
  class="pre-orders-content"
  *ngIf="!isMobileResolution; else mobilePreOrdersFormContent"
>
  <div class="centered-honey-text-div flex">
    <p class="text-title-honey text-align-center">
      {{ "pre-order-form-title" | translate }}
    </p>
    <p class="text-subtitle-honey text-align-center">
      {{ "pre-order-form-subtitle" | translate }}
    </p>
    <p class="text-content-honey text-align-center">
      {{ "pre-order-form-body" | translate }}
    </p>
  </div>

  <form (ngSubmit)="onSubmit(preOrdersForm.value)" [formGroup]="preOrdersForm">
    <div class="flex margin-top">
      <input
        type="text"
        class="form-control form-input-honey form-small-input-height"
        id="name"
        required
        formControlName="name"
        placeholder="{{ 'Name' | translate }}"
      />
      <input
        type="text"
        class="form-control form-input-honey form-small-input-height"
        id="surname"
        required
        formControlName="surname"
        placeholder="{{ 'Surname' | translate }}"
      />
    </div>
    <div class="flex">
      <input
        type="email"
        class="form-control form-input-honey form-small-input-height"
        id="email"
        required
        formControlName="email"
        placeholder="{{ 'E-mail' | translate }}"
      />
      <input
        type="number"
        class="form-control form-input-honey form-small-input-height"
        id="phone"
        required
        formControlName="phone"
        placeholder="{{ 'Phone' | translate }}"
      />
    </div>
    <div class="flex">
      <input
        type="email"
        class="form-control form-input-honey form-small-input-height"
        id="company"
        required
        formControlName="company"
        placeholder="{{ 'Company' | translate }}"
      />
      <input
        type="text"
        class="form-control form-input-honey form-small-input-height"
        id="country"
        required
        formControlName="country"
        placeholder="{{ 'Country' | translate }}"
      />
    </div>
    <div class="flex">
      <input
        type="text"
        class="form-control form-input-honey form-small-input-height"
        id="deliveryAddr"
        required
        formControlName="deliveryAddr"
        placeholder="{{ 'Delivery-address' | translate }}"
      />
      <mat-form-field
        class="form-control form-input-honey form-small-input-height"
      >
        <mat-label>{{ "Estimated-range-year" | translate }}</mat-label>
        <mat-select [(value)]="selected" required>
          <mat-option value="-50">{{ "less-50" | translate }}</mat-option>
          <mat-option value="50-100">50-100</mat-option>
          <mat-option value="100-200">100-200</mat-option>
          <mat-option value="200-500">200-500</mat-option>
          <mat-option value="+500">{{ "more-500" | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="flex place-content-center margin-top">
      <button
        type="submit"
        class="yellow-honey-button"
        [disabled]="sending"
        style="width: 30%"
      >
        {{ "send" | translate }}
      </button>
    </div>
  </form>

  <div class="terms-conditions-checkbox">
    <section>
      <mat-checkbox [checked]="false" [disabled]="false"> </mat-checkbox>
    </section>
    <p class="font-family" style="margin-left: 10px">
      {{ "I have read and agree to the" | translate }}
      <!--        <button class="terms-button" (click)="showTermsConditions()"> {{'terms-and-conditions' | translate}}</button>-->
      <!--        {{'and' | translate}}-->
      <button class="terms-button" (click)="showPrivacyPolicy()">
        {{ "privacy-policy" | translate }}
      </button>
    </p>
  </div>
</div>

<ng-template #mobilePreOrdersFormContent>
  <div class="info-div-mobile">
    <p class="home-info-title-mobile">
      {{ "pre-order-form-title" | translate }}
    </p>
    <p class="home-info-subtitle-mobile">
      {{ "pre-order-form-subtitle" | translate }}
    </p>
    <p class="home-info-body-mobile">
      {{ "pre-order-form-body" | translate }}
    </p>
  </div>

  <form (ngSubmit)="onSubmit(preOrdersForm.value)" [formGroup]="preOrdersForm">
    <div class="margin-top">
      <input
        type="text"
        class="form-control form-input-honey form-small-input-height-mobile"
        id="name"
        required
        formControlName="name"
        placeholder="{{ 'Name' | translate }}"
      />
      <input
        type="text"
        class="form-control form-input-honey form-small-input-height-mobile"
        id="surname"
        required
        formControlName="surname"
        placeholder="{{ 'Surname' | translate }}"
      />
      <input
        type="email"
        class="form-control form-input-honey form-small-input-height-mobile"
        id="email"
        required
        formControlName="email"
        placeholder="{{ 'E-mail' | translate }}"
      />
      <input
        type="number"
        class="form-control form-input-honey form-small-input-height-mobile"
        id="phone"
        required
        formControlName="phone"
        placeholder="{{ 'Phone' | translate }}"
      />
      <input
        type="email"
        class="form-control form-input-honey form-small-input-height-mobile"
        id="company"
        required
        formControlName="company"
        placeholder="{{ 'Company' | translate }}"
      />
      <input
        type="text"
        class="form-control form-input-honey form-small-input-height-mobile"
        id="country"
        required
        formControlName="country"
        placeholder="{{ 'Country' | translate }}"
      />
      <input
        type="text"
        class="form-control form-input-honey form-small-input-height-mobile"
        id="deliveryAddr"
        required
        formControlName="deliveryAddr"
        placeholder="{{ 'Delivery-address' | translate }}"
      />
      <mat-form-field
        class="form-control form-input-honey form-small-input-height-mobile"
      >
        <mat-label>{{ "Estimated-range-year" | translate }}</mat-label>
        <mat-select [(value)]="selected" required>
          <mat-option value="-50">{{ "less-50" | translate }}</mat-option>
          <mat-option value="50-100">50-100</mat-option>
          <mat-option value="100-200">100-200</mat-option>
          <mat-option value="200-500">200-500</mat-option>
          <mat-option value="+500">{{ "more-500" | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="flex place-content-center margin-top">
      <button
        type="submit"
        class="yellow-honey-button"
        [disabled]="sending"
        style="width: 80%"
      >
        {{ "send" | translate }}
      </button>
    </div>
  </form>

  <div class="terms-conditions-checkbox">
    <section>
      <mat-checkbox [checked]="true" [disabled]="true"> </mat-checkbox>
    </section>
    <p class="font-family" style="margin-left: 10px">
      {{ "I have read and agree to the" | translate }}
      <button class="terms-button" (click)="showPrivacyPolicy()">
        {{ "privacy-policy" | translate }}
      </button>
    </p>
  </div>
</ng-template>
