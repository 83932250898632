<div *ngIf="!isMobileResolution; else mobileContactFormContent">
  <form (ngSubmit)="onSubmit(contactForm.value)" [formGroup]="contactForm">
    <div class="flex margin-top">
      <input
        type="text"
        class="form-control form-input-honey form-small-input-height"
        id="name"
        required
        formControlName="name"
        placeholder="{{ 'Name' | translate }}"
      />
      <input
        type="text"
        class="form-control form-input-honey form-small-input-height"
        id="company"
        required
        formControlName="company"
        placeholder="{{ 'Company' | translate }}"
      />
    </div>
    <div class="flex">
      <input
        type="email"
        class="form-control form-input-honey form-small-input-height"
        id="email"
        required
        formControlName="email"
        placeholder="{{ 'E-mail' | translate }}"
      />
      <input
        type="number"
        class="form-control form-input-honey form-small-input-height"
        id="phone"
        required
        formControlName="phone"
        placeholder="{{ 'Phone' | translate }}"
      />
    </div>
    <div class="flex">
      <textarea
        class="form-control form-input-honey form-big-input-height"
        id="comments"
        formControlName="comments"
        placeholder="{{ 'Comments' | translate }}"
      ></textarea>
    </div>
    <div class="flex place-content-center margin-top">
      <button
        type="submit"
        class="yellow-honey-button"
        [disabled]="sending"
        style="width: 30%"
      >
        {{ "send" | translate }}
      </button>
    </div>
  </form>
</div>

<div class="terms-conditions-checkbox">
  <section>
    <mat-checkbox [checked]="false"> </mat-checkbox>
  </section>
  <p class="font-family" style="margin-left: 10px">
    {{ "I have read and agree to the" | translate }}
    <!--    <button class="terms-button" (click)="showTermsConditions()"> {{'terms-and-conditions' | translate}}</button>-->
    <!--    {{'and' | translate}}-->
    <button class="terms-button" (click)="showPrivacyPolicy()">
      {{ "privacy-policy" | translate }}
    </button>
  </p>
</div>

<ng-template #mobileContactFormContent>
  <form (ngSubmit)="onSubmit(contactForm.value)" [formGroup]="contactForm">
    <div class="info-div-mobile">
      <input
        type="text"
        class="form-control form-input-honey-mobile form-small-input-height"
        id="name"
        required
        formControlName="name"
        placeholder="{{ 'Name' | translate }}"
      />
      <input
        type="text"
        class="form-control form-input-honey-mobile form-small-input-height"
        id="company"
        required
        formControlName="company"
        placeholder="{{ 'Company' | translate }}"
      />
      <input
        type="email"
        class="form-control form-input-honey-mobile form-small-input-height"
        id="email"
        required
        formControlName="email"
        placeholder="{{ 'E-mail' | translate }}"
      />
      <input
        type="number"
        class="form-control form-input-honey-mobile form-small-input-height"
        id="phone"
        required
        formControlName="phone"
        placeholder="{{ 'Phone' | translate }}"
      />
      <textarea
        class="form-control form-input-honey-mobile form-big-input-height"
        id="comments"
        formControlName="comments"
        placeholder="{{ 'Comments' | translate }}"
      ></textarea>
    </div>

    <div class="margin-top info-div-mobile">
      <button
        type="submit"
        class="yellow-honey-button"
        [disabled]="sending"
        style="width: 80%"
      >
        {{ "send" | translate }}
      </button>
    </div>
  </form>
</ng-template>
