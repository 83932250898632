import { Component, ViewChild } from '@angular/core';
import { ApplicationStateService } from '../../../service/application-state.service';
import { environment } from '../../../../environments/environment';
import { MatMenuTrigger } from '@angular/material/menu';
import { LanguageService } from 'src/app/service/LanguageService.service';

@Component({
  selector: 'app-tabsbar',
  templateUrl: './tabsbar.component.html',
  styleUrls: ['./tabsbar.component.css'],
})
export class TabsbarComponent {
  privateUrl = environment.privateUrl;

  homeLink = '/home';
  links = ['/pricing', '/pre-orders', '/about-us', '/news-events', '/FAQs'];
  titles = ['Pricing', 'pre-orders', 'About-us', 'news-events', 'FAQs'];
  disabled = [false, false, false, false, false, true];
  activeLink: string;
  techOptionsMobile = [
    {
      title: 'tech-opt-honey-ai-as-a-whole',
      path: '/technology/honey-ai-whole',
    },
    { title: 'tech-opt-pollen-analysis', path: '/technology/pollen-analysis' },
    {
      title: 'tech-opt-crystallization-degree',
      path: '/technology/crystallization-degree',
    },
    { title: 'tech-opt-yeasts-counting', path: '/technology/yeast-counting' },
    { title: 'tech-opt-conductivity', path: '/technology/conductivity' },
    {
      title: 'tech-opt-honeydew-analysis',
      path: '/technology/honeydew-analysis',
    },
    {
      title: 'tech-opt-pfund-colour-grading',
      path: '/technology/pfund-color-grading',
    },
    {
      title: 'tech-opt-your-custom-application',
      path: '/technology/your-custom-application',
    },
    {
      title: 'tech-opt-diagnosis-bee-infections',
      path: '/technology/bee-health',
    },
  ];

  techOptions = [
    {
      title: 'tech-opt-honey-ai-as-a-whole',
      path: '/technology/honey-ai-whole',
    },
    { title: 'tech-opt-conductivity', path: '/technology/conductivity' },
    { title: 'tech-opt-pollen-analysis', path: '/technology/pollen-analysis' },
    {
      title: 'tech-opt-honeydew-analysis',
      path: '/technology/honeydew-analysis',
    },
    {
      title: 'tech-opt-crystallization-degree',
      path: '/technology/crystallization-degree',
    },
    {
      title: 'tech-opt-diagnosis-bee-infections',
      path: '/technology/bee-health',
    },
    {
      title: 'tech-opt-pfund-colour-grading',
      path: '/technology/pfund-color-grading',
    },
    {
      title: 'tech-opt-your-custom-application',
      path: '/technology/your-custom-application',
    },
    { title: 'tech-opt-yeasts-counting', path: '/technology/yeast-counting' },
  ];
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;

  showMobileMenu: boolean = false;
  showMobileSubMenu: boolean = false;

  public isMobileResolution: boolean;

  constructor(
    private applicationStateService: ApplicationStateService,
    public languageService: LanguageService
  ) {
    this.isMobileResolution = applicationStateService.getIsMobileResolution();
  }

  mouseEnterButton() {
    this.trigger.openMenu();
  }

  mouseLeaveMenu() {
    this.trigger.closeMenu();
  }
}
