import { Component, OnInit } from '@angular/core';
import { FAQsCardService } from './service/FAQsCard.service';
import { ApplicationStateService } from '../../service/application-state.service';
import { LanguageService } from '../../service/LanguageService.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css'],
})
export class FAQsComponent implements OnInit {
  public isMobileResolution: boolean;

  constructor(
    public dataService: FAQsCardService,
    public languageService: LanguageService,
    private applicationStateService: ApplicationStateService
  ) {
    this.isMobileResolution = applicationStateService.getIsMobileResolution();
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

  selectFAQCard(number: number) {
    this.dataService.setValue(number);
  }
}
