<div class="your-custom-application-container">


    <div class="header-title">
        <h1> {{ 'your-custom-application-title' | translate }} </h1>
    </div>

    <div class="yca-container-video">

        <div class="container-video">
            <video style="width: 100%" autoplay [muted]="'muted'" loop>
                <source src="../../../../assets/your-custom-application/your-custom-application-2.MOV" />
                Your browser does not support the video tag.
            </video>
        </div>

        <div class="container-text">
            <div class="left-side">
                <h3 class="subtitle-1">
                    {{ 'your-custom-application-subtitle-1' | translate }}
                </h3>

                <div class="text">
                    {{ 'your-custom-application-text-1' | translate }}
                </div>

                <h3 class="subtitle-2">
                    {{ 'your-custom-application-subtitle-2' | translate }}
                </h3>

                <div class="text">
                    {{ 'your-custom-application-text-2' | translate }}
                </div>
            </div>
        </div>
    </div>

    <div class="white-container">

        <div class="white-container-big-text">
            {{ 'your-custom-application-text-3' | translate }}
        </div>

        <div class="applications-list-container">

            <div class="left-side">
                <img class="icon-yellow" src="../../../../assets/your-custom-application/icon-microscopio.png" />
                <img class="icon-arrows" src="../../../../assets/your-custom-application/icon-flechas.png" />
                <img class="icon-yellow" src="../../../../assets/your-custom-application/icon-ia.png" />
            </div>

            <div class="right-side">
                <div class="title-applications">
                    {{ 'your-custom-application-text-4' | translate }}
                </div>

                <div class="text-applications" *ngFor="let application of vApplications">
                    <div class="icon">
                        <img src="../../../../assets/your-custom-application/icon-lupa.png" />
                    </div>
                    <div class="text">
                        {{ application | translate }}
                    </div>
                </div>
            </div>
        </div>

        <div class="contact-container">
            <div class="contact-text" [innerHTML]="'your-custom-application-text-11' | translate">
            </div>
            <button class="button-contact" [routerLink]="languageService.getPath('/contact')">
                {{ 'your-custom-application-text-button-contact' | translate }}
            </button>
        </div>

    </div>

    <div class="gray-container">

        <div class="left-side">

            <div class="video-container">
                <video autoplay [muted]="'muted'" loop>
                    <source src="../../../../assets/your-custom-application/your-custom-application-2.MOV" />
                    Your browser does not support the video tag.
                </video>
            </div>

        </div>

        <div class="right-side">
            <div class="subtitle">
                {{ 'your-custom-application-subtitle-3' | translate }}
            </div>

            <div class="list-item" *ngFor="let itemList of vListItems">
                <div class="icon">
                    <img [src]="imgPath + itemList.iconNumber" />
                </div>
                <div class="text">
                    <div class="bold-cap-text">
                        {{ itemList.bolderText | translate }}
                    </div>
                    <div class="normal-text">
                        {{ itemList.normalText | translate }}
                    </div>
                </div>
            </div>
        </div>

        <div class="left-side-mobile">

            <div class="video-container">
                <video autoplay [muted]="'muted'" loop>
                    <source src="../../../../assets/your-custom-application/your-custom-application-2.MOV" />
                    Your browser does not support the video tag.
                </video>
            </div>

        </div>
    </div>

</div>