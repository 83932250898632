import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-technology-tab',
  templateUrl: './our-technology-tab.component.html',
  styleUrls: ['./our-technology-tab.component.css']
})
export class OurTechnologyTabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { 
    // Scroll to top
    window.scroll({ top: 0, left: 0 });
  }

}
