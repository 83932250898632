<div class="home-content" *ngIf="!isMobileResolution; else mobileHomeContent">

  <!-- Our technology - Microscope image and first description -->
  <div class="home-micro-info min-width flex">
    <div class="home-micro-info-text">
      <p class="home-micro-info-title font-family">
        {{ 'Honey.AI microscope' | translate}}
      </p>
      <p class="home-micro-info-body font-family">
        {{'The first intelligent microscope to perform on-site automated honey quality analysis.' | translate}}
      </p>
      <div style="margin-top: 30px;">
        <!--        <a [routerLink]="languageService.getPath('/our-technology')">-->
        <a [routerLink]="languageService.getPath('/FAQs')">
          <button class="home-button white-honey-button">
            <span class="home-button-text font-family">{{'Want to know more?'| translate}}</span>
          </button>
        </a>
        <a [routerLink]="languageService.getPath('/pre-orders')">
          <button class="home-button yellow-honey-button margin-left">
            <span class="home-button-text font-family">{{'Request a pre-order!'| translate}}</span>
          </button>
        </a>
      </div>
    </div>
    <div class="home-micro-image">
      <img
        width="100%"
        alt="Honey.AI Microscope"
        src="../../../../assets/home/microscope-honeyai.jpg">
    </div>
  </div>

  <!-- What is Honey.AI? -->
  <div class="home-what-is-div flex">
    <div class="home-what-is-half-div">
      <div>
        <img
          width="100%"
          alt="Honey.AI Microscope details"
          src="../../../../assets/home/detailed-microscope.jpg">
      </div>
    </div>
    <div class="home-what-is-half-div">

      <p class="text-title-honey text-align-left">
        {{'What is Honey.AI?'| translate}}
      </p>
      <p class="text-subtitle-honey text-align-left">
        {{'Artificial Intelligence for the honey Industry'| translate}}
      </p>
      <p class="text-content-honey text-align-left">
        {{'Conduct different honey quality tests at you premises (floral source, sugar crystals, and yeasts counting), in less than 1 hour!'| translate}}
      </p>

      <!-- What is Honey.AI accordion -->
      <div class="margin-top">
        <app-home-accordion></app-home-accordion>
      </div>

      <div class="margin-top">
        <a [routerLink]="languageService.getPath('/FAQs')">
          <button class="yellow-honey-button">
            <span class="home-button-text font-family"> {{'Any doubt? Visit our FAQs!'| translate}} </span>
          </button>
        </a>
      </div>
    </div>
  </div>

  <!-- How does it work? -->
  <div class="home-how-does-div flex">
    <p class="text-title-honey text-align-center">
      {{'How does it work?'| translate}}
    </p>
    <p class="text-subtitle-honey text-align-center">
      {{'Intuitive, autonomous, very easy to use.'| translate}}
    </p>
    <p class="text-content-honey text-align-center">
      {{'Honey.AI combines robotics, image processing, and artificial intelligence for automated floral source identification through pollen counting and classification. Honey.AI also performs automated yeasts counting analysis, and crystallization measurement.'| translate}}
    </p>
  </div>

  <!-- How does it work? Sequence -->
  <div class="how-does-it-work-sequence min-width">
    <div class="how-does-it-work-background-image">
      <img
        width="90%"
        alt="Honey.AI - how does it work"
        src="assets/home/how-does-it-work-background-image-{{ 'lang' | translate}}.png">
    </div>
    <div class="how-does-it-work-seq-div flex">
      <p class="how-does-it-work-seq-text font-family">
        {{'Prepare the honey sample in 10 minutes'| translate}}
      </p>
      <p class="how-does-it-work-seq-text font-family">
        {{'Place the sample in the device'| translate}}
      </p>
      <p class="how-does-it-work-seq-text font-family">
        {{'Press Start and wait less than 1 hour'| translate}}
      </p>
      <p class="how-does-it-work-seq-text font-family">
        {{'The results will be in your inbox!'| translate}}
      </p>
    </div>
  </div>

  <!-- Check our subscription packages -->
  <div class="check-subscription-div min-width">
    <div class="check-subscription">
      <p class="home-body-text font-family">
        {{'Find more useful and interesting contents in '| translate}} <span style="color:#F3D02F;">{{'your personal account.'| translate}}</span>
      </p>
      <p class="home-body-text-sub font-family">
        {{'Become our client, and enjoy lots of additional materials.'| translate}}
      </p>
    </div>
    <div class="check-subscription-button-div">
      <a [routerLink]="languageService.getPath('/pricing')" style="color: black">
        <button mat-button class="yellow-honey-button home-button-text font-family">{{'Check our subscription packages'| translate}}</button>
      </a>
    </div>
  </div>

  <!-- Home Facts & Figures -->
  <div class="facts-figures margin-top min-width">
    <img
      width="100%"
      alt="Facts & Figures - background"
      src="../../../../assets/home/facts-background.svg">
    <div class="facts-figures-content">
      <div class="facts-figures-line-divs flex">
        <div class="text-align-left facts-first-text-width">
          <p class="facts-body-text font-family">
            {{'Facts & Figures'| translate}}
          </p>
          <p class="facts-title-text font-family">
            {{'Proud of Honey.AI actual features'| translate}}
          </p>
        </div>
        <div class="facts-text-width">
          <p class="facts-title-text font-family">
            10€
          </p>
          <p class="facts-body-text font-family">
            {{'Average price per pollen analysis'| translate}}
          </p>
        </div>
        <div class="facts-text-width">
          <p class="facts-title-text font-family">
            15
          </p>
          <p class="facts-body-text font-family">
            {{'Clients have validated the system'| translate}}
          </p>
        </div>
        <div class="facts-text-width">
          <p class="facts-title-text font-family">
            9
          </p>
          <p class="facts-body-text font-family">
            {{'Countries in which we have customers'| translate}}
          </p>
        </div>
        <div class="facts-text-width">
          <p class="facts-title-text font-family">
            > 200,000
          </p>
          <p class="facts-body-text font-family">
            {{'Pictures stored in our Database'| translate}}
          </p>
        </div>
        <div class="facts-text-width">
          <p class="facts-title-text font-family">
            {{'3-60 min'| translate}}
          </p>
          <p class="facts-body-text font-family">
            {{'Time required per test'| translate}}
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- Reasons to invest -->
  <div class="reasons-to-invest-div margin-top">
    <div class="reasons-to-invest-list-div">
      <p class="reasons-to-title font-family">
        {{'Reasons to invest in Honey.AI'| translate}}
      </p>
      <div class="reasons-to-card">
        <img width="60px"
             src="../../../../assets/home/reasons-to-1.svg">
        <span class="home-what-is-button-text font-family">
          <span style="color:#F3D02F;">{{'ACCURATE. RELIABLE.'| translate}}</span>
          <br>
          <span style="white-space: pre-line">{{'Avoid human errors and variability between laboratories. Honey.AI increases repeatability and precision.'| translate}}</span>
        </span>
      </div>
      <div class="reasons-to-card">
        <img width="60px"
             src="../../../../assets/home/reasons-to-2.svg">
        <span class="home-what-is-button-text font-family">
          <span style="color:#F3D02F;">{{'SAVE MONEY'| translate}}</span><br>
          <span style="white-space: pre-line">{{'Recover the investment only in few months. More than five times cheaper than analytical tests performed by an external laboratory.'| translate}}</span>
        </span>
      </div>
      <div class="reasons-to-card">
        <img width="60px"
             src="../../../../assets/home/reasons-to-3.svg">
        <span class="home-what-is-button-text font-family">
          <span style="color:#F3D02F;">{{'FAST. ON-SITE. AUTONOMOUS'| translate}}</span><br>
          <span style="white-space: pre-line">{{'Get the results in 1 hour. No need to send a sample to an external laboratory every time and wait days for the results.'| translate}}</span>
        </span>
      </div>
      <div class="reasons-to-card">
        <img width="60px"
             src="../../../../assets/home/reasons-to-4.svg">
        <span class="home-what-is-button-text font-family">
          <span style="color:#F3D02F;">{{'PRODUCT QUALITY MONITORING'| translate}}</span><br>
          {{'Gain real-time control of your process and your product, with very little investment.'| translate}}
        </span>
      </div>
    </div>
    <div class="reasons-to-invest-honey-img-div">
      <img class="reasons-to-invest-honey-img"
           src="../../../../assets/home/reasons-to-honey.svg">
    </div>
  </div>

  <!-- Early adopters -->
  <div class="early-adopters-div margin-top">
    <p class="home-centered-title font-family mb-5 pb-2"> {{'Early adopters'| translate}} </p>
    <app-carousel></app-carousel>
  </div>

  <div class="main-supplier-div margin-top">
    <p class="home-centered-title font-family">  {{'Main supplier'| translate}} </p>
    <a href="https://moticeurope.com/" target="_blank">
      <img class="main-supplier-img"
           alt="Motic - Honey.AI Supplier"
           width="300px"
           src="../../../../assets/home/motic.jpg">
    </a>

  </div>

  <div class="contact-us-div margin-top min-width">
    <p class="home-centered-title font-family">  {{'Contact us!'| translate}} </p>
    <app-contact-us-cards></app-contact-us-cards>
  </div>

</div>

<ng-template #mobileHomeContent>
  <!-- Our technology - Microscope image and first description -->
  <div>
    <img
      width="100%"
      alt="Honey.AI Microscope"
      src="../../../../assets/home/microscope-honeyai.jpg">
  </div>
  <div class="info-div-mobile" style="margin-bottom: 60px;">
      <p class="home-info-title-mobile">
        {{ 'Honey.AI microscope' | translate}}
      </p>
      <p class="home-info-subtitle-mobile">
        {{'The first intelligent microscope to perform on-site automated honey quality analysis.' | translate}}
      </p>
      <div style="margin-top: 30px; justify-content: space-around; display: flex;">
        <!--        <a routerLink="/our-technology">-->
        <a [routerLink]="languageService.getPath('/FAQs')" style="margin-right: 10px;">
          <button class="white-honey-button">
            <span class="home-button-text-mobile">{{'Want to know more?'| translate}}</span>
          </button>
        </a>
        <a [routerLink]="languageService.getPath('/pre-orders')" style="margin-left: 10px;">
          <button class="yellow-honey-button">
            <span class="home-button-text-mobile">{{'Request a pre-order!'| translate}}</span>
          </button>
        </a>
      </div>
  </div>

  <!-- What is Honey.AI? -->
  <div class="info-div-mobile">
    <p class="home-info-title-mobile">
      {{'What is Honey.AI?'| translate}}
    </p>
    <p class="home-info-subtitle-mobile">
      {{'Artificial Intelligence for the honey Industry'| translate}}
    </p>
    <p class="home-info-body-mobile">
      {{'Conduct different honey quality tests at you premises (floral source, sugar crystals, and yeasts counting), in less than 1 hour!'| translate}}
    </p>
  </div>
  <div>
      <img
        width="100%"
        alt="Honey.AI Microscope details"
        src="../../../../assets/home/detailed-microscope.jpg">
  </div>
  <div style=" margin-top: 30px;margin-bottom: 30px; margin-left: 10px;">
    <app-home-accordion [titleFont]=17></app-home-accordion>
  </div>
  <div style="text-align: center; margin-bottom: 60px;">
    <a [routerLink]="languageService.getPath('/FAQs')">
      <button class="yellow-honey-button">
        <span class="home-button-text-mobile font-family"> {{'Any doubt? Visit our FAQs!'| translate}} </span>
      </button>
    </a>
  </div>

  <!-- How does it work? -->
  <div class="info-div-mobile">
    <p class="home-info-title-mobile">
      {{'How does it work?'| translate}}
    </p>
    <p class="home-info-subtitle-mobile">
      {{'Intuitive, autonomous, very easy to use.'| translate}}
    </p>
    <p class="home-info-body-mobile">
      {{'Honey.AI combines robotics, image processing, and artificial intelligence for automated floral source identification through pollen counting and classification. Honey.AI also performs automated yeasts counting analysis, and crystallization measurement.'| translate}}
    </p>
  </div>

  <div style="text-align: center; font-weight: 600; margin-bottom: 60px;">
    <img style="width: 60%;" src="assets/home/how-does-mobile-1.png">
    <p>{{'Prepare the honey sample in 10 minutes'| translate}}</p>
    <img style="width: 60%;" src="assets/home/how-does-mobile-2.png">
    <p>{{'Place the sample in the device'| translate}}</p>
    <img style="width: 60%;" src="assets/home/how-does-mobile-3-{{'lang' | translate}}.png">
    <p>{{'Press Start and wait less than 1 hour'| translate}}</p>
    <img style="width: 60%;" src="assets/home/how-does-mobile-4-{{'lang' | translate}}.png">
    <p>{{'The results will be in your inbox!'| translate}}</p>
  </div>

  <!-- Check our subscription packages -->
  <div class="check-subscription-div" style="margin-bottom: 60px;">
    <div class="check-subscription">
      <p class="home-info-subtitle-mobile">
        {{'Find more useful and interesting contents in '| translate}} <span style="color:#F3D02F;">{{'your personal account.'| translate}}</span>
      </p>
      <p class="home-info-body-mobile">
        {{'Become our client, and enjoy lots of additional materials.'| translate}}
      </p>
    </div>
    <div class="check-subscription-button-div">
      <a [routerLink]="languageService.getPath('/pricing')" style="color: black">
        <button mat-button class="yellow-honey-button home-button-text-mobile">{{'Check our subscription packages'| translate}}</button>
      </a>
    </div>
  </div>

  <!-- Home Facts & Figures -->
  <div class="facts-figures">
    <img
      width="100%"
      alt="Facts & Figures"
      src="../../../../assets/home/facts-background-mobile.svg">
    <div class="facts-figures-content-mobile">
      <div class="facts-figures-line-divs">
        <div style="margin-bottom: 40px;">
          <p class="facts-title-text-mobile">
            {{'Proud of Honey.AI actual features'| translate}}
          </p>
        </div>
        <div style="margin-bottom: 40px;">
          <p class="facts-title-text-mobile">
            10€
          </p>
          <p class="facts-body-text-mobile">
            {{'Average price per pollen analysis'| translate}}
          </p>
        </div>
        <div style="margin-bottom: 40px;">
          <p class="facts-title-text-mobile">
            15
          </p>
          <p class="facts-body-text-mobile">
            {{'Clients have validated the system'| translate}}
          </p>
        </div>
        <div style="margin-bottom: 40px;">
          <p class="facts-title-text-mobile">
            9
          </p>
          <p class="facts-body-text-mobile">
            {{'Countries in which we have customers'| translate}}
          </p>
        </div>
        <div style="margin-bottom: 40px;">
          <p class="facts-title-text-mobile">
            > 200,000
          </p>
          <p class="facts-body-text-mobile">
            {{'Pictures stored in our Database'| translate}}
          </p>
        </div>
        <div style="margin-bottom: 40px;">
          <p class="facts-title-text-mobile">
            {{'3-60 min'| translate}}
          </p>
          <p class="facts-body-text-mobile">
            {{'Time required per test'| translate}}
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- Reasons to invest -->
  <div class="info-div-mobile">
    <p class="home-info-subtitle-mobile">
      {{'Reasons to invest in Honey.AI'| translate}}
    </p>
    <div class="reasons-to-card-mobile">
      <div class="card-image-mobile">
        <img src="assets/home/reasons-to-1.svg">
      </div>
      <p class="home-info-title-mobile card-text-mobile" style="font-size: 24px;">
        {{'ACCURATE. RELIABLE.'| translate}}
      </p>
      <p class="home-info-body-mobile card-text-mobile" style="white-space: pre-line">
        {{'Avoid human errors and variability between laboratories. Honey.AI increases repeatability and precision.'| translate}}
      </p>
    </div>

    <div class="reasons-to-card-mobile">
      <div class="card-image-mobile">
        <img src="assets/home/reasons-to-2.svg">
      </div>
      <p class="home-info-title-mobile card-text-mobile" style="font-size: 24px;">
        {{'SAVE MONEY'| translate}}
      </p>
      <p class="home-info-body-mobile card-text-mobile" style="white-space: pre-line">
        {{'Recover the investment only in few months. More than five times cheaper than analytical tests performed by an external laboratory.'| translate}}
      </p>
    </div>

    <div class="reasons-to-card-mobile">
      <div class="card-image-mobile">
        <img src="assets/home/reasons-to-3.svg">
      </div>
      <p class="home-info-title-mobile card-text-mobile" style="font-size: 24px;">
        {{'FAST. ON-SITE. AUTONOMOUS'| translate}}
      </p>
      <p class="home-info-body-mobile card-text-mobile" style="white-space: pre-line">
        {{'Get the results in 1 hour. No need to send a sample to an external laboratory every time and wait days for the results.'| translate}}
      </p>
    </div>

    <div class="reasons-to-card-mobile">
      <div class="card-image-mobile">
        <img src="assets/home/reasons-to-4.svg">
      </div>
      <p class="home-info-title-mobile card-text-mobile" style="font-size: 24px;">
        {{'PRODUCT QUALITY MONITORING'| translate}}
      </p>
      <p class="home-info-body-mobile card-text-mobile" style="white-space: pre-line">
        {{'Gain real-time control of your process and your product, with very little investment.'| translate}}
      </p>
    </div>
  </div>

  <!-- Early adopters -->
  <div class="info-div-mobile">
    <p class="home-info-subtitle-mobile"> {{'Early adopters'| translate}} </p>
    <app-carousel></app-carousel>
  </div>

  <!-- Main supplier -->
  <div class="info-div-mobile">
    <p class="home-info-subtitle-mobile">  {{'Main supplier'| translate}} </p>
    <a href="https://moticeurope.com/" target="_blank">
      <img class="main-supplier-img"
           alt="Motic - Honey.AI Supplier"
           width="200px"
           src="assets/home/motic.jpg"></a>
  </div>

  <div class="background-honey-pot">
  </div>

  <div class="info-div-mobile" style="margin-top: 0px;">
    <p class="home-info-subtitle-mobile">
      {{'Contact us!'| translate}}
    </p>
    <app-contact-us-cards></app-contact-us-cards>
  </div>

</ng-template>
