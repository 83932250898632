<div class="yeast-counting-container">

    <app-technology-header-container [title]="yeastCountingTitle" [subtitle]="yeastCountingSubTitle"
        [vText]="yeastCountingVText" [vCarousel]="yeastCountingVCarousel">
    </app-technology-header-container>


    <div class="yeast-counting-reasons">

        <h3 class="subtitle">
            {{ 'yeast-counting-flip-cards-title' | translate }}
        </h3>

        <div class="benefits-cards">

            <!-- Cards -->
            <app-flip-card *ngFor="let cardData of vFlipCardData" [itemFlipCard]="cardData"></app-flip-card>

        </div>
    </div>


    <div class="yeast-counting-how-it-helps">

        <div class="left-side">
            <div class="gif-content">
                <img src="../../../../assets/yeast-counting/yeast-counting.png" />
            </div>
            
            <div class="arrow-text-content">
                <div class="arrow">
                    <img src="../../../../assets/commons/curved-arrow.png" />
                </div>
                <div class="text">
                    {{ 'yeast-counting-how-it-helps-text-4' | translate }}
                </div>
            </div>
        </div>

        <div class="right-side">
            <div class="container-text">
                <div class="subtitle">
                    {{ 'yeast-counting-how-it-helps-title' | translate }}
                </div>

                <div class="text">
                    {{ 'yeast-counting-how-it-helps-text-1' | translate }}
                </div>

                <div class="text">
                    {{ 'yeast-counting-how-it-helps-text-2' | translate }}
                </div>

                <div class="text">
                    {{ 'yeast-counting-how-it-helps-text-3' | translate }}
                </div>

            </div>
        </div>

    </div>

    <div class="download-example-yeast-counting">

        <div class="text-download-example-content">
            {{ 'yeast-counting-want-to-see-example' | translate }}
        </div>

        <div class="button-download-content">
            <a class="button-download-pdf"
                href="../../../../assets/pdf/Boletin_levaduras.pdf"
                target="_blank"
                download>
                {{ 'download-pdf-here' | translate }}
            </a>
        </div>

    </div>
</div>