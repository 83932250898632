<div class="what-is">
    <div class="title">
        <h1> {{ title | translate }} </h1>
    </div>

    
    <div class="what-is-container-carousel">
        <!-- If Carousel -->
        <div class="right-side"
                [ngStyle]="{ 'background-image': 'url(' + pathImages + vCarousel[currentImage] + ')'}"></div>
                
        <div class="left-side">
            <h3 class="subtitle">
                {{ subtitle | translate }}
            </h3>

            <div class="text" *ngFor="let itemText of vText"
                [innerHTML]="itemText | translate">
            </div>

        </div>

        <!-- If Carousel -->
        <div class="right-side"
            [ngStyle]="{ 'background-image': 'url(' + pathImages + vCarousel[currentImage] + ')'}"></div>

    </div>

</div>