<div class="faq-content-accordions-div">
  <p style="text-align: center"
     [class]="!isMobileResolution? 'text-subtitle-honey': 'home-info-subtitle-mobile'">
    {{"orders-pricing" | translate}}</p>
  <mat-accordion>
    <app-faqs-content-template faqId="faq-orders-pricing-1"></app-faqs-content-template>
    <app-faqs-content-template faqId="faq-orders-pricing-2"></app-faqs-content-template>
    <mat-expansion-panel style=" box-shadow: none;">
      <mat-expansion-panel-header class="expansion-header-class-faqs">
        <mat-panel-title>
          <span> {{ 'faq-orders-pricing-3-title'| translate}} </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p style="text-align: justify; white-space: pre-line;">{{'faq-orders-pricing-3-a-body'| translate}}
        <a [routerLink]="languageService.getPath('/pre-orders')" style="color: black;">{{'HERE'| translate}}</a>{{'faq-orders-pricing-3-b-body'| translate}}
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel style=" box-shadow: none;">
      <mat-expansion-panel-header class="expansion-header-class-faqs">
        <mat-panel-title>
          <span> {{ 'faq-orders-pricing-4-title'| translate}} </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p style="text-align: justify; white-space: pre-line;">{{'faq-orders-pricing-4-a-body'| translate}}
        <a [routerLink]="languageService.getPath('/pre-orders')" style="color: black;">{{'HERE'| translate}}</a>. {{'faq-orders-pricing-4-b-body'| translate}}
      </p>
    </mat-expansion-panel>
    <app-faqs-content-template faqId="faq-orders-pricing-5"></app-faqs-content-template>
    <mat-expansion-panel style=" box-shadow: none;">
      <mat-expansion-panel-header class="expansion-header-class-faqs">
        <mat-panel-title>
          <span> {{ 'faq-orders-pricing-6-title'| translate}} </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p style="text-align: justify; white-space: pre-line;">{{'faq-orders-pricing-6-a-body'| translate}}
        <a [routerLink]="languageService.getPath('/pricing')" style="color: black;">{{'HERE'| translate}}</a>. {{'faq-orders-pricing-6-b-body'| translate}}
      </p>
    </mat-expansion-panel>
  </mat-accordion>
</div>

