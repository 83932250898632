<div class="container-visual-link">

    <div class="icon-circle">
        <img [src]="getIconPath()" />
    </div>

    <div class="link-label">
        {{ label | translate }}
    </div>

    <button class="link-saber-mas" [routerLink]="languageService.getPath(path)" [disabled]="path === ''">
        Saber más
    </button>
</div>