<div #panel style="margin-top: 4%; text-align: center; min-width: 980px;" *ngIf="!isMobileResolution; else mobilePreOrdersContent">
  <p class="text-title-honey" style="margin-bottom: 20px;">{{'pre-orders-information' | translate}}</p>

  <div>
    <div style="display: flex; place-items: center; margin-left: 4%; ">
      <div style="width: 38%;">
        <div class="preorders-discount-div">
          <div style="height: 70%;">
            <p class="text-subtitle-honey" style="white-space: pre-line">{{'get-discount' | translate}}</p>
            <p class="text-title-honey">{{'discount' | translate}}</p>
          </div>
          <div class="yellow-discount-info" style="padding-bottom: 20px;">
            <p><span class="yellow-arrow">→</span> {{'discount-info' | translate}} <span class="yellow-discount-info_2">{{'discount-info-2' | translate}}</span></p>
          </div>
        </div>
        <div style="transform: translate(0px, -35px);">
          <button class="yellow-honey-button" (click)="pressButton()">{{'pre-orders-button' | translate}}</button>
        </div>
        <p>{{'pre-order-form-body' | translate}}</p>
      </div>

      <div style="transform: translate(-20px, -67px); width: 38%;">
        <div class="preorders-info-card">
          <img src="../../../../assets/pre-orders/tick.svg">
          <span style="font-size: 18px; padding-left: 15px;">{{'pre-orders-card-1' | translate}}</span>
        </div>
        <div class="preorders-info-card">
          <img src="../../../../assets/pre-orders/tick.svg">
          <span style="font-size: 18px; padding-left: 15px;">{{'pre-orders-card-2' | translate}}</span>
        </div>
        <div class="preorders-info-card">
          <img src="../../../../assets/pre-orders/tick.svg">
          <span style="font-size: 18px; padding-left: 15px;">{{'pre-orders-card-3' | translate}}</span>
        </div>
      </div>

      <div style="width: 26%;">
        <img
          style="width: 100%;"
          src="../../../../assets/pre-orders/micro-image-preorders.svg">
      </div>
    </div>
  </div>

  <app-pre-orders-form [hidden]="hideForm"></app-pre-orders-form>
</div>

<!--Mobile view-->
<ng-template #mobilePreOrdersContent>
  <div #panelMobile class="info-div-mobile">
    <p class="home-info-title-mobile">{{'pre-orders-information' | translate}}</p>

    <div class="preorders-discount-div" style="height: 430px;">
      <div style="height: 70%;">
        <p class="text-subtitle-honey" style="white-space: pre-line">{{'get-discount' | translate}}</p>
        <p class="text-title-honey">{{'discount' | translate}}</p>
      </div>
      <div class="yellow-discount-info" style="padding-bottom: 20px;">
        <p><span class="yellow-arrow">→</span>{{'discount-info' | translate}}<span class="yellow-discount-info_2">{{'discount-info-2' | translate}}</span></p>
      </div>
    </div>

    <div class="preorders-info-card-mobile">
      <img src="assets/pre-orders/tick.svg">
      <span style="font-size: 18px; padding-left: 15px;">{{'pre-orders-card-1' | translate}}</span>
    </div>
    <div class="preorders-info-card-mobile">
      <img src="assets/pre-orders/tick.svg">
      <span style="font-size: 18px; padding-left: 15px;">{{'pre-orders-card-2' | translate}}</span>
    </div>
    <div class="preorders-info-card-mobile">
      <img src="assets/pre-orders/tick.svg">
      <span style="font-size: 18px; padding-left: 15px;">{{'pre-orders-card-3' | translate}}</span>
    </div>

    <div class="info-div-mobile">
      <img
        src="assets/pre-orders/micro-image-preorders-mobile.svg">
    </div>

    <div style="margin-bottom: 50px;">
      <button class="yellow-honey-button" (click)="pressButton()">{{'pre-orders-button' | translate}}</button>
    </div>

    <app-pre-orders-form [hidden]="hideForm"></app-pre-orders-form>

  </div>
</ng-template>
