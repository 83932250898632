<div #panel class="container-pollen-analysis">
  <app-technology-header-container
    [title]="title"
    [subtitle]="subtitle"
    [vText]="vText"
    [vCarousel]="vCarousel"
  ></app-technology-header-container>

  <div class="benefits-pollen-analysis">
    <h3 class="subtitle">
      {{ "pollen-analysis-benefics-title" | translate }}
    </h3>

    <div class="benefits-cards">
      <!-- Cards -->
      <app-flip-card
        *ngFor="let cardData of vFlipCardData"
        [itemFlipCard]="cardData"
      ></app-flip-card>
    </div>
  </div>

  <div class="how-it-works-pollen-analysis">
    <div class="left-side">
      <div class="gif-row">
        <div class="gif-content"></div>
        <div class="arrow-text-content">
          <div class="text">
            {{ "pollen-analysis-how-it-works-text-5" | translate }}
          </div>
          <div class="arrow">
            <img src="../../../../assets/commons/curved-arrow.png" />
          </div>
        </div>
      </div>
      <div class="slider-row">
        <img-comparison-slider class="img-comparator">
          <img
            class="img-slider-item"
            slot="first"
            src="../../../../assets/pollen-analysis/pollen-slider-before.png"
          />
          <img
            class="img-slider-item"
            slot="second"
            src="../../../../assets/pollen-analysis/pollen-slider-after.png"
          />
          <img
            slot="handle"
            width="40"
            src="../../../../assets/pollen-analysis/slider-arrow.png"
          />
        </img-comparison-slider>
      </div>
    </div>

    <div class="right-side">
      <div class="subtitle">
        {{ "pollen-analysis-how-it-works-title" | translate }}
      </div>

      <div class="text">
        {{ "pollen-analysis-how-it-works-text-1" | translate }}
      </div>

      <div class="text">
        {{ "pollen-analysis-how-it-works-text-2" | translate }}
      </div>

      <div
        class="text"
        [innerHTML]="'pollen-analysis-how-it-works-text-3' | translate"
      ></div>

      <div class="text">
        {{ "pollen-analysis-how-it-works-text-4" | translate }}
      </div>
    </div>
  </div>

  <div class="download-example-pollen-analysis">
    <div class="text-download-example-content">
      {{ "pollen-analysis-see-example" | translate }}
    </div>

    <div class="button-download-content">
      <a
        class="button-download-pdf"
        [href]="getPdfPath()"
        download
        target="_blank"
      >
        {{ "download-pdf-here" | translate }}
      </a>
    </div>
  </div>

  <div class="pdf-info-hover-points-container">
    <div class="pdf-container">
      <img
        class="pdf-img"
        [src]="getMultiespectrumPdfPath(1)"
        alt="Pollen Analysis PDF page 1"
      />
      <img
        *ngFor="let point of vPdf1InfoPoints"
        [matTooltip]="point.text | translate"
        (click)="tooltip.show()"
        (hover)="tooltip.show()"
        [style]="getPointStyle(point)"
        #tooltip="matTooltip"
        class="hover-point"
        matTooltipClass="info-tooltip"
        src="../../../../assets/pollen-analysis/yellow-info-icon.svg"
      />
    </div>
    <div class="pdf-container">
      <img
        class="pdf-img"
        [src]="getMultiespectrumPdfPath(2)"
        alt="Pollen Analysis PDF page 2"
      />
      <img
        *ngFor="let point of vPdf2InfoPoints"
        [matTooltip]="point.text | translate"
        (click)="tooltip.show()"
        (hover)="tooltip.show()"
        [style]="getPointStyle(point)"
        #tooltip="matTooltip"
        class="hover-point"
        matTooltipClass="info-tooltip"
        src="../../../../assets/pollen-analysis/yellow-info-icon.svg"
      />
    </div>
  </div>

  <div class="reliability-pollen-analysis">
    <div class="title">
      {{ "pollen-analysis-reliability-title" | translate }}
    </div>

    <div class="text">
      {{ "pollen-analysis-reliability-text-1" | translate }}
    </div>

    <div class="text">
      {{ "pollen-analysis-reliability-text-2" | translate }}
    </div>
  </div>

  <div class="upv-pollen-analysis">
    <div class="left-side">
      <div class="images-container">
        <img
          class="folder"
          src="../../../../assets/pollen-analysis/icon-folder-check.png"
        />
        <img
          class="upv-logo"
          src="../../../../assets/pollen-analysis/universitat-politecnica-valencia.png"
        />
      </div>

      <div class="message-upv">
        {{ "pollen-analysis-reliability-text-6" | translate }}
      </div>

      <div class="button-download-content">
        <a
          class="button-download-pdf"
          href="../../../../assets/pdf/informe_sonicat_final.pdf"
          download
        >
          {{ "download-pdf-here" | translate }}
        </a>
      </div>
    </div>
    <div class="right-side">
      <div class="text">
        <span>"</span> {{ "pollen-analysis-reliability-text-3" | translate }}
        <span>"</span>
      </div>
      <div class="text">
        <span>"</span> {{ "pollen-analysis-reliability-text-4" | translate }}
        <span>"</span>
      </div>
      <div class="text">
        <span>"</span> {{ "pollen-analysis-reliability-text-5" | translate }}
        <span>"</span>
      </div>
    </div>
  </div>

  <div class="request-service-pollen-analysis">
    <h2 class="rs-row">
      {{ "pollen-analysis-request-service-text-1" | translate }}
    </h2>

    <div class="rs-row">
      <div class="left-side">
        {{ "pollen-analysis-request-service-text-2" | translate }}
      </div>
      <div class="right-side">
        {{ "pollen-analysis-request-service-text-3" | translate }}
      </div>
    </div>

    <div class="rs-row">
      <div class="item-hexagon">
        <div class="icon">
          <img
            src="../../../../assets/pollen-analysis/hexa-icons/icon-coin.png"
          />
        </div>
        <div class="title">
          {{ "pollen-analysis-request-service-text-4" | translate }}
        </div>
        <div class="text">
          {{ "pollen-analysis-request-service-text-5" | translate }}
        </div>
      </div>
      <div
        class="item-hexagon"
        [ngClass]="{ 'language-en': currentLanguage === 'en' }"
      >
        <div class="icon">
          <img
            src="../../../../assets/pollen-analysis/hexa-icons/icon-calendar.png"
          />
        </div>
        <div class="title">
          {{ "pollen-analysis-request-service-text-6" | translate }}
        </div>
        <div class="text">
          {{ "pollen-analysis-request-service-text-7" | translate }}
        </div>
      </div>
      <div class="item-hexagon">
        <div class="icon">
          <img
            src="../../../../assets/pollen-analysis/hexa-icons/icon-honey.png"
          />
        </div>
        <div class="title">
          {{ "pollen-analysis-request-service-text-8" | translate }}
        </div>
        <div class="text">
          {{ "pollen-analysis-request-service-text-9" | translate }}
        </div>
      </div>
    </div>

    <div class="rs-row">
      <div class="button-download-content">
        <button class="button-download-pdf" (click)="pressButtonShowForm()">
          {{ "pollen-analysis-request-button" | translate }}
        </button>
      </div>
    </div>
  </div>

  <div class="contact-form-pollen-analysis" [hidden]="hideForm">
    <div class="title">
      {{ "pollen-analysis-contact-form-title" | translate }}
    </div>

    <div class="text">
      {{ "pollen-analysis-contact-form-text-1" | translate }}
    </div>

    <div class="form">
      <form [formGroup]="contactUsForm" (submit)="onSendForm()">
        <div class="input-container">
          <input
            type="text"
            formControlName="name"
            class="form-control form-input-honey"
            maxlength="200"
            placeholder="{{
              'pollen-analysis-contact-form-fullname' | translate
            }}"
          />
          <div
            class="text-error"
            *ngIf="
              contactUsForm.get('name')?.touched &&
              contactUsForm.controls['name'].errors !== null
            "
          >
            {{ "pollen-analysis-fullname-error" | translate }}
          </div>
        </div>

        <div class="input-container">
          <input
            type="text"
            formControlName="company"
            class="form-control form-input-honey"
            maxlength="200"
            placeholder="{{
              'pollen-analysis-contact-form-company' | translate
            }}"
          />
          <div
            class="text-error"
            *ngIf="
              contactUsForm.get('company')?.touched &&
              contactUsForm.controls['company'].errors !== null
            "
          >
            {{ "pollen-analysis-company-error" | translate }}
          </div>
        </div>

        <div class="input-container">
          <input
            type="text"
            formControlName="cif"
            class="form-control form-input-honey"
            maxlength="10"
            placeholder="{{ 'pollen-analysis-contact-form-cif' | translate }}"
          />
          <div
            class="text-error"
            *ngIf="
              contactUsForm.get('cif')?.touched &&
              contactUsForm.controls['cif'].errors !== null
            "
          >
            {{ "pollen-analysis-cif-error" | translate }}
          </div>
        </div>

        <div class="input-container">
          <input
            type="text"
            formControlName="deliveryAddr"
            class="form-control form-input-honey"
            maxlength="250"
            placeholder="{{
              'pollen-analysis-contact-form-address' | translate
            }}"
          />
          <div
            class="text-error"
            *ngIf="
              contactUsForm.get('deliveryAddr')?.touched &&
              contactUsForm.controls['deliveryAddr'].errors !== null
            "
          >
            {{ "pollen-analysis-address-error" | translate }}
          </div>
        </div>

        <div class="input-container">
          <input
            type="text"
            formControlName="phone"
            class="form-control form-input-honey"
            maxlength="15"
            placeholder="{{
              'pollen-analysis-contact-form-telephone' | translate
            }}"
          />
          <div
            class="text-error"
            *ngIf="
              contactUsForm.get('phone')?.touched &&
              contactUsForm.controls['phone'].errors !== null
            "
          >
            {{ "pollen-analysis-telephone-error" | translate }}
          </div>
        </div>

        <div class="input-container">
          <input
            type="email"
            formControlName="email"
            class="form-control form-input-honey"
            maxlength="100"
            placeholder="{{ 'pollen-analysis-contact-form-email' | translate }}"
          />
          <div
            class="text-error"
            *ngIf="
              contactUsForm.get('email')?.touched &&
              contactUsForm.controls['email'].errors !== null
            "
          >
            {{ "pollen-analysis-email-error" | translate }}
          </div>
        </div>

        <div class="input-container">
          <input
            type="number"
            formControlName="numberSamples"
            class="form-control form-input-honey"
            placeholder="{{
              'pollen-analysis-contact-form-number-samples' | translate
            }}"
            min="0"
            max="100"
          />
          <div
            class="text-error"
            *ngIf="
              contactUsForm.get('numberSamples')?.touched &&
              contactUsForm.controls['numberSamples'].errors !== null
            "
          >
            {{ "pollen-analysis-number-samples-error" | translate }}
          </div>
        </div>

        <div class="input-container">
          <input
            type="text"
            formControlName="typeSamples"
            class="form-control form-input-honey"
            maxlength="200"
            placeholder="{{
              'pollen-analysis-contact-form-type-samples' | translate
            }}"
          />
          <div
            class="text-error"
            *ngIf="
              contactUsForm.get('typeSamples')?.touched &&
              contactUsForm.controls['typeSamples'].errors !== null
            "
          >
            {{ "pollen-analysis-type-samples-error" | translate }}
          </div>
        </div>

        <div class="submit-container">
          <button type="submit" [disabled]="sending" class="button-submit">
            {{ "pollen-analysis-contact-form-button-send" | translate }}
          </button>
        </div>

        <div class="check-container">
          <input
            type="checkbox"
            [ngClass]="{ checked: contactUsForm.value['acceptTerms'] === true }"
            formControlName="acceptTerms"
          />
          <span class="checkmark" (click)="onChangeCheck()"></span>
          <label
            [innerHTML]="
              'pollen-analysis-contact-form-accept-terms' | translate
            "
          ></label>
        </div>

        <div
          class="text-error"
          *ngIf="
            contactUsForm.get('acceptTerms')?.touched &&
            contactUsForm.controls['acceptTerms'].errors !== null
          "
        >
          {{ "pollen-analysis-accept-terms-error" | translate }}
        </div>
      </form>
    </div>
  </div>
</div>
