<div *ngIf="!isMobileResolution; else mobileContactContent">
  <!-- Header -->
  <app-header></app-header>

  <div class="tabs-image">
    <a [routerLink]="languageService.getPath('/home')">
      <img
        width="220px"
        alt="Honey.AI Logo"
        src="../../../../assets/home/logo-honey-ai.svg"
      />
    </a>
  </div>

  <div class="contact-content min-width">
    <div class="centered-honey-text-div flex">
      <p class="text-title-honey text-align-center">
        {{ "Contact us" | translate }}
      </p>
      <p class="text-subtitle-honey text-align-center">
        {{ "Want to know more about Honey.AI?" | translate }}
      </p>
      <p class="text-content-honey text-align-center">
        {{
          "Leave us your contact information and we will get back to you!"
            | translate
        }}
      </p>
    </div>

    <app-contact-form
      class="contact-form-div min-width margin-bottom"
    ></app-contact-form>
    <app-contact-us-cards></app-contact-us-cards>
    <app-map class="map-div"></app-map>
  </div>

  <!-- Footer -->
  <app-footer></app-footer>
</div>

<ng-template #mobileContactContent>
  <!-- Header -->
  <app-header></app-header>

  <div class="info-div-mobile">
    <p class="home-info-title-mobile">
      {{ "Contact us" | translate }}
    </p>
    <p class="home-info-subtitle-mobile">
      {{ "Want to know more about Honey.AI?" | translate }}
    </p>
    <p class="home-info-body-mobile">
      {{
        "Leave us your contact information and we will get back to you!"
          | translate
      }}
    </p>
  </div>

  <app-contact-form></app-contact-form>
  <div style="margin-bottom: 60px"></div>
  <app-contact-us-cards></app-contact-us-cards>
  <app-map class="map-div"></app-map>
  <div style="margin-bottom: 60px"></div>

  <!-- Footer -->
  <app-footer></app-footer>
</ng-template>
