import { Component, OnInit } from '@angular/core';
import { FLIP_CARD_PARAGRAPH_TYPE, FlipCardData } from '../../commons/flip-card/flip-card.component';

@Component({
  selector: 'app-tech-yeast-counting',
  templateUrl: './tech-yeast-counting.component.html',
  styleUrls: ['./tech-yeast-counting.component.css']
})
export class TechYeastCountingComponent implements OnInit {

  // 
  // 
  yeastCountingTitle: string = 'yeast-counting-title';
  yeastCountingSubTitle: string = 'yeast-counting-subtitle';
  yeastCountingVText: string[] = [
    'yeast-counting-header-text-1',
    'yeast-counting-header-text-2',
    'yeast-counting-header-text-3'
  ];
  yeastCountingVCarousel: string[] = [
    'yeast-counting-carousel-1.png',
    'yeast-counting-carousel-2.png',
    'yeast-counting-carousel-3.png',
    'yeast-counting-carousel-4.png'
  ]

  vFlipCardData: FlipCardData[] = [
    {
      iconName: 'circle-icon-distribucion-consumo.png',
      cardName: 'yeast-counting-flip-card-1-title',
      vString: [
        { text: 'yeast-counting-flip-card-1-text-1', type: FLIP_CARD_PARAGRAPH_TYPE },
        { text: 'yeast-counting-flip-card-1-text-2', type: FLIP_CARD_PARAGRAPH_TYPE },
      ]
    },
    {
      iconName: 'circle-icon-informacion-producto.png',
      cardName: 'yeast-counting-flip-card-2-title',
      vString: [
        { text: 'yeast-counting-flip-card-2-text-1', type: FLIP_CARD_PARAGRAPH_TYPE },
      ]
    },
  ]

  constructor() { }

  ngOnInit(): void {
    // Scroll to top
    window.scroll({ top: 0, left: 0 });
  }

}
