<div class="conductivity-container">

    <div class="conductivity-title">
        <h1> {{ 'conductivity-title' | translate }} </h1>
    </div>

    <div class="header-conductivity-container">

        <div class="image-mobile-side"></div>

        <div class="text-side">
            <h3 class="subtitle">
                {{ 'conductivity-subtitle' | translate }}
            </h3>

            <div class="text">
                {{ 'conductivity-text-1' | translate }}
            </div>

            <div class="text">
                {{ 'conductivity-text-2' | translate }}
            </div>

            <div class="text">
                {{ 'conductivity-text-3' | translate }}
            </div>

        </div>

        <div class="image-pc-side"></div>

    </div>

    <div class="mockup-download-container">

        <div class="mockup-side"></div>

        <div class="download-side">
            <div class="text">
                {{ 'conductivity-text-4' | translate }}
            </div>
            <div class="download-button">
                <a class="button-download-pdf"
                    href="../../../../assets/pdf/Boletin_Color.pdf"
                    target="_blank" download>
                    {{ 'conductivity-download-example' | translate }}
                </a>
            </div>
        </div>
    </div>

</div>