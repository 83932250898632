<div *ngIf="!isMobileResolution; else mobileFAQs">
  <!-- Header -->
  <app-header></app-header>

  <div class="tabs-image">
    <a [routerLink]="languageService.getPath('/home')">
      <img
        width="220px"
        alt="Honey.AI Logo"
        src="../../../assets/home/logo-honey-ai.svg"
      />
    </a>
  </div>

  <div class="centered-honey-text-div flex">
    <p class="text-title-honey text-align-center">
      {{ "Frequently Asked Questions" | translate }}
    </p>
    <p class="text-subtitle-honey text-align-center">
      {{ "here-to-help" | translate }}
    </p>
  </div>

  <div style="margin-top: 80px; place-content: center" class="faqs-cards-div">
    <app-faqs-card
      (click)="selectFAQCard(1)"
      [routerLink]="languageService.getPath('/FAQs/content')"
      class="card-div-content"
      imageHeight="160"
      fontSize="24"
      cardText="about-honey"
      imageUrl="../../assets/faqs/about-honeyAI.svg"
    ></app-faqs-card>

    <app-faqs-card
      (click)="selectFAQCard(2)"
      [routerLink]="languageService.getPath('/FAQs/content')"
      class="card-div-content"
      imageHeight="160"
      fontSize="24"
      cardText="using-the-sys"
      imageUrl="../../assets/faqs/using-the-system.svg"
    ></app-faqs-card>

    <app-faqs-card
      (click)="selectFAQCard(3)"
      [routerLink]="languageService.getPath('/FAQs/content')"
      class="card-div-content"
      imageHeight="160"
      fontSize="24"
      cardText="install-maintenance"
      imageUrl="../../assets/faqs/installation-mantenance.svg"
    ></app-faqs-card>
  </div>

  <div
    style="margin-top: 30px; margin-bottom: 140px; place-content: center"
    class="faqs-cards-div"
  >
    <app-faqs-card
      (click)="selectFAQCard(4)"
      [routerLink]="languageService.getPath('/FAQs/content')"
      class="card-div-content"
      imageHeight="160"
      fontSize="24"
      cardText="orders-pricing"
      imageUrl="../../assets/faqs/orders-pricing.svg"
    ></app-faqs-card>

    <app-faqs-card
      (click)="selectFAQCard(5)"
      [routerLink]="languageService.getPath('/FAQs/content')"
      class="card-div-content"
      imageHeight="160"
      fontSize="24"
      cardText="shipment-delivery"
      imageUrl="../../assets/faqs/shipment-delivery.svg"
    ></app-faqs-card>

    <app-faqs-card
      (click)="selectFAQCard(6)"
      [routerLink]="languageService.getPath('/FAQs/content')"
      class="card-div-content"
      imageHeight="160"
      fontSize="24"
      cardText="my-account"
      imageUrl="../../assets/faqs/account.svg"
    ></app-faqs-card>
  </div>

  <!-- Footer -->
  <app-footer></app-footer>
</div>

<ng-template #mobileFAQs>
  <!-- Header -->
  <app-header></app-header>

  <div class="info-div-mobile">
    <p class="home-info-title-mobile">
      {{ "Frequently Asked Questions" | translate }}
    </p>
    <p class="home-info-subtitle-mobile">
      {{ "here-to-help" | translate }}
    </p>
  </div>

  <div style="margin-top: 20px; place-content: center" class="faqs-cards-div">
    <app-faqs-card
      (click)="selectFAQCard(1)"
      [routerLink]="languageService.getPath('/FAQs/content')"
      class="card-div-content-mobile"
      imageHeight="100"
      fontSize="18"
      cardText="about-honey"
      imageUrl="../../assets/faqs/about-honeyAI.svg"
    ></app-faqs-card>

    <app-faqs-card
      (click)="selectFAQCard(2)"
      [routerLink]="languageService.getPath('/FAQs/content')"
      class="card-div-content-mobile"
      imageHeight="100"
      fontSize="18"
      cardText="using-the-sys"
      imageUrl="../../assets/faqs/using-the-system.svg"
    ></app-faqs-card>
  </div>

  <div style="margin-top: 20px; place-content: center" class="faqs-cards-div">
    <app-faqs-card
      (click)="selectFAQCard(3)"
      [routerLink]="languageService.getPath('/FAQs/content')"
      class="card-div-content-mobile"
      imageHeight="100"
      fontSize="18"
      cardText="install-maintenance"
      imageUrl="../../assets/faqs/installation-mantenance.svg"
    ></app-faqs-card>

    <app-faqs-card
      (click)="selectFAQCard(4)"
      [routerLink]="languageService.getPath('/FAQs/content')"
      class="card-div-content-mobile"
      imageHeight="100"
      fontSize="18"
      cardText="orders-pricing"
      imageUrl="../../assets/faqs/orders-pricing.svg"
    ></app-faqs-card>
  </div>

  <div
    style="margin-top: 20px; margin-bottom: 140px; place-content: center"
    class="faqs-cards-div"
  >
    <app-faqs-card
      (click)="selectFAQCard(5)"
      [routerLink]="languageService.getPath('/FAQs/content')"
      class="card-div-content-mobile"
      imageHeight="100"
      fontSize="18"
      cardText="shipment-delivery"
      imageUrl="../../assets/faqs/shipment-delivery.svg"
    ></app-faqs-card>

    <app-faqs-card
      (click)="selectFAQCard(6)"
      [routerLink]="languageService.getPath('/FAQs/content')"
      class="card-div-content-mobile"
      imageHeight="100"
      fontSize="18"
      cardText="my-account"
      imageUrl="../../assets/faqs/account.svg"
    ></app-faqs-card>
  </div>

  <!-- Footer -->
  <app-footer></app-footer>
</ng-template>
