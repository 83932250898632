import {Component, Input, OnInit} from '@angular/core';
import {ApplicationStateService} from "../../../../service/application-state.service";

@Component({
  selector: 'app-home-accordion',
  templateUrl: './home-accordion.component.html',
  styleUrls: ['./home-accordion.component.css'],
  styles: [`
    ::ng-deep .toggle-specific-class > .mat-expansion-indicator:after {
      color: #F3D02F;
    }
  `]
})
export class HomeAccordionComponent {

  @Input() titleFont: number = 20;

  panelOpenState1 = '#c8c8c8';
  panelOpenState2 = '#c8c8c8';
  panelOpenState3 = '#c8c8c8';
  panelOpenState4 = '#c8c8c8';

  public isMobileResolution: boolean;

  constructor(private applicationStateService: ApplicationStateService) {
    this.isMobileResolution = applicationStateService.getIsMobileResolution();
  }

}
