import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, interval } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-tech-honeyai-whole',
  templateUrl: './tech-honeyai-whole.component.html',
  styleUrls: ['./tech-honeyai-whole.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TechHoneyaiWholeComponent implements OnInit {

  vSteps = [1, 2, 3, 4];
  actualLang: string = '';

  vTechLinks = [
    {
      path: '/technology/pollen-analysis',
      label: 'honey-ai-whole-visual-link-1',
      icon: 'circle-icon-analisis-polinico.png'
    },
    {
      path: '/technology/yeast-counting',
      label: 'honey-ai-whole-visual-link-2',
      icon: 'circle-icon-conteo-levaduras.png'
    },
    {
      path: '/technology/pfund-color-grading',
      label: 'honey-ai-whole-visual-link-3',
      icon: 'circle-icon-color-pfund.png'
    },
    {
      path: '/technology/honeydew-analysis',
      label: 'honey-ai-whole-visual-link-4',
      icon: 'circle-icon-analisis-mielatos.png'
    },
    {
      path: '/technology/conductivity',
      label: 'honey-ai-whole-visual-link-5',
      icon: 'circle-icon-medida-conductividad.png'
    },
    {
      path: '/technology/crystallization-degree',
      label: 'honey-ai-whole-visual-link-6',
      icon: 'circle-icon-grado-cristalizacion.png'
    },
    {
      path: '/technology/your-custom-application',
      label: 'honey-ai-whole-visual-link-7',
      icon: 'circle-icon-aplicacion-bajo-demanda.png'
    },
    {
      path: '/technology/bee-health',
      label: 'honey-ai-whole-visual-link-8',
      icon: 'circle-icon-diagnostico-infecciones.png'
    },
  ]

  vStarterPack = [
    { icon: 'historical-icon.png', label: 'honey-ai-whole-starter-pack-item-1' },
    { icon: 'videos-icon.png', label: 'honey-ai-whole-starter-pack-item-2' },
    { icon: 'materials-content-icon.png', label: 'honey-ai-whole-starter-pack-item-3' },
    { icon: 'credits-shop-icon.png', label: 'honey-ai-whole-starter-pack-item-4' }
  ]

  vMocksES = [
    '../../../../assets/tech-honeyai-whole/mockups-screen/MockupES-Historial.jpg',
    '../../../../assets/tech-honeyai-whole/mockups-screen/MockupES-login.jpg',
    '../../../../assets/tech-honeyai-whole/mockups-screen/MockupES-perfil.jpg',
    '../../../../assets/tech-honeyai-whole/mockups-screen/MockupES-tutoriales.jpg',
  ]

  vMocksEN = [
    '../../../../assets/tech-honeyai-whole/mockups-screen/MockupEN-Historial.jpg',
    '../../../../assets/tech-honeyai-whole/mockups-screen/MockupEN-login.jpg',
    '../../../../assets/tech-honeyai-whole/mockups-screen/MockupEN-perfil.jpg',
    '../../../../assets/tech-honeyai-whole/mockups-screen/MockupEN-tutoriales.jpg',
  ]

  vCompanysTrustUs = [
    {'src': '../../../../assets/home/early-adopters/apisrom.svg', 'href': 'https://apisrom.ro/en/'},
    {'src': '../../../../assets/home/early-adopters/honeymell.svg', 'href': 'https://www.honeymell.com/en/'},
    {'src': '../../../../assets/home/early-adopters/honning-centralen.png', 'href': 'https://www.honning.no/'},
    {'src': '../../../../assets/home/early-adopters/apisol.png', 'href': 'https://apisol.es/'},
    {'src': '../../../../assets/home/early-adopters/sanfrancisco.png', 'href': 'https://www.granjasanfrancisco.com/en/'},

    {'src': '../../../../assets/home/early-adopters/abeya.png', 'href': 'https://www.abeyareina.com/'},
    {'src': '../../../../assets/home/early-adopters/asincar.png', 'href': 'https://www.asincar.com/'},
    {'src': '../../../../assets/home/early-adopters/biavl.png', 'href': 'https://www.biavl.dk/'},
    {'src': '../../../../assets/home/early-adopters/cmr.png', 'href': 'https://ovmediorural.xunta.gal/es/tramites/laboratorio-de-sanidad-animal'},
    {'src': '../../../../assets/home/early-adopters/coc-Lebanon.png', 'href': 'https://www.cciat.org.lb/'},
    {'src': '../../../../assets/home/early-adopters/esense.png', 'href': 'https://e-sense.se/'},
    {'src': '../../../../assets/home/early-adopters/sunbulah_logo.png', 'href': 'https://www.sunbulahgroup.com/honey-jam.html'},

    {'src': '../../../../assets/home/early-adopters/lbb.png', 'href': 'https://www.medus.lv/'},
    {'src': '../../../../assets/home/early-adopters/ukr-hh.png', 'href': 'https://ukrhousehoney.com/em'}
  ]

  currentMock: number = 0;
  currentCompany: number = 0;
  subscriptionCompany: Subscription;
  subscriptionMock: Subscription;
  videoLink: SafeResourceUrl;

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    // Scroll to top
    window.scroll({ top: 0, left: 0 });
    
    this.actualLang = this.translateService.currentLang === undefined ? 'es' : this.translateService.currentLang;
    this.videoLink = this.getEmbeddedVideoLinks();

    // Event to perform updates when you change language
    this.translateService.onLangChange.subscribe((lang) => {
      this.actualLang = lang.lang;
      this.videoLink = this.getEmbeddedVideoLinks();
    });

    const timeInterval = interval(3000);
    this.subscriptionCompany = timeInterval.subscribe(() => {
      this.onNextCompany();
    })

    this.subscriptionMock = timeInterval.subscribe(() => {
      this.onNextMock();
    })
  }

  getPathImg(i: number): string {
    return `../../../../assets/tech-honeyai-whole/hexa-numbers/hexa-0${i}.png`;
  }

  getPathSubtitle(i: number): string {
    return `honey-ai-whole-how-use-subtitle-${i}`;
  }

  getPathText(i: number): string {
    return `honey-ai-whole-how-use-text-${i}`;
  }

  getPathStarterPathIcon(iconName: string): string {
    return `../../../../assets/tech-honeyai-whole/starter-pack-icons/${iconName}`;
  }

  getItemStarterPack(): string {
    return this.actualLang === 'es' ? 
    '../../../../assets/tech-honeyai-whole/plataforma-honey-pc.png' : 
    '../../../../assets/tech-honeyai-whole/plataforma-honey-pc-en.png';
  }

  getEmbeddedVideoLinks(): SafeResourceUrl {
    return this.actualLang === 'es' ?
      this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/wL2l4-gMARA?controls=0&showinfo=0&rel=0') :
      this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/9Yinkq6vXVs?controls=0&showinfo=0&rel=0'); 
  }
  
  getHexImage(): string {
    return this.actualLang === 'es' ?
    '../../../../assets/tech-honeyai-whole/why-hexagon-messages.png' :
    '../../../../assets/tech-honeyai-whole/why-hexagon-messages-en.png'
  }

  // Click actions
  onPreviousMockClick() {
    this.onPreviousMock();
    this.subscriptionMock.unsubscribe();
  }

  onNextMockClick() {
    this.onNextMock();
    this.subscriptionMock.unsubscribe();
  }

  onPreviousCompanyClick() {
    this.onPreviousCompany();
    this.subscriptionCompany.unsubscribe();
  }
  
  onNextCompanyClick() {
    this.onNextCompany();
    this.subscriptionCompany.unsubscribe();
  }

  // Image changes
  onPreviousMock() {
    const previous2 = this.currentMock - 1;
    this.currentMock = previous2 < 0 ? this.vMocksES.length - 1 : previous2;
  }

  onNextMock() {
    const next = this.currentMock + 1;
    this.currentMock = next === this.vMocksES.length ? 0 : next;
  }

  onPreviousCompany() {
    const previous2 = this.currentCompany - 1;
    this.currentCompany = previous2 < 0 ? this.vCompanysTrustUs.length - 1 : previous2;
  }

  onNextCompany() {
    const next = this.currentCompany + 1;
    this.currentCompany = next === this.vCompanysTrustUs.length ? 0 : next;
  }
  
  @HostListener('click', ['$event'])
  processLinks(e) {
    const element: HTMLElement = e.target;
    if (element.nodeName === 'A') {
      e.preventDefault();
      localStorage.setItem('goToStarterPack', 'true');
      this.router.navigate(['/pricing']);
    }
  }

}
