import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TabsbarComponent } from '../../tabs/tabsbar/tabsbar.component';
import { MatTabGroup } from '@angular/material/tabs';
import { environment } from '../../../../environments/environment';
import { LanguageService } from '../../../service/LanguageService.service';
import { ApplicationStateService } from '../../../service/application-state.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
  privateUrl = environment.privateUrl;
  defaultLang = 'en';

  @ViewChild(TabsbarComponent) private tabGroup: MatTabGroup | any;

  public isMobileResolution: boolean;

  constructor(
    public translate: TranslateService,
    public langService: LanguageService,
    public router: Router,
    private applicationStateService: ApplicationStateService
  ) {
    this.isMobileResolution = applicationStateService.getIsMobileResolution();
    translate.addLangs(['en', 'es']);
    const lang = this.router.url.split('/')[1];

    if (lang === 'es' || lang === 'en') {
      this.translate.use(lang);
      this.langService.switchLang(lang);
      this.defaultLang = lang;
    } else if (lang !== '') {
      
      this.translate.use('es');
      this.defaultLang = 'es';
      this.langService.switchLang('es');
      this.router.navigateByUrl('/es/home');
    }
  }

  switchLang(lang: string) {
    this.translate.use(lang);
    this.router.navigateByUrl(
      this.router.url.replace(`/${this.langService.currentLang}/`, `/${lang}/`)
    );
    this.langService.switchLang(lang);
  }

}
