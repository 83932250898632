<mat-dialog-content>
  <div style="text-align: center; margin: 20px;">
    <div style="margin-bottom: 30px;">
      <img src="../../../../assets/commons/popup/check.svg">
    </div>
    <div>
      <div style="display: inline-flex">
        <p class="text-subtitle-honey" >{{data.title | translate}}</p>
      </div>
      <p class="text-content-honey" >{{data.body | translate}}</p>
      <a [routerLink]="languageService.getPath('home')" mat-dialog-close>
        <button style="margin: 30px 0px 30px 0px;" class="yellow-honey-button">{{data.textButton | translate}}</button>
      </a>
    </div>
  </div>
</mat-dialog-content>

