<!-- carousel -->
<div class="carousel" *ngIf="!isMobileResolution; else mobileCarousel">

  <!-- control previous -->
  <button class="control prev" (click)="onPreviousClick()">
    <span class="arrow left"></span>
  </button>

  <div style="margin-right: 4%;">
    <ng-container *ngFor="let slide of slides; let i = index">
      <a href="{{slide.href}}" target="_blank">
        <img
          *ngIf="i === currentSlide1"
          [src]="slide.src"
          class="slide"
        /></a>
    </ng-container>
  </div>

  <div style="margin-left: 4%;">
    <ng-container *ngFor="let slide of slides; let i = index">
      <a href="{{slide.href}}" target="_blank">
        <img
          *ngIf="i === currentSlide2"
          [src]="slide.src"
          class="slide"
        /></a>
    </ng-container>
  </div>

  <div style="margin-left: 4%;">
    <ng-container *ngFor="let slide of slides; let i = index">
      <a href="{{slide.href}}" target="_blank">
        <img
          *ngIf="i === currentSlide3"
          [src]="slide.src"
          class="slide"
        /></a>
    </ng-container>
  </div>

  <div style="margin-left: 4%;">
    <ng-container *ngFor="let slide of slides; let i = index">
      <a href="{{slide.href}}" target="_blank">
        <img
          *ngIf="i === currentSlide4"
          [src]="slide.src"
          class="slide"
        /></a>
    </ng-container>
  </div>

  <div style="margin-left: 4%;">
    <ng-container *ngFor="let slide of slides; let i = index">
      <a href="{{slide.href}}" target="_blank">
        <img
          *ngIf="i === currentSlide5"
          [src]="slide.src"
          class="slide"
        /></a>
    </ng-container>
  </div>

  <!-- control next -->
  <button class="control next" (click)="onNextClick()">
    <span class="arrow right"></span>
  </button>
</div>

<!-- Mobile -->
<ng-template #mobileCarousel>
  <div class="carousel" style="height: 200px;">

    <!-- control previous -->
    <button class="control prev" (click)="onPreviousClick()">
      <span class="mobile arrow left"></span>
    </button>

    <div style="margin-right: 4%;">
      <ng-container *ngFor="let slide of slides; let i = index">
        <a href="{{slide.href}}" target="_blank">
          <img
            *ngIf="i === currentSlide1"
            [src]="slide.src"
            class="slide"
            style="max-width: 180px;"
          /></a>
      </ng-container>
    </div>

    <!-- control next -->
    <button class="control next" (click)="onNextClick()">
      <span class="mobile arrow right"></span>
    </button>
  </div>

</ng-template>
