import { Component, OnInit, Input } from '@angular/core';
import { LanguageService } from 'src/app/service/LanguageService.service';

@Component({
  selector: 'app-technology-visual-link',
  templateUrl: './technology-visual-link.component.html',
  styleUrls: ['./technology-visual-link.component.css']
})
export class TechnologyVisualLinkComponent implements OnInit {

  @Input() dataLink: any;

  icon: string;
  path: string;
  label: string;

  iconPath: string = '../../../../../assets/tech-honeyai-whole/circle-icon/';

  constructor(public languageService: LanguageService) { }

  ngOnInit(): void {
    this.icon = this.dataLink.icon;
    this.path = this.dataLink.path;
    this.label = this.dataLink.label;
  }

  getIconPath(): string {
    return `${this.iconPath}${this.icon}`;
  }

}
