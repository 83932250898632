import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TabsbarComponent } from './components/tabs/tabsbar/tabsbar.component';
import { MatTabsModule } from '@angular/material/tabs';
import { OurTechnologyTabComponent } from './components/tabs/our-technology-tab/our-technology-tab.component';
import { PricingTabComponent } from './components/tabs/pricing-tab/pricing-tab.component';
import { OrdersTabComponent } from './components/tabs/orders-tab/orders-tab.component';
import { AboutUsTabComponent } from './components/tabs/about-us-tab/about-us-tab.component';
import { BlogTabComponent } from './components/tabs/blog-tab/blog-tab.component';
import { CarouselComponent } from './components/tabs/home-tab/carousel/carousel.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContactUsCardsComponent } from './components/commons/contact-us-cards/contact-us-cards/contact-us-cards.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { HomeTabComponent } from './components/tabs/home-tab/home-tab.component';
import { ContactUsComponent } from './components/tabs/contact-us/contact-us.component';
import { ContactFormComponent } from './components/tabs/contact-us/contact-form/contact-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MapComponent } from './components/tabs/contact-us/map/map.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { FooterComponent } from './components/commons/footer/footer.component';
import { PageNotFoundComponent } from './components/commons/page-not-found/page-not-found.component';
import { HeaderComponent } from './components/commons/header/header.component';
import { TeamMemberPopupComponent } from './components/tabs/about-us-tab/team-member-popup/team-member-popup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AboutUsCarouselComponent } from './components/tabs/about-us-tab/about-us-carousel/about-us-carousel.component';
import { FAQsComponent } from './components/faqs/faqs.component';
import { FAQsCardComponent } from './components/faqs/faqs-card/faqs-card.component';
import { FAQsContentComponent } from './components/faqs/faqs-content/faqs-content.component';
import { FAQsContentTemplateComponent } from './components/faqs/faqs-content/faqs-content-template/faqs-content-template.component';
import { FAQsContentAboutHoneyComponent } from './components/faqs/faqs-content/faqs-content-about-honey/faqs-content-about-honey.component';
import { FAQsContentUsingTheSystemComponent } from './components/faqs/faqs-content/faqs-content-using-the-system/faqs-content-using-the-system.component';
import { FAQsContentInstallMaintenanceComponent } from './components/faqs/faqs-content/faqs-content-install-maintenance/faqs-content-install-maintenance.component';
import { FAQsContentOrdersPricingComponent } from './components/faqs/faqs-content/faqs-content-orders-pricing/faqs-content-orders-pricing.component';
import { FAQsContentShipmentDeliveryComponent } from './components/faqs/faqs-content/faqs-content-shipment-delivery/faqs-content-shipment-delivery.component';
import { FAQsContentAccountComponent } from './components/faqs/faqs-content/faqs-content-account/faqs-content-account.component';
import { FAQsCardService } from './components/faqs/service/FAQsCard.service';
import { PreOrdersFormComponent } from './components/tabs/orders-tab/pre-orders-form/pre-orders-form.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SubmissionPopupComponent } from './components/commons/submission-popup/submission-popup.component';
import { NewsTabComponent } from './components/tabs/news-tab/news-tab.component';
import { MatRadioModule } from '@angular/material/radio';
import { NewsComponentTemplateComponent } from './components/tabs/news-tab/news-component-template/news-component-template.component';
import { HomeAccordionComponent } from './components/tabs/home-tab/home-accordion/home-accordion.component';
import { TermsConditionsComponent } from './components/commons/footer/terms-conditions/terms-conditions.component';
import { TechHoneyaiWholeComponent } from './components/tabs/tech-honeyai-whole/tech-honeyai-whole.component';
import { TechPollenAnalysisComponent } from './components/tabs/tech-pollen-analysis/tech-pollen-analysis.component';
import { CarouselOurTechnologyComponent } from './components/tabs/tech-honeyai-whole/carousel-our-technology/carousel-our-technology.component';
import { StarterPackItemComponent } from './components/tabs/tech-honeyai-whole/starter-pack-item/starter-pack-item.component';
import { TechnologyVisualLinkComponent } from './components/tabs/tech-honeyai-whole/technology-visual-link/technology-visual-link.component';
import { HexagonMessageItemComponent } from './components/tabs/tech-honeyai-whole/hexagons-messages/hexagon-message-item/hexagon-message-item.component';
import { HexagonsMessagesComponent } from './components/tabs/tech-honeyai-whole/hexagons-messages/hexagons-messages.component';
import { TechnologyHeaderContainerComponent } from './components/commons/technology-header-container/technology-header-container.component';
import { FlipCardComponent } from './components/commons/flip-card/flip-card.component';
import { TechCrystallizationDegreeComponent } from './components/tabs/tech-crystallization-degree/tech-crystallization-degree.component';
import { TechPfundColorGradingComponent } from './components/tabs/tech-pfund-color-grading/tech-pfund-color-grading.component';
import { TechYeastCountingComponent } from './components/tabs/tech-yeast-counting/tech-yeast-counting.component';
import { TechConductivityComponent } from './components/tabs/tech-conductivity/tech-conductivity.component';
import { TechHoneydewAnalysisComponent } from './components/tabs/tech-honeydew-analysis/tech-honeydew-analysis.component';
import { TechYourCustomApplicationComponent } from './components/tabs/tech-your-custom-application/tech-your-custom-application.component';
import { SingupComponent } from './components/tabs/singup/singup.component';
import { TechBeeHealthComponent } from './tabs/tech-bee-health/tech-bee-health.component';

import { init } from 'emailjs-com';

@NgModule({
  declarations: [
    AppComponent,
    TabsbarComponent,
    OurTechnologyTabComponent,
    PricingTabComponent,
    OrdersTabComponent,
    AboutUsTabComponent,
    BlogTabComponent,
    CarouselComponent,
    ContactUsCardsComponent,
    HomeTabComponent,
    ContactUsComponent,
    ContactFormComponent,
    MapComponent,
    FooterComponent,
    PageNotFoundComponent,
    HeaderComponent,
    TeamMemberPopupComponent,
    AboutUsCarouselComponent,
    FAQsComponent,
    FAQsCardComponent,
    FAQsContentComponent,
    FAQsContentTemplateComponent,
    FAQsContentAboutHoneyComponent,
    FAQsContentUsingTheSystemComponent,
    FAQsContentInstallMaintenanceComponent,
    FAQsContentOrdersPricingComponent,
    FAQsContentShipmentDeliveryComponent,
    FAQsContentAccountComponent,
    PreOrdersFormComponent,
    SubmissionPopupComponent,
    NewsTabComponent,
    NewsComponentTemplateComponent,
    HomeAccordionComponent,
    TermsConditionsComponent,
    TechHoneyaiWholeComponent,
    TechPollenAnalysisComponent,
    CarouselOurTechnologyComponent,
    HexagonsMessagesComponent,
    HexagonMessageItemComponent,
    TechnologyVisualLinkComponent,
    StarterPackItemComponent,
    TechnologyHeaderContainerComponent,
    FlipCardComponent,
    TechCrystallizationDegreeComponent,
    TechPfundColorGradingComponent,
    TechYeastCountingComponent,
    TechConductivityComponent,
    TechHoneydewAnalysisComponent,
    TechYourCustomApplicationComponent,
    SingupComponent,
    TechBeeHealthComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatTabsModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    HttpClientModule,
    MatSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MatOptionModule,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
    LeafletModule,
    MatDialogModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTooltipModule,
  ],
  providers: [FAQsCardService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor() {
    init('ktt-vMgHLOf_cNEYr');
  }
}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
