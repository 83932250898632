import { Component, OnInit } from '@angular/core';
import { FAQsCardService } from '../service/FAQsCard.service';
import { ApplicationStateService } from '../../../service/application-state.service';
import { LanguageService } from 'src/app/service/LanguageService.service';

@Component({
  selector: 'app-faqs-content',
  templateUrl: './faqs-content.component.html',
  styleUrls: ['./faqs-content.component.css'],
})
export class FAQsContentComponent implements OnInit {
  componentToShow = 1;
  public isMobileResolution: boolean;

  constructor(
    public dataService: FAQsCardService,
    public languageService: LanguageService,
    private applicationStateService: ApplicationStateService
  ) {
    this.isMobileResolution = applicationStateService.getIsMobileResolution();
  }

  ngOnInit(): void {
    // Scroll to top
    window.scroll({ top: 0, left: 0 });

    this.componentToShow = this.dataService.selectedFAQsCard;
  }

  isComponentActive(number: number) {
    if (number == this.componentToShow) return 0;
    else return 1;
  }

  cardChange(number: number) {
    this.componentToShow = number;
  }
}
