<div *ngIf="!isMobileResolution; else mobileHomeContent">
  <!-- Header -->
  <app-header></app-header>

  <div class="toolbar">
    <div class="tabs-image">
      <div
        [routerLink]="languageService.getPath(homeLink)"
        (click)="activeLink = homeLink"
      >
        <img
          width="220px"
          alt="Honey.AI Logo"
          src="../../../../assets/home/logo-honey-ai.svg"
        />
      </div>
    </div>

    <div class="toolbar-tabs">
      <div class="menu-link-technology menu-link-item">
        <div
          [routerLink]="languageService.getPath(homeLink)"
          (click)="activeLink = homeLink"
        >
          {{ "technology" | translate }}
        </div>

        <div class="submenu-technology">
          <button
            *ngFor="let linkTech of techOptions"
            [routerLink]="languageService.getPath(linkTech.path)"
            [routerLinkActive]="'item-submenu-technology-active'"
            [disabled]="linkTech.path === ''"
            class="item-menu-technology"
          >
            <div>{{ linkTech.title | translate }}</div>
          </button>
        </div>
      </div>
      <div
        class="menu-link-item"
        *ngFor="let link of links; index as i"
        [routerLink]="languageService.getPath(link)"
        (click)="activeLink = link"
      >
        {{ titles[i] | translate }}
      </div>
    </div>
  </div>

  <router-outlet></router-outlet>

  <app-footer></app-footer>
</div>

<ng-template #mobileHomeContent>
  <!-- Header -->
  <app-header></app-header>
  <div class="tabs-mobile">
    <div class="button-header-mobile">
      <button mat-button (click)="showMobileMenu = !showMobileMenu">
        <img src="assets/home/header/menu-icon.svg" />
      </button>
    </div>
  </div>
  <div class="mobile-menu" [ngClass]="{ 'show-mobile-menu': showMobileMenu }">
    <div class="container-options-mobile-menu">
      <div class="mobile-menu-item title-submenu">
        <a style="text-decoration: none">
          <div class="menu-item-mobile">
            <div (click)="showMobileSubMenu = !showMobileSubMenu">
              {{ "our-technology" | translate }}
              <img
                class="down-arrow-svg"
                src="assets/home/header/arrow-down.svg"
              />
            </div>

            <div
              class="submenu-mobile"
              [ngClass]="{ 'show-mobile-submenu': showMobileSubMenu }"
            >
              <div
                class="submenu-item-mobile"
                *ngFor="let linkTech of techOptionsMobile"
              >
                <a
                  [routerLink]="languageService.getPath(linkTech.path)"
                  (click)="showMobileMenu = false; showMobileSubMenu = false"
                  style="text-decoration: none"
                >
                  <button
                    [disabled]="linkTech.path === ''"
                    mat-button
                    class="submenu-item-mobile-title"
                  >
                    {{ linkTech.title | translate }}
                  </button>
                </a>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="mobile-menu-item">
        <a
          [routerLink]="languageService.getPath('/pricing')"
          style="text-decoration: none"
          ><button mat-button class="menu-item-mobile">
            {{ "Pricing" | translate }}
          </button></a
        >
      </div>
      <div class="mobile-menu-item">
        <a
          [routerLink]="languageService.getPath('/pre-orders')"
          style="text-decoration: none"
          ><button mat-button class="menu-item-mobile">
            {{ "pre-orders" | translate }}
          </button></a
        >
      </div>
      <div class="mobile-menu-item">
        <a
          [routerLink]="languageService.getPath('/about-us')"
          style="text-decoration: none"
          ><button mat-button class="menu-item-mobile">
            {{ "About-us" | translate }}
          </button></a
        >
      </div>
      <div class="mobile-menu-item">
        <a
          [routerLink]="languageService.getPath('/news-events')"
          style="text-decoration: none"
          ><button mat-button class="menu-item-mobile">
            {{ "news-events" | translate }}
          </button></a
        >
      </div>
      <div class="mobile-menu-item">
        <a
          [routerLink]="languageService.getPath('/FAQs')"
          style="text-decoration: none"
          ><button mat-button class="menu-item-mobile">
            {{ "FAQs" | translate }}
          </button></a
        >
      </div>
      <div class="mobile-menu-item">
        <a
          [routerLink]="languageService.getPath('/contact')"
          style="text-decoration: none"
          ><button mat-button class="menu-item-mobile mat-menu-contact-button">
            {{ "contact" | translate }}
          </button></a
        >
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
</ng-template>
