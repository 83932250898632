<div
  class="benefits-card"
  (mouseenter)="reversed = true"
  (mouseleave)="reversed = false"
>
  <div class="both-sides-card" [ngClass]="{ 'show-back': reversed === true }">
    <div class="front-side">
      <img [src]="circleIconPath + itemFlipCard.iconName" />
      <div [innerHTML]="itemFlipCard.cardName | translate"></div>
    </div>

    <div class="back-side">
      <div
        *ngFor="let itemText of itemFlipCard.vString"
        class="text"
        [ngClass]="{ 'item-list': itemText.type === 'LIST' }"
      >
        <div>✓</div>
        <div [innerHTML]="itemText.text | translate"></div>
      </div>
    </div>
  </div>
</div>
