import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tech-conductivity',
  templateUrl: './tech-conductivity.component.html',
  styleUrls: ['./tech-conductivity.component.css']
})
export class TechConductivityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scroll({  top: 0, left: 0 });
  }

}
