import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ApplicationStateService} from "../../../service/application-state.service";

@Component({
  selector: 'app-orders-tab',
  templateUrl: './orders-tab.component.html',
  styleUrls: ['./orders-tab.component.css']
})
export class OrdersTabComponent implements OnInit {

  @Input() hideForm: boolean;
  @ViewChild('panel') public panel:ElementRef;
  @ViewChild('panelMobile') public panelMobile:ElementRef;

  public isMobileResolution: boolean;

  constructor(private applicationStateService: ApplicationStateService) {
    this.isMobileResolution = applicationStateService.getIsMobileResolution();
  }

  ngOnInit(): void {
    window.scrollTo(0,0);
    this.hideForm = true;
  }

  pressButton(){
    this.hideForm = false;
    setTimeout(() => {
      if(!this.isMobileResolution) this.panel.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
      else this.panelMobile.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    });
  }
}
