import {Component, Input} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ApplicationStateService} from "../../../../service/application-state.service";

@Component({
  selector: 'app-about-us-carousel',
  templateUrl: './about-us-carousel.component.html',
  styleUrls: ['./about-us-carousel.component.css']
})
export class AboutUsCarouselComponent {

  @Input() slides: any;

  public isMobileResolution: boolean;

  constructor(public dialog: MatDialog, private applicationStateService: ApplicationStateService) {
    this.isMobileResolution = applicationStateService.getIsMobileResolution();
  }

  currentSlide1 = 0;
  currentSlide2 = 1;
  currentSlide3 = 2;
  showThird: 100;
  marginThird: 3;

  onPreviousClick() {
    const previous1 = this.currentSlide1 - 1;
    const previous2 = this.currentSlide2 - 1;
    const previous3 = this.currentSlide3 - 1;
    this.currentSlide1 = previous1 < 0 ? this.slides.length - 1 : previous1;
    this.currentSlide2 = previous2 < 0 ? this.slides.length - 1 : previous2;
    this.currentSlide3 = previous3 < 0 ? this.slides.length - 1 : previous3;
  }

  onNextClick() {
    const next1 = this.currentSlide1 + 1;
    const next2 = this.currentSlide2 + 1;
    const next3 = this.currentSlide3 + 1;
    this.currentSlide1 = next1 === this.slides.length ? 0 : next1;
    this.currentSlide2 = next2 === this.slides.length ? 0 : next2;
    this.currentSlide3 = next3 === this.slides.length ? 0 : next3;
  }
}
