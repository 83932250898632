<div class="new-left-component" *ngIf="!isMobileResolution; else mobileNewsContentTemplate">
  <div class="horizontal-divs image-new-left-component">
    <img style="max-width: 100%; max-height: 500px; border-radius: 10px;"
         src="assets/news-events/images/new-{{index}}-{{'lang' | translate}}.png">
  </div>
  <div class="horizontal-divs">
    <p style="color: #E9BD02">{{ 'new-date-'+index | translate}}</p>
    <p style="font-weight: bold; font-size: 24px;">{{ 'new-title-'+index | translate}}</p>
    <div [innerHtml]="safeHtml"></div>
    <a target="_blank" href="{{href}}">
      <button class="white-honey-button" style="height: 40px;" [hidden]="hiddenButton">
        {{ 'new-buttonText-'+index | translate}}
      </button>
    </a>
  </div>
</div>


<ng-template #mobileNewsContentTemplate>
  <div class="new-left-component">
    <div class="horizontal-divs image-new-left-component">
      <img style="max-width: 100%; max-height: 100%; border-radius: 4px;"
           src="assets/news-events/images/new-{{index}}-{{'lang' | translate}}.png">
    </div>
    <div class="horizontal-divs">
      <p style="color: #E9BD02">{{ 'new-date-'+index | translate}}</p>
      <p style="font-weight: bold; font-size: 20px;">{{ 'new-title-'+index | translate}}</p>

      <button (click)="showContent()" [hidden]=!hiddenContent class="no-style-button">
        <p style="color: #E9BD02; font-weight: bold">{{'read-more' | translate}} ></p>
      </button>
      <button (click)="hideContent()" [hidden]=hiddenContent class="no-style-button">
        <p style="color: #E9BD02; font-weight: bold">{{'hide-content' | translate}} </p>
      </button>

    </div>
  </div>

  <!-- Content component -->
  <div [hidden]=hiddenContent class="news-content-mobile">
    <div [innerHtml]="safeHtml"></div>
    <a target="_blank" href="{{href}}">
      <button class="white-honey-button" style="height: 40px;" [hidden]="hiddenButton">
        {{ 'new-buttonText-'+index | translate}}
      </button>
    </a>
  </div>

</ng-template>
