import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent implements OnInit{

  safeHtmlGeneral: SafeHtml | undefined;
  safeHtmlStatements: SafeHtml | undefined;
  safeHtmlBody: SafeHtml | undefined;


  constructor(private sanitizer: DomSanitizer,
              private translate: TranslateService) {}

  ngOnInit(): void {
    this.translate.get('terms-and-conditions-general-info-body').subscribe(
      (foo: string) => {
        this.safeHtmlGeneral = this.sanitizer.bypassSecurityTrustHtml(foo);
      });
    this.translate.get('terms-and-conditions-statements-body').subscribe(
      (foo: string) => {
        this.safeHtmlStatements = this.sanitizer.bypassSecurityTrustHtml(foo);
      });
    this.translate.get('terms-and-conditions-body-privacy-policy').subscribe(
      (foo: string) => {
        this.safeHtmlBody = this.sanitizer.bypassSecurityTrustHtml(foo);
      });

  }

}


