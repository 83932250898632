import { Component, OnInit } from '@angular/core';
import { ApplicationStateService } from '../../../service/application-state.service';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { MatDialog } from '@angular/material/dialog';
import { LanguageService } from 'src/app/service/LanguageService.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  public isMobileResolution: boolean;

  constructor(
    private applicationStateService: ApplicationStateService,
    public dialog: MatDialog,
    public languageService: LanguageService
  ) {
    this.isMobileResolution = applicationStateService.getIsMobileResolution();
  }

  ngOnInit(): void {}

  showPrivacyPolicy() {
    this.dialog.open(TermsConditionsComponent);
  }
}
