import {Injectable} from "@angular/core";

@Injectable({ providedIn: 'root' })

export class FAQsCardService {

  selectedFAQsCard = 1;

  getValue(){
    return this.selectedFAQsCard;
  }

  setValue(value: number){
    this.selectedFAQsCard = value;
  }

}
