import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment } from '../../environments/environment';
import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';

// APIEndpoints: 'https://cloud.honey-ai.com'

@Injectable({ providedIn: 'root' })
export class NewsletterService {
  baseUrl = environment.APIEndpoints;

  constructor(private http: HttpClient) { }

  initHeaders() {
    let headers = new HttpHeaders()
    return headers;
  }

  addContact (mail: any): Observable<Object> {
    let headers = this.initHeaders();
    headers = headers.set('Accept', 'application/json');
    headers = headers.set('Content-Type', 'application/json');

    return this.http.post(this.baseUrl +'/api/newsletter', JSON.stringify({email: mail}), { headers: headers });
  }

}
