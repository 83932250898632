import {HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from "@angular/common/http";
import { environment } from "../../environments/environment";
import {Injectable} from "@angular/core";
import {ContactForm} from "../model/ContactForm";
import {PreorderForm} from "../model/PreorderForm";


@Injectable({ providedIn: 'root' })

export class FromDataService {

  constructor(private http: HttpClient) { }

  baseUrl = environment.APIEndpoints;

  initHeaders() {
    let headers = new HttpHeaders()
    return headers;
  }

  sendForm(data, type){
    let headers = this.initHeaders();
    headers = headers.set('Accept', 'application/json');
    data['type'] = type;
    return this.http.post(this.baseUrl +'/backend/form',
      data, { headers: headers });
  }
  
  registerUser(data){
    let headers = this.initHeaders();
    headers = headers.set('Accept', 'application/json');

    return this.http.post(this.baseUrl +'/backend/user/create',
      data, { headers: headers });
  }
}

