<div class="faq-content-accordions-div">
  <p style="text-align: center"
     [class]="!isMobileResolution? 'text-subtitle-honey': 'home-info-subtitle-mobile'">
    {{"using-the-sys" | translate}}</p>
  <mat-accordion>
    <app-faqs-content-template faqId="faq-using-the-sys-1"></app-faqs-content-template>
    <app-faqs-content-template faqId="faq-using-the-sys-2"></app-faqs-content-template>
    <app-faqs-content-template faqId="faq-using-the-sys-3"></app-faqs-content-template>
    <app-faqs-content-template faqId="faq-using-the-sys-4"></app-faqs-content-template>
    <app-faqs-content-template faqId="faq-using-the-sys-5"></app-faqs-content-template>
    <app-faqs-content-template faqId="faq-using-the-sys-6"></app-faqs-content-template>
  </mat-accordion>
</div>
