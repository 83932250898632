import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ApplicationStateService } from '../../../service/application-state.service';
import { LanguageService } from 'src/app/service/LanguageService.service';

@Component({
  selector: 'app-pricing-tab',
  templateUrl: './pricing-tab.component.html',
  styleUrls: ['./pricing-tab.component.css'],
})
export class PricingTabComponent implements OnInit, AfterViewInit {
  privateUrl = environment.privateUrl;
  @ViewChild('panel') public panel: ElementRef;

  public isMobileResolution: boolean;

  constructor(
    private applicationStateService: ApplicationStateService,
    public languageService: LanguageService
  ) {
    this.isMobileResolution = applicationStateService.getIsMobileResolution();
  }

  ngOnInit(): void {
    // Scroll to top
    if (localStorage.getItem('goToStarterPack') !== null) {
      localStorage.removeItem('goToStarterPack');
      this.pressInitPack();
      return;
    }
    window.scroll({ top: 0, left: 0 });
  }

  ngAfterViewInit(): void {
    if (localStorage.getItem('goToStarterPack') !== null) {
      localStorage.removeItem('goToStarterPack');
      return;
    }
  }

  pressInitPack() {
    setTimeout(() => {
      this.panel.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'end',
      });
    }, 100);
  }
}
