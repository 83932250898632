import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {DialogData} from "../about-us-tab.component";
import {ApplicationStateService} from "../../../../service/application-state.service";

@Component({
  selector: 'app-team-member-popup',
  templateUrl: './team-member-popup.component.html',
  styleUrls: ['./team-member-popup.component.css']
})
export class TeamMemberPopupComponent {

  public isMobileResolution: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private applicationStateService: ApplicationStateService) {

    this.isMobileResolution = applicationStateService.getIsMobileResolution();
  }

}
