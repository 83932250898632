<!-- carousel -->
<div class="carousel" *ngIf="!isMobileResolution; else mobileCarouselContent">

  <!-- control previous -->
  <button class="control prev" (click)="onPreviousClick()">
    <span class="arrow left"></span>
  </button>

  <div style="width: 100%;">
    <ng-container *ngFor="let slide of slides; let i = index">
      <a href="{{slide.href}}" >
        <img
          *ngIf="i === currentSlide1"
          [src]="slide.src"
          class="slide"
        /></a>
    </ng-container>
  </div>

  <div style="width: 100%; margin-right: 6%; margin-left: 6%;">
    <ng-container *ngFor="let slide of slides; let i = index">
      <a href="{{slide.href}}">
        <img
          *ngIf="i === currentSlide2"
          [src]="slide.src"
          class="slide"
        /></a>
    </ng-container>
  </div>

  <div style="width: {{showThird}}%;">
    <ng-container *ngFor="let slide of slides; let i = index">
      <a href="{{slide.href}}">
        <img
          *ngIf="i === currentSlide3"
          [src]="slide.src"
          class="slide"
        /></a>
    </ng-container>
  </div>


  <!-- control next -->
  <button class="control next" (click)="onNextClick()">
    <span class="arrow right"></span>
  </button>
</div>


<!-- Mobile -->
<ng-template #mobileCarouselContent>
  <!-- carousel -->
  <div class="carousel">

    <!-- control previous -->
    <button class="control prev" (click)="onPreviousClick()">
      <span class="arrow left"></span>
    </button>

    <div style="width: 100%;">
      <ng-container *ngFor="let slide of slides; let i = index">
        <a href="{{slide.href}}" >
          <img
            *ngIf="i === currentSlide1"
            [src]="slide.src"
            class="slide"
            style="max-width: 205px;"/></a>
      </ng-container>
    </div>

    <!-- control next -->
    <button class="control next" (click)="onNextClick()">
      <span class="arrow right"></span>
    </button>
  </div>
</ng-template>
