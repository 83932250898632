import { Component, OnInit } from '@angular/core';
import {ApplicationStateService} from "../../../service/application-state.service";
import { NewsletterService } from 'src/app/service/newsletter.service';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-news-tab',
  templateUrl: './news-tab.component.html',
  styleUrls: ['./news-tab.component.css']
})
export class NewsTabComponent implements OnInit {

  public isMobileResolution: boolean;
  public newsletterForm = this.formBuilder.group({
    email: ''
  });
  public thanksSubmitNewsletterVisibility: string = 'hidden';

  constructor(
    private applicationStateService: ApplicationStateService,
    private formBuilder: FormBuilder,
    private newsletterService: NewsletterService) {
    this.isMobileResolution = applicationStateService.getIsMobileResolution();
  }

  news: { index: string, href: string, hiddenButton: boolean} [];

  ngOnInit() {
    // Scroll to top
    window.scroll({ top: 0, left: 0 });

    this.news = [
      {
        index: '43',
        href: '',
        hiddenButton: true
      },
      {
        index: '42',
        href: '',
        hiddenButton: true
      },
      {
        index: '41',
        href: '',
        hiddenButton: true
      },
      {
        index: '40',
        href: '',
        hiddenButton: true
      },
      {
        index: '39',
        href: '',
        hiddenButton: true
      },
      {
        index: '38',
        href: '',
        hiddenButton: true
      },
      {
        index: '37',
        href: '',
        hiddenButton: true
      },
      {
        index: '36',
        href: '',
        hiddenButton: true
      },
      {
        index: '35',
        href: '',
        hiddenButton: true
      },
      {
        index: '34',
        href: '',
        hiddenButton: true
      },
      {
        index: '33',
        href: '',
        hiddenButton: true
      },
      {
        index: '32',
        href: '',
        hiddenButton: true
      },
      {
        index: '31',
        href: '',
        hiddenButton: true
      },
      {
        index: '30',
        href: '',
        hiddenButton: true
      },
      {
        index: '29',
        href: '',
        hiddenButton: true
      },
      {
        index: '28',
        href: '',
        hiddenButton: true
      },
      {
        index: '27',
        href: '',
        hiddenButton: true
      },
      {
        index: '26',
        href: '',
        hiddenButton: true
      },
      {
        index: '25',
        href: '',
        hiddenButton: true
      },
      {
        index: '24',
        href: '',
        hiddenButton: true
      },
      {
        index: '23',
        href: '',
        hiddenButton: true
      },
      {
        index: '22',
        href: '',
        hiddenButton: true
      },
      {
        index: '21',
        href: '',
        hiddenButton: true
      },
      {
        index: '20',
        href: '',
        hiddenButton: true
      },
      {
        index: '19',
        href: '',
        hiddenButton: true
      },
      {
        index: '18',
        href: '',
        hiddenButton: true
      },
      {
        index: '17',
        href: '',
        hiddenButton: true
      },
      {
        index: '16',
        href: '',
        hiddenButton: true
      },
      {
        index: '15',
        href: '',
        hiddenButton: true
      },
      {
        index: '14',
        href: '',
        hiddenButton: true
      },
      {
        index: '13',
        href: '',
        hiddenButton: true
      },
      {
        index: '12',
        href: '',
        hiddenButton: true
      },
      {
        index: '11',
        href: '',
        hiddenButton: true
      },
      {
        index: '10',
        href: '',
        hiddenButton: true
      },
      {
        index: '9',
        href: '',
        hiddenButton: true
      },
      {
        index: '8',
        href: '',
        hiddenButton: true
      },
      {
        index: '7',
        href: '',
        hiddenButton: true
      },
      {
        index: '6',
        href: '',
        hiddenButton: true
      },
      {
        index: '5',
        href: '',
        hiddenButton: true
      },
      {
        index: '4',
        href: 'https://aicongress.barcelona/en/inscripcions',
        hiddenButton: false
      },
      {
        index: '3',
        href: '',
        hiddenButton: true
      },
      {
        index: '2',
        href: '',
        hiddenButton: true
      },
      {
        index: '1',
        href: '',
        hiddenButton: true
      }
    ];
  }

  onSubmitNewsletter (): void {
    const response = this.newsletterService.addContact(this.newsletterForm.value.email);
    response.subscribe(
      data => console.log('Correcto'),
      error => console.log('Error')
    );

    this.thanksSubmitNewsletterVisibility = 'visible';
    this.newsletterForm.reset();
  }
}
