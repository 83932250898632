<div class="faq-content-accordions-div">
  <p style="text-align: center"
     [class]="!isMobileResolution? 'text-subtitle-honey': 'home-info-subtitle-mobile'">
    {{"install-maintenance" | translate}}</p>
  <mat-accordion>
    <app-faqs-content-template faqId="faq-install-maintenance-1"></app-faqs-content-template>
    <app-faqs-content-template faqId="faq-install-maintenance-2"></app-faqs-content-template>
    <app-faqs-content-template faqId="faq-install-maintenance-3"></app-faqs-content-template>
    <app-faqs-content-template faqId="faq-install-maintenance-4"></app-faqs-content-template>
    <app-faqs-content-template faqId="faq-install-maintenance-5"></app-faqs-content-template>
    <app-faqs-content-template faqId="faq-install-maintenance-6"></app-faqs-content-template>
  </mat-accordion>
</div>

