<div style="text-align: center; margin: 6%">
  <app-header></app-header>
  <div>
    <img style="width: 70%; text-align: center" src="assets/commons/404.svg" />
  </div>
  <p
    [class]="
      !isMobileResolution ? 'text-title-honey' : 'home-info-title-mobile'
    "
  >
    {{ "error-404-title" | translate }}
  </p>
  <p
    [class]="
      !isMobileResolution ? 'text-content-honey' : 'home-info-body-mobile'
    "
    style="margin-bottom: 60px"
  >
    {{ "error-404-body" | translate }}
  </p>
  <a href="{{ publicUrl }}">
    <button class="yellow-honey-button">
      {{ "error-404-button-redirect" | translate }}
    </button>
  </a>

  <app-footer></app-footer>
</div>
