<div class="container-honeyai-whole">

    <div class="container-includes">

        <div class="title">
            {{ 'honey-ai-whole-title-one' | translate }}
        </div>

        <div class="subtitle" [innerHTML]="'honey-ai-whole-subtitle-one' | translate"></div>

        <div class="items-includes">
            <div class="item-include">
                <div class="icon">
                    <img src="../../../../assets/tech-honeyai-whole/tech-microscopio-robotizado.svg">
                </div>
                <div class="text">
                    {{ 'honey-ai-whole-item-ejecutable-app' | translate }}
                </div>
            </div>
            <div class="item-include-separator">
                <img src="../../../../assets/tech-honeyai-whole/arrows-left-right.svg" />
            </div>
            <div class="item-include">
                <div class="icon">
                    <img src="../../../../assets/tech-honeyai-whole/tech-aplicacion-ejecutable.svg">
                </div>
                <div class="text">
                    {{ 'honey-ai-whole-item-robot-micros' | translate }}
                </div>
            </div>
            <div class="item-include-separator">
                <img src="../../../../assets/tech-honeyai-whole/cloud-arrow-up.svg" />
            </div>
            <div class="item-include">
                <div class="icon">
                    <img src="../../../../assets/tech-honeyai-whole/tech-redes-neuronales.svg">
                </div>
                <div class="text">
                    {{ 'honey-ai-whole-item-ai-chip' | translate }}
                </div>
            </div>
        </div>
    </div>

    <div class="container-how-use">
        <div class="how-use-description">

            <div class="how-use-title">
                {{ 'honey-ai-whole-how-use-title' | translate }}
            </div>

            <div class="bee-fly-content"></div>
            <div class="little-bee-content"></div>

            <div class="how-use-steps">
                <div class="how-use-item-step" *ngFor="let i of vSteps">
                    <div class="img-number">
                        <img [src]="getPathImg(i)">
                    </div>
                    <div class="how-use-item-step-description">
                        <div class="how-use-item-step-description-title" 
                            [innerHTML]="getPathSubtitle(i) | translate"
                            (click)="processLinks($event)"></div>
                        <div class="how-use-item-step-description-text">
                            {{ getPathText(i) | translate }}
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="how-use-carousel">
            <app-carousel-our-technology></app-carousel-our-technology>
        </div>
    </div>

    <div class="container-know-your-product">


        <div class="title">
            {{ 'honey-ai-whole-know-your-product-title' | translate }}
        </div>

        <div class="subtitle">
            {{ 'honey-ai-whole-know-your-product-subtitle' | translate }}
        </div>

        <div class="text">
            {{ 'honey-ai-whole-know-your-product-text' | translate }}
        </div>

        <div class="container-video-yt">

            <div class="mobile-arrow-content">
                <div class="message-video how-i-use-it">
                    <div class="text">
                        {{ 'honey-ai-whole-how-I-use-it' | translate }}
                    </div>
                </div>
                <div class="message-video how-i-do-prepare-the-sample">
                    <div class="text">
                        {{ 'honey-ai-whole-how-I-do-prepare-the-sample' | translate }}
                    </div>
                </div>
                <div class="message-video what-advantages-does-it-have">
                    <div class="text">
                        {{ 'honey-ai-whole-what-advantages-does-it-have' | translate }}
                    </div>
                    <div class="arrow">
                        <img src="../../../../assets/commons/curved-arrow.png" />
                    </div>
                </div>
            </div>


            <div class="left-side-video">

                <div class="message-video how-i-use-it">
                    <div class="text">
                        {{ 'honey-ai-whole-how-I-use-it' | translate }}
                    </div>
                    <div class="arrow">
                        <img src="../../../../assets/commons/curved-arrow.png" />
                    </div>
                </div>
    
            </div>

            <div class="video">
                <img class="border-video-top" src="../../../../assets/commons/border-video-yt.png" />
                <iframe width="800" height="450" [src]="videoLink"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen></iframe>
                <img class="border-video-bottom" src="../../../../assets/commons/border-video-yt.png" />
                <img class="honey-jar-img" src="../../../../assets/tech-honeyai-whole/honey-jar-yt-video.png" />
            </div>

            <div class="right-side-video">
                <div class="message-video how-i-do-prepare-the-sample">
                    <div class="text">
                        {{ 'honey-ai-whole-how-I-do-prepare-the-sample' | translate }}
                    </div>
                    <div class="arrow">
                        <img src="../../../../assets/commons/curved-arrow.png" />
                    </div>
                </div>
    
                <div class="message-video what-advantages-does-it-have">
                    <div class="arrow">
                        <img src="../../../../assets/commons/curved-arrow.png" />
                    </div>
                    <div class="text">
                        {{ 'honey-ai-whole-what-advantages-does-it-have' | translate }}
                    </div>
                </div>
            </div>

            

        </div>
    </div>


    <div class="container-why-honey-ai">

        <div class="title">
            {{ 'honey-ai-whole-why-honey-ai-title' | translate }}
        </div>


        <div class="text">
            {{ 'honey-ai-whole-why-honey-ai-text' | translate }}
        </div>

        <div class="container-hex-messages">
            <img [src]="getHexImage()" />
        </div>
    </div>

    <div class="container-other-pages-link">
        <div class="container-links">
            <app-technology-visual-link *ngFor="let itemLinkData of vTechLinks"
                [dataLink]="itemLinkData"></app-technology-visual-link>
        </div>
    </div>

    <div class="container-starter-pack">
        <div class="container-carousel">
            <div class="image-content">
                <img [src]="actualLang === 'en' ? vMocksEN[currentMock] : vMocksES[currentMock]" />
            </div>
            <div class="arrows-content">
                <button mat-fab (click)="onPreviousMockClick()">
                    <img src="../../../../assets/commons/icons/left-yellow-arrow.png" />
                </button>
                <button mat-fab (click)="onNextMockClick()">
                    <img src="../../../../assets/commons/icons/right-yellow-arrow.png" />
                </button>
            </div>
        </div>
        <div class="container-description">
            <div class="title">
                {{ 'honey-ai-whole-starter-pack-title' | translate }}
            </div>
            <div class="text" [innerHTML]="'honey-ai-whole-starter-pack-text' | translate"> </div>
            <div class="container-items-starter-pack">
                <img [src]="getItemStarterPack()" />
            </div>
        </div>
        <div class="container-carousel-mobile">
            <img src="../../../../assets/tech-honeyai-whole/mockup-login.png" />
        </div>
    </div>

    <div class="container-already-trust-us">

        <div class="already-trust-us-text">
            {{ 'honey-ai-whole-already-trust-us' | translate }}
        </div>
    </div>

    <div class="container-carousel-already-trust-us" style="padding: 36px 60px 36px 60px;">
        <app-carousel></app-carousel>
    </div>
</div>