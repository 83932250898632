import {AfterViewInit, Component, Renderer2, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {SubmissionPopupComponent} from "../../commons/submission-popup/submission-popup.component";
import {FromDataService} from "../../../service/FromDataService.service";
import {HttpClient} from "@angular/common/http";
import {ApplicationStateService} from "../../../service/application-state.service";
import {TermsConditionsComponent} from "../../commons/footer/terms-conditions/terms-conditions.component";
import {OrganizationDataService} from "../../../service/organization-service";
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-singup',
  templateUrl: './singup.component.html',
  styleUrls: ['./singup.component.css']
})
export class SingupComponent implements AfterViewInit, OnInit {

  
  public preOrdersForm: FormGroup;
  formService: FromDataService | any;
  selected: null;
  public isMobileResolution: boolean;
  companyName: string;

  constructor( private _httpClient: HttpClient,
               private formBuilder: FormBuilder,
               public dialog: MatDialog,
               private applicationStateService: ApplicationStateService,
               private organizationDataService: OrganizationDataService,
               private route: ActivatedRoute,
               private renderer: Renderer2
               ) {
    
    this.isMobileResolution = applicationStateService.getIsMobileResolution();
    this.preOrdersForm = this.formBuilder.group({
      firstName: '',
      lastName: '',
      email: '',
      username: '',
      password: '',
      password1: '',
      company: '',
    })
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {

     

      this.organizationDataService.getOrganizationName(params['companyId']).subscribe(
        (response: any) => {
          // Asignamos directamente la cadena a this.companyName
         
          this.companyName = response;

          //change the company parameter in the preOrdersForm
          this.preOrdersForm.controls['company'].setValue(params['companyId']);
        },
        (error: HttpErrorResponse) => {
          console.error("Error al obtener el nombre de la organización:", error);
          
          location.href = "/";

          if (error.error instanceof ErrorEvent) {
            // Error de red u otro error del cliente
            console.error("Error del cliente:", error.error.message);
          } else {
            // El servidor retornó un código de error
            console.error("Código de error:", error.status);
            console.error("Cuerpo de error:", error.error);
          }
        }
      );
    });

    
  }


  
  ngAfterViewInit(): void {
    this.formService = new FromDataService(this._httpClient);
    
    
  }

  onSubmit(registerData: any){
   
    var elements = document.querySelectorAll('.form-control');
    var errorInForm = false;

    elements.forEach(function (element) {
      // Add a red border to each element
      let htmlElement = element as HTMLElement;
    
      // Add a red border to each element
      htmlElement.style.borderBottom = '';
    }); 


    if (registerData.firstName.length < 2){
      this.highlighInput("firstName"); 
      errorInForm = true;
    }
      
    if (registerData.lastName.length < 2){
      this.highlighInput("lastName"); 
      errorInForm = true;
    }
      

    if (!this.isEmailValid(registerData.email)){
      this.highlighInput("email"); 
      errorInForm = true;
    }
      
    if (registerData.username.length < 2){
      this.highlighInput("username");
       errorInForm = true;
    }
      
    if (registerData.password.length < 3 || registerData.password1.length < 3 || registerData.password !== registerData.password1){
      this.highlighInput("password");
      this.highlighInput("password1");
      errorInForm = true;
    }
     


    if (!errorInForm){
      this.formService.registerUser(registerData).subscribe(
        (response: any) => {
          console.log(response);
          this.openDialog();
        },
        (error: HttpErrorResponse) => {
          console.error("Error al registrar el usuario:", error);

          if (error.error instanceof ErrorEvent) {
            // Error de red u otro error del cliente
            console.error("Error del cliente:", error.error.message);
          } else {
            // El servidor retornó un código de error
            console.error("Código de error:", error.status);
            console.error("Cuerpo de error:", error.error);
          }
        }
      );
    } 

   


    
  }

  isEmailValid(email: string): boolean {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the provided email matches the regular expression
    return emailRegex.test(email);
  }

  highlighInput(idName): void {
    const nameElement = document.getElementById(idName);
  
    if (nameElement) {
      nameElement.style.borderBottom = "3px solid red";
    }
  }

  openDialog() {
    const data = {
      data: {
        title: 'Thank you for registering!',
        body: 'Now you can enter the system and start using it.',
        textButton: 'Back to home'
      }
    }
    this.dialog.open(SubmissionPopupComponent, data);
  }

  showPrivacyPolicy() {
    this.dialog.open(TermsConditionsComponent);
  }

  
  togglePasswordVisibility(controlName: string) {
    const control = this.preOrdersForm.get(controlName);
  
    if (control) {
      const inputElement = document.getElementById(controlName);
  
      if (inputElement) {
        const eyeIconElement = inputElement.nextElementSibling?.firstChild as HTMLImageElement;

        
        const currentType = inputElement.getAttribute('type');
        const newType = currentType === 'password' ? 'text' : 'password';

        // Cambia el atributo 'type' del elemento
        this.renderer.setAttribute(inputElement, 'type', newType);

        // Cambia la fuente de la imagen del ojo
        const newIconSrc = currentType !== 'password'
          ? '../../../../assets/password-icons/eye.png'
          : '../../../../assets/password-icons/eye-hide.png';
        
        this.renderer.setAttribute(eyeIconElement, 'src', newIconSrc);
        
      }
    }
  }

  
  
  
  
}
