import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-tab',
  templateUrl: './blog-tab.component.html',
  styleUrls: ['./blog-tab.component.css']
})
export class BlogTabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // Scroll to top
    window.scroll({ top: 0, left: 0 });
  }

}
