import { Component, OnInit } from '@angular/core';
import {ApplicationStateService} from "../../../../service/application-state.service";

@Component({
  selector: 'app-faqs-content-install-maintenance',
  templateUrl: './faqs-content-install-maintenance.component.html',
  styleUrls: ['./faqs-content-install-maintenance.component.css']
})
export class FAQsContentInstallMaintenanceComponent {

  public isMobileResolution: boolean;

  constructor(private applicationStateService: ApplicationStateService) {
    this.isMobileResolution = applicationStateService.getIsMobileResolution();
  }

}
