<div
  class="home-content"
  *ngIf="!isMobileResolution; else mobileAboutUsContent"
>
  <!-- About us - The company -->
  <div class="divs-horizontally">
    <div class="team-image-container">
      <img
        alt="Company image"
        src="../../../../assets/about-us/team-honeyai.jpeg"
        style="border-radius: 150px"
      />
    </div>
    <div class="div-company-text">
      <p class="text-title-honey text-align-left">
        {{ "About-us" | translate }}
      </p>
      <p class="text-subtitle-honey text-align-left">
        {{ "The company" | translate }}
      </p>
      <p
        class="body-text justify text-align-left"
        style="white-space: pre-line"
      >
        {{ "company-explanation" | translate }}
      </p>
    </div>
  </div>

  <!-- About us cards -->
  <div class="divs-horizontally">
    <div class="about-us-cards margin-left margin-between-cards-right">
      <div class="card-image">
        <img src="../../../../assets/about-us/vision.svg" />
      </div>
      <p class="text-subtitle-honey text-align-left card-title-text">
        {{ "Vision" | translate }}
      </p>
      <p class="body-text text-align-left card-body-text">
        {{ "Vision-text" | translate }}
      </p>
    </div>
    <div
      class="about-us-cards margin-between-cards-right margin-between-cards-left"
    >
      <div class="card-image">
        <img src="../../../../assets/about-us/mission.svg" />
      </div>
      <p class="text-subtitle-honey text-align-left card-title-text">
        {{ "Mission" | translate }}
      </p>
      <p class="body-text text-align-left card-body-text">
        {{ "Mission-text" | translate }}
      </p>
    </div>
    <div class="about-us-cards margin-between-cards-left">
      <div class="card-image">
        <img src="../../../../assets/about-us/values.svg" />
      </div>
      <p class="text-subtitle-honey text-align-left card-title-text">
        {{ "Values" | translate }}
      </p>
      <p class="body-text text-align-left card-body-text">
        {{ "Values-text" | translate }}
      </p>
    </div>
  </div>

  <!-- About us cards -->
  <div class="team-card">
    <p class="text-subtitle-honey">
      {{ "meet-team" | translate }}
    </p>
    <p class="body-text">
      {{ "meet-team-text" | translate }}
    </p>
    <div class="divs-horizontally">
      <div class="team-member-card">
        <input
          style="width: 80%"
          type="image"
          src="assets/about-us/team-members/iratxe.png"
          (click)="openDialog(2)"
        />
        <a style="cursor: pointer" (click)="openDialog(2)">
          <p class="member-name-text">Iratxe Perales</p>
          <p class="member-body-text">{{ "iratxe-title" | translate }}</p>
        </a>
      </div>
      <div class="team-member-card">
        <input
          style="width: 80%"
          type="image"
          src="assets/about-us/team-members/edu.png"
          (click)="openDialog(3)"
        />
        <a style="cursor: pointer" (click)="openDialog(3)">
          <p class="member-name-text">Eduard López</p>
          <p class="member-body-text">{{ "edu-title" | translate }}</p>
        </a>
      </div>
      <!--Asma-->
      <div class="team-member-card" id="pic-round">
        <input
          style="width: 80%"
          type="image"
          src="assets/about-us/team-members/asma.png"
          (click)="openDialog(6)"
        />
        <a style="cursor: pointer" (click)="openDialog(6)">
          <p class="member-name-text">Asma Ghorab</p>
          <p class="member-body-text">{{ "asma-title" | translate }}</p>
        </a>
      </div>
      <div class="team-member-card">
        <input
          style="width: 80%"
          type="image"
          src="assets/about-us/team-members/alberto.png"
          (click)="openDialog(9)"
        />
        <a style="cursor: pointer" (click)="openDialog(9)">
          <p class="member-name-text">Alberto Fernández</p>
          <p class="member-body-text">{{ "alberto-title" | translate }}</p>
        </a>
      </div>
    </div>
    <div class="divs-horizontally">
      <!--Goddy-->
      <div class="team-member-card" id="pic-round">
        <input
          style="width: 80%"
          type="image"
          src="assets/about-us/team-members/alvaro.png"
          (click)="openDialog(7)"
        />
        <a style="cursor: pointer" (click)="openDialog(7)">
          <p class="member-name-text">Álvaro Suárez</p>
          <p class="member-body-text">{{ "alvaro-title" | translate }}</p>
        </a>
      </div>
      <!--Julian-->
      <div class="team-member-card">
        <input
          style="width: 80%"
          type="image"
          src="assets/about-us/team-members/julian.png"
          (click)="openDialog(8)"
        />
        <a style="cursor: pointer" (click)="openDialog(8)">
          <p class="member-name-text">Julian Rodriguez</p>
          <p class="member-body-text">{{ "julian-title" | translate }}</p>
        </a>
      </div>
      <div class="team-member-card">
        <input
          style="width: 80%"
          type="image"
          src="assets/about-us/team-members/naoufal.png"
          (click)="openDialog(11)"
        />
        <a style="cursor: pointer" (click)="openDialog(11)">
          <p class="member-name-text">Naoufal Amrani</p>
          <p class="member-body-text">{{ "naoufal-title" | translate }}</p>
        </a>
      </div>
      <div class="team-member-card">
        <input
          style="width: 80%"
          type="image"
          src="assets/about-us/team-members/miguel.png"
          (click)="openDialog(10)"
        />
        <a style="cursor: pointer" (click)="openDialog(10)">
          <p class="member-name-text">Miguel Moreno</p>
          <p class="member-body-text">{{ "miguel-title" | translate }}</p>
        </a>
      </div>
    </div>
  </div>

  <div class="divs-horizontally" style="margin-right: 0px">
    <div class="div-company-text margin-right" style="width: 40%">
      <p class="text-subtitle-honey">
        {{ "our-premises" | translate }}
      </p>
      <p class="body-text justify" style="white-space: pre-line">
        {{ "our-premises-explanation" | translate }}
      </p>
      <button
        [routerLink]="languageService.getPath('/contact')"
        type="submit"
        class="yellow-honey-button"
        style="width: 30%"
      >
        {{ "Visit us" | translate }}
      </button>
    </div>
    <div style="width: 60%; text-align: right">
      <img
        alt="Company site"
        src="../../../../assets/about-us/sonicat-site.svg"
      />
    </div>
  </div>

  <!-- Partners container -->
  <div class="partners-container">
    <div class="partners-subtitle">Funding, Collaborations & Partners</div>

    <div class="img-partners-container">
      <div class="img-container" *ngFor="let itemPartner of partnersAssets">
        <a [href]="itemPartner.url" target="_blank">
          <img [src]="imgPartnersPath + itemPartner.path" />
        </a>
      </div>
    </div>
  </div>

</div>

<!-- Mobile view -->
<ng-template #mobileAboutUsContent>
  <!-- About us - The company -->
  <div>
    <div class="info-div-mobile-about-us">
      <p
        class="home-info-title-mobile"
        style="margin-bottom: 0px; line-height: 22px"
      >
        {{ "About-us" | translate }}
      </p>
      <p
        class="home-info-subtitle-mobile"
        style="margin-top: 0px; font-size: 16px"
      >
        {{ "The company" | translate }}
      </p>
    </div>
    <div style="text-align: center">
      <img
        style="width: 90%; border-radius: 100px"
        alt="Company image"
        src="assets/about-us/team-honeyai.jpeg"
      />
    </div>
    <div class="info-div-mobile">
      <p class="home-info-body-mobile" style="white-space: pre-line">
        {{ "company-explanation" | translate }}
      </p>
    </div>
  </div>

  <!-- About us cards -->
  <div class="info-div-mobile">
    <div class="about-us-cards-mobile">
      <div class="card-image">
        <img src="../../../../assets/about-us/vision.svg" />
      </div>
      <p class="text-subtitle-honey text-align-left card-title-text">
        {{ "Vision" | translate }}
      </p>
      <p class="body-text text-align-left card-body-text">
        {{ "Vision-text" | translate }}
      </p>
    </div>
    <div class="about-us-cards-mobile">
      <div class="card-image">
        <img src="../../../../assets/about-us/mission.svg" />
      </div>
      <p class="text-subtitle-honey text-align-left card-title-text">
        {{ "Mission" | translate }}
      </p>
      <p class="body-text text-align-left card-body-text">
        {{ "Mission-text" | translate }}
      </p>
    </div>
    <div class="about-us-cards-mobile">
      <div class="card-image">
        <img src="../../../../assets/about-us/values.svg" />
      </div>
      <p class="text-subtitle-honey text-align-left card-title-text">
        {{ "Values" | translate }}
      </p>
      <p class="body-text text-align-left card-body-text">
        {{ "Values-text" | translate }}
      </p>
    </div>
  </div>

  <!-- About us cards -->
  <div class="team-card">
    <p class="home-info-subtitle-mobile">
      {{ "meet-team" | translate }}
    </p>
    <p class="home-info-body-mobile">
      {{ "meet-team-text" | translate }}
    </p>
    <div class="team-member-card">
      <input
        style="width: 60%"
        type="image"
        src="assets/about-us/team-members/iratxe.png"
        (click)="openDialog(2)"
      />
      <a style="cursor: pointer" (click)="openDialog(2)">
        <p class="member-name-text">Iratxe Perales</p>
        <p class="member-body-text">{{ "iratxe-title" | translate }}</p>
      </a>
    </div>
    <div class="team-member-card">
      <input
        style="width: 60%"
        type="image"
        src="assets/about-us/team-members/edu.png"
        (click)="openDialog(3)"
      />
      <a style="cursor: pointer" (click)="openDialog(3)">
        <p class="member-name-text">Eduard López</p>
        <p class="member-body-text">{{ "edu-title" | translate }}</p>
      </a>
    </div>
    <!--Asma-->
    <div class="team-member-card">
      <div>
        <input
          style="width: 60%"
          type="image"
          src="assets/about-us/team-members/asma.png"
          (click)="openDialog(6)"
        />
      </div>
      <a style="cursor: pointer" (click)="openDialog(6)">
        <p class="member-name-text">Asma Ghorab</p>
        <p class="member-body-text">{{ "asma-title" | translate }}</p>
      </a>
    </div>
    <div class="team-card">
      <!--Alvaro-->
      <div class="team-member-card">
        <input
          style="width: 60%"
          type="image"
          src="assets/about-us/team-members/alvaro.png"
          (click)="openDialog(7)"
        />
        <a style="cursor: pointer" (click)="openDialog(7)">
          <p class="member-name-text">Álvaro Suárez</p>
          <p class="member-body-text">{{ "alvaro-title" | translate }}</p>
        </a>
      </div>
      <!--Julian-->
      <div class="team-member-card">
        <input
          style="width: 60%"
          type="image"
          src="assets/about-us/team-members/julian.png"
          (click)="openDialog(8)"
        />
        <a style="cursor: pointer" (click)="openDialog(8)">
          <p class="member-name-text">Julian Rodriguez</p>
          <p class="member-body-text">{{ "julian-title" | translate }}</p>
        </a>
      </div>
      <!--Alberto-->
      <div class="team-member-card">
        <input
          style="width: 60%"
          type="image"
          src="assets/about-us/team-members/alberto.png"
          (click)="openDialog(9)"
        />
        <a style="cursor: pointer" (click)="openDialog(8)">
          <p class="member-name-text">Alberto Fernández</p>
          <p class="member-body-text">{{ "alberto-title" | translate }}</p>
        </a>
      </div>
      <!--Miguel-->
      <div class="team-member-card">
        <input
          style="width: 60%"
          type="image"
          src="assets/about-us/team-members/miguel.png"
          (click)="openDialog(10)"
        />
        <a style="cursor: pointer" (click)="openDialog(10)">
          <p class="member-name-text">Miguel Moreno</p>
          <p class="member-body-text">{{ "miguel-title" | translate }}</p>
        </a>
      </div>
      <!--Naoufal-->
      <div class="team-member-card">
        <input
          style="width: 60%"
          type="image"
          src="assets/about-us/team-members/naoufal.png"
          (click)="openDialog(11)"
        />
        <a style="cursor: pointer" (click)="openDialog(11)">
          <p class="member-name-text">Naoufal Amrani</p>
          <p class="member-body-text">{{ "naoufal-title" | translate }}</p>
        </a>
      </div>
    </div>
  </div>

  <!-- Our premises -->
  <div class="info-div-mobile">
    <p class="home-info-subtitle-mobile">
      {{ "our-premises" | translate }}
    </p>
    <p class="home-info-body-mobile">
      {{ "our-premises-explanation" | translate }}
    </p>
    <div style="text-align: center; margin-bottom: 30px">
      <img
        alt="Company site"
        style="width: 100%"
        src="assets/about-us/sonicat-site-mobile.png"
      />
    </div>
    <button 
    [routerLink]="languageService.getPath('/contact')"
      type="submit"
      class="yellow-honey-button"
      style="width: 80%"
    >
      {{ "Visit us" | translate }}
    </button>
  </div>

  <div class="partners-container">
    <div class="partners-subtitle">Funding, Collaborations & Partners</div>

    <div class="img-partners-container">
      <div class="img-container" *ngFor="let itemPartner of partnersAssets">
        <a [href]="itemPartner.url" target="_blank">
          <img [src]="imgPartnersPath + itemPartner.path" />
        </a>
      </div>
    </div>
  </div>


</ng-template>
