<div class="faq-content-accordions-div">
  <p style="text-align: center"
     [class]="!isMobileResolution? 'text-subtitle-honey': 'home-info-subtitle-mobile'">
    {{"my-account" | translate}}</p>
  <mat-accordion>
    <mat-expansion-panel style=" box-shadow: none;">
      <mat-expansion-panel-header class="expansion-header-class-faqs">
        <mat-panel-title>
          <span> {{ 'faq-my-account-1-title'| translate}} </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p style="text-align: justify; white-space: pre-line;">{{'faq-my-account-1-a-body'| translate}}
        <a [routerLink]="languageService.getPath('/pre-orders')" style="color: black;">{{'HERE'| translate}}</a>. {{'faq-my-account-1-b-body'| translate}}
      </p>
    </mat-expansion-panel>
    <app-faqs-content-template faqId="faq-my-account-2"></app-faqs-content-template>
    <app-faqs-content-template faqId="faq-my-account-3"></app-faqs-content-template>
    <app-faqs-content-template faqId="faq-my-account-4"></app-faqs-content-template>
  </mat-accordion>
</div>

