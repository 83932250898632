<div *ngIf="!isMobileResolution; else mobileContentFAQs">
  <!-- Header -->
  <app-header></app-header>

  <div class="tabs-image">
    <a [routerLink]="languageService.getPath('/home')">
      <img
        width="220px"
        alt="Honey.AI Logo"
        src="../../../../assets/home/logo-honey-ai.svg"
      />
    </a>
  </div>

  <div class="centered-honey-text-div flex">
    <p class="text-title-honey text-align-center">
      {{ "Frequently Asked Questions" | translate }}
    </p>
    <p class="text-subtitle-honey text-align-center">
      {{ "here-to-help" | translate }}
    </p>
  </div>

  <div style="margin-top: 80px; place-content: center" class="faqs-cards-div">
    <app-faqs-card
      (click)="cardChange(1)"
      class="card-div-content"
      [compId]="isComponentActive(1)"
      cardText="about-honey"
      imageUrl="../../assets/faqs/about-honeyAI.svg"
      imageHeight="70"
      fontSize="16"
    ></app-faqs-card>

    <app-faqs-card
      (click)="cardChange(2)"
      class="card-div-content"
      [compId]="isComponentActive(2)"
      cardText="using-the-sys"
      imageUrl="../../assets/faqs/using-the-system.svg"
      imageHeight="70"
      fontSize="16"
    ></app-faqs-card>

    <app-faqs-card
      (click)="cardChange(3)"
      class="card-div-content"
      [compId]="isComponentActive(3)"
      cardText="install-maintenance"
      imageUrl="../../assets/faqs/installation-mantenance.svg"
      imageHeight="70"
      fontSize="16"
    ></app-faqs-card>

    <app-faqs-card
      (click)="cardChange(4)"
      class="card-div-content"
      [compId]="isComponentActive(4)"
      cardText="orders-pricing"
      imageUrl="../../assets/faqs/orders-pricing.svg"
      imageHeight="70"
      fontSize="16"
    ></app-faqs-card>

    <app-faqs-card
      (click)="cardChange(5)"
      class="card-div-content"
      [compId]="isComponentActive(5)"
      cardText="shipment-delivery"
      imageUrl="../../assets/faqs/shipment-delivery.svg"
      imageHeight="70"
      fontSize="16"
    ></app-faqs-card>

    <app-faqs-card
      (click)="cardChange(6)"
      class="card-div-content"
      [compId]="isComponentActive(6)"
      cardText="my-account"
      imageUrl="../../assets/faqs/account.svg"
      imageHeight="70"
      fontSize="16"
    ></app-faqs-card>
  </div>

  <div [ngSwitch]="componentToShow">
    <app-faqs-content-about-honey
      *ngSwitchDefault
    ></app-faqs-content-about-honey>
    <app-faqs-content-using-the-system
      *ngSwitchCase="2"
    ></app-faqs-content-using-the-system>
    <app-faqs-content-install-maintenance
      *ngSwitchCase="3"
    ></app-faqs-content-install-maintenance>
    <app-faqs-content-orders-pricing
      *ngSwitchCase="4"
    ></app-faqs-content-orders-pricing>
    <app-faqs-content-shipment-delivery
      *ngSwitchCase="5"
    ></app-faqs-content-shipment-delivery>
    <app-faqs-content-account *ngSwitchCase="6"></app-faqs-content-account>
  </div>

  <!-- Footer -->
  <app-footer></app-footer>
</div>

<ng-template #mobileContentFAQs>
  <!-- Header -->
  <app-header></app-header>

  <div class="info-div-mobile">
    <p class="home-info-title-mobile">
      {{ "Frequently Asked Questions" | translate }}
    </p>
    <p class="home-info-subtitle-mobile">
      {{ "here-to-help" | translate }}
    </p>
  </div>

  <div style="margin-top: 40px; place-content: center" class="faqs-cards-div">
    <app-faqs-card
      (click)="cardChange(1)"
      class="card-div-content-mobile"
      [compId]="isComponentActive(1)"
      imageUrl="../../assets/faqs/about-honeyAI.svg"
      imageHeight="40"
      fontSize="16"
    ></app-faqs-card>

    <app-faqs-card
      (click)="cardChange(2)"
      class="card-div-content-mobile"
      [compId]="isComponentActive(2)"
      imageUrl="../../assets/faqs/using-the-system.svg"
      imageHeight="40"
      fontSize="16"
    ></app-faqs-card>

    <app-faqs-card
      (click)="cardChange(3)"
      class="card-div-content-mobile"
      [compId]="isComponentActive(3)"
      imageUrl="../../assets/faqs/installation-mantenance.svg"
      imageHeight="40"
      fontSize="16"
    ></app-faqs-card>

    <app-faqs-card
      (click)="cardChange(4)"
      class="card-div-content-mobile"
      [compId]="isComponentActive(4)"
      imageUrl="../../assets/faqs/orders-pricing.svg"
      imageHeight="40"
      fontSize="16"
    ></app-faqs-card>

    <app-faqs-card
      (click)="cardChange(5)"
      class="card-div-content-mobile"
      [compId]="isComponentActive(5)"
      imageUrl="../../assets/faqs/shipment-delivery.svg"
      imageHeight="40"
      fontSize="16"
    ></app-faqs-card>

    <app-faqs-card
      (click)="cardChange(6)"
      class="card-div-content-mobile"
      [compId]="isComponentActive(6)"
      imageUrl="../../assets/faqs/account.svg"
      imageHeight="40"
      fontSize="16"
    ></app-faqs-card>
  </div>

  <div [ngSwitch]="componentToShow">
    <app-faqs-content-about-honey
      *ngSwitchDefault
    ></app-faqs-content-about-honey>
    <app-faqs-content-using-the-system
      *ngSwitchCase="2"
    ></app-faqs-content-using-the-system>
    <app-faqs-content-install-maintenance
      *ngSwitchCase="3"
    ></app-faqs-content-install-maintenance>
    <app-faqs-content-orders-pricing
      *ngSwitchCase="4"
    ></app-faqs-content-orders-pricing>
    <app-faqs-content-shipment-delivery
      *ngSwitchCase="5"
    ></app-faqs-content-shipment-delivery>
    <app-faqs-content-account *ngSwitchCase="6"></app-faqs-content-account>
  </div>

  <!-- Footer -->
  <app-footer></app-footer>
</ng-template>
