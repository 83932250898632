import { Component } from '@angular/core';
import {ApplicationStateService} from "../../../../service/application-state.service";

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})

export class CarouselComponent {

  public isMobileResolution: boolean;

  constructor(private applicationStateService: ApplicationStateService) {
    this.isMobileResolution = applicationStateService.getIsMobileResolution();
    setInterval(
      this.onNextClick.bind(this),
      3000
    )
  }

  currentSlide1 = 0;
  currentSlide2 = 1;
  currentSlide3 = 2;
  currentSlide4 = 3;
  currentSlide5 = 4;

  slides =  [
        {'src': '../../../assets/home/early-adopters/apisrom.svg', 'href': 'https://apisrom.ro/en/'},
        {'src': '../../../assets/home/early-adopters/honeymell.svg', 'href': 'https://www.honeymell.com/en/'},
        {'src': '../../../assets/home/early-adopters/honning-centralen.png', 'href': 'https://www.honning.no/'},
        {'src': '../../../assets/home/early-adopters/apisol.png', 'href': 'https://apisol.es/'},
        {'src': '../../../assets/home/early-adopters/sanfrancisco.png', 'href': 'https://www.granjasanfrancisco.com/en/'},
        
        {'src': '../../../../assets/home/early-adopters/abeya.png', 'href': 'https://www.abeyareina.com/'},
        {'src': '../../../../assets/home/early-adopters/apigeo_logo.png', 'href': 'https://tradewithgeorgia.com/companies/apigeo-ltd-1'},
        {'src': '../../../../assets/home/early-adopters/asincar.png', 'href': 'https://www.asincar.com/'},
        {'src': '../../../../assets/home/early-adopters/biavl.png', 'href': 'https://www.biavl.dk/'},
        {'src': '../../../../assets/home/early-adopters/cmr.png', 'href': 'https://ovmediorural.xunta.gal/es/tramites/laboratorio-de-sanidad-animal'},
        {'src': '../../../../assets/home/early-adopters/coc-Lebanon.png', 'href': 'https://www.cciat.org.lb/'},
        {'src': '../../../../assets/home/early-adopters/esense.png', 'href': 'https://e-sense.se/'},
        {'src': '../../../../assets/home/early-adopters/sunbulah_logo.png', 'href': 'https://www.sunbulahgroup.com/honey-jam.html'},

        {'src': '../../../../assets/home/early-adopters/lbb.png', 'href': 'https://www.medus.lv/'},
        {'src': '../../../../assets/home/early-adopters/ukr-hh.png', 'href': 'https://ukrhousehoney.com/em'}
  ];

  onPreviousClick() {
    const previous1 = this.currentSlide1 - 1;
    const previous2 = this.currentSlide2 - 1;
    const previous3 = this.currentSlide3 - 1;
    const previous4 = this.currentSlide4 - 1;
    const previous5 = this.currentSlide5 - 1;
    
    this.currentSlide1 = previous1 < 0 ? this.slides.length - 1 : previous1;
    this.currentSlide2 = previous2 < 0 ? this.slides.length - 1 : previous2;
    this.currentSlide3 = previous3 < 0 ? this.slides.length - 1 : previous3;
    this.currentSlide4 = previous4< 0 ? this.slides.length - 1 : previous4;
    this.currentSlide5 = previous5< 0 ? this.slides.length - 1 : previous5;
  }

  onNextClick() {
    const next1 = this.currentSlide1 + 1;
    const next2 = this.currentSlide2 + 1;
    const next3 = this.currentSlide3 + 1;
    const next4 = this.currentSlide4 + 1;
    const next5 = this.currentSlide5 + 1;

    this.currentSlide1 = next1 === this.slides.length ? 0 : next1;
    this.currentSlide2 = next2 === this.slides.length ? 0 : next2;
    this.currentSlide3 = next3 === this.slides.length ? 0 : next3;
    this.currentSlide4 = next4 === this.slides.length ? 0 : next4;
    this.currentSlide5 = next5 === this.slides.length ? 0 : next5;
  }

  

}
