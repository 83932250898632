import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FromDataService } from 'src/app/service/FromDataService.service';
import { MatDialog } from '@angular/material/dialog';
import { SubmissionPopupComponent } from '../../commons/submission-popup/submission-popup.component';
import { Router } from '@angular/router';
import { TermsConditionsComponent } from '../../commons/footer/terms-conditions/terms-conditions.component';
import {
  FLIP_CARD_LIST_TYPE,
  FLIP_CARD_PARAGRAPH_TYPE,
  FlipCardData,
} from '../../commons/flip-card/flip-card.component';
import { ApplicationStateService } from 'src/app/service/application-state.service';
import { EmailJSResponseStatus, send } from 'emailjs-com';
import { LanguageService } from 'src/app/service/LanguageService.service';

interface MicroHoverPointData {
  top: number;
  left: number;
  text: string;
}

@Component({
  selector: 'app-tech-pollen-analysis',
  templateUrl: './tech-pollen-analysis.component.html',
  styleUrls: ['./tech-pollen-analysis.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TechPollenAnalysisComponent implements OnInit {
  @ViewChild('panel') public panel: ElementRef;

  currentLanguage: string = 'es';
  isMobileResolucion: boolean;
  errorForm: boolean = false;
  hideForm: boolean = true;
  contactUsForm: FormGroup = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(200)]],
    company: ['', [Validators.required, Validators.maxLength(200)]],
    cif: ['', [Validators.required, Validators.maxLength(10)]],
    deliveryAddr: ['', [Validators.required, Validators.maxLength(200)]],
    phone: ['', [Validators.required, Validators.maxLength(15)]],
    email: [
      '',
      [Validators.required, Validators.maxLength(100), Validators.email],
    ],
    numberSamples: [
      '',
      [Validators.required, Validators.min(1), Validators.max(100)],
    ],
    typeSamples: ['', [Validators.required, Validators.maxLength(200)]],
    acceptTerms: [false, [Validators.requiredTrue]],
  });

  title: string = 'pollen-analysis-title';
  subtitle: string = 'pollen-analysis-what-is';
  vText: string[] = [
    'pollen-analysis-what-is-text-1',
    'pollen-analysis-what-is-text-2',
    'pollen-analysis-what-is-text-3',
  ];
  vCarousel: string[] = [
    'pa-carousel-1.png',
    'pa-carousel-2.png',
    'pa-carousel-3.png',
    'pa-carousel-4.png',
    'pa-carousel-5.png',
    'pa-carousel-6.png',
    'pa-carousel-7.png',
  ];

  // Flip Cards
  vFlipCardData: FlipCardData[] = [
    {
      iconName: 'circle-icon-clasificacion-miel.png',
      cardName: 'pollen-analysis-benefics-item-1',
      vString: [
        {
          text: 'pollen-analysis-card-1-text-1',
          type: FLIP_CARD_PARAGRAPH_TYPE,
        },
        {
          text: 'pollen-analysis-card-1-text-2',
          type: FLIP_CARD_PARAGRAPH_TYPE,
        },
      ],
    },
    {
      iconName: 'circle-icon-medida-calidad-pureza.png',
      cardName: 'pollen-analysis-benefics-item-2',
      vString: [
        {
          text: 'pollen-analysis-card-2-text-1',
          type: FLIP_CARD_PARAGRAPH_TYPE,
        },
      ],
    },
    {
      iconName: 'circle-icon-conocimientos-colmenas.png',
      cardName: 'pollen-analysis-benefics-item-3',
      vString: [
        {
          text: 'pollen-analysis-card-3-text-1',
          type: FLIP_CARD_PARAGRAPH_TYPE,
        },
        {
          text: 'pollen-analysis-card-3-text-2',
          type: FLIP_CARD_PARAGRAPH_TYPE,
        },
        {
          text: 'pollen-analysis-card-3-text-3',
          type: FLIP_CARD_PARAGRAPH_TYPE,
        },
      ],
    },
    {
      iconName: 'circle-icon-criterios-mezclado.png',
      cardName: 'pollen-analysis-benefics-item-4',
      vString: [
        {
          text: 'pollen-analysis-card-4-text-1',
          type: FLIP_CARD_PARAGRAPH_TYPE,
        },
        {
          text: 'pollen-analysis-card-4-text-2',
          type: FLIP_CARD_PARAGRAPH_TYPE,
        },
      ],
    },
    {
      iconName: 'circle-icon-mejor-control-producto.png',
      cardName: 'pollen-analysis-benefics-item-5',
      vString: [
        {
          text: 'pollen-analysis-card-5-text-1',
          type: FLIP_CARD_PARAGRAPH_TYPE,
        },
        {
          text: 'pollen-analysis-card-5-text-2',
          type: FLIP_CARD_PARAGRAPH_TYPE,
        },
      ],
    },
    {
      iconName: 'circle-icon-identificacion-otras-particulas.png',
      cardName: 'pollen-analysis-benefics-item-6',
      vString: [
        {
          text: 'pollen-analysis-card-6-text-1',
          type: FLIP_CARD_PARAGRAPH_TYPE,
        },
        { text: 'pollen-analysis-card-6-text-2', type: FLIP_CARD_LIST_TYPE },
        { text: 'pollen-analysis-card-6-text-3', type: FLIP_CARD_LIST_TYPE },
        { text: 'pollen-analysis-card-6-text-4', type: FLIP_CARD_LIST_TYPE },
      ],
    },
  ];

  public vPdf1InfoPoints: MicroHoverPointData[] = [
    {
      top: this.currentLanguage === 'en' ? 11 : 11.1,
      left: this.currentLanguage === 'en' ? 5 : 5.5,
      text: 'pollen-pdf-hover-point-1',
    },
    {
      top: 15.4,
      left: this.currentLanguage === 'en' ? 5 : 5.5,
      text: 'pollen-pdf-hover-point-2',
    },
    {
      top: 18.7,
      left: this.currentLanguage === 'en' ? 5 : 5.5,
      text: 'pollen-pdf-hover-point-3',
    },
    {
      top: this.currentLanguage === 'en' ? 20.8 : 20.6,
      left: this.currentLanguage === 'en' ? 38 : 49,
      text: 'pollen-pdf-hover-point-5',
    },
    {
      top: 13.3,
      left: this.currentLanguage === 'en' ? 71.2 : 74,
      text: 'pollen-pdf-hover-point-6',
    },
    {
      top: this.currentLanguage === 'en' ? 29.8 : 29.55,
      left: this.currentLanguage === 'en' ? 18 : 22,
      text: 'pollen-pdf-hover-point-7',
    },
    {
      top: this.currentLanguage === 'en' ? 31.6 : 31.3,
      left: this.currentLanguage === 'en' ? 17 : 28,
      text: 'pollen-pdf-hover-point-8',
    },
    {
      top: this.currentLanguage === 'en' ? 33.5 : 33.2,
      left: this.currentLanguage === 'en' ? 36 : 38,
      text: 'pollen-pdf-hover-point-9',
    },
    {
      top: this.currentLanguage === 'en' ? 35.2 : 34.8,
      left: this.currentLanguage === 'en' ? 18.5 : 24,
      text: 'pollen-pdf-hover-point-10',
    },
    {
      top: this.currentLanguage === 'en' ? 37 : 36.7,
      left: this.currentLanguage === 'en' ? 33 : 36,
      text: 'pollen-pdf-hover-point-11',
    },
    {
      top: this.currentLanguage === 'en' ? 38.8 : 38.4,
      left: this.currentLanguage === 'en' ? 33.5 : 37.5,
      text: 'pollen-pdf-hover-point-12',
    },
    {
      top: this.currentLanguage === 'en' ? 40.6 : 40.2,
      left: this.currentLanguage === 'en' ? 42.5 : 45,
      text: 'pollen-pdf-hover-point-13',
    },
    {
      top: this.currentLanguage === 'en' ? 42.4 : 41.9,
      left: this.currentLanguage === 'en' ? 46.4 : 43,
      text: 'pollen-pdf-hover-point-14',
    },
    {
      top: this.currentLanguage === 'en' ? 51 : 50.2,
      left: this.currentLanguage === 'en' ? 5 : 5.5,
      text: 'pollen-pdf-hover-point-16',
    },
    {
      top: this.currentLanguage === 'en' ? 66.2 : 64.2,
      left: this.currentLanguage === 'en' ? 5 : 5.5,
      text: 'pollen-pdf-hover-point-17',
    },
    {
      top: this.currentLanguage === 'en' ? 46.8 : 46.3,
      left: this.currentLanguage === 'en' ? 72 : 71,
      text: 'pollen-pdf-hover-point-21',
    },
    {
      top: this.currentLanguage === 'en' ? 46.8 : 46.3,
      left: this.currentLanguage === 'en' ? 77.5 : 77.5,
      text: 'pollen-pdf-hover-point-22',
    },
  ];

  public vPdf2InfoPoints: MicroHoverPointData[] = [
    {
      top: this.currentLanguage === 'en' ? 8.3 : 11,
      left: this.currentLanguage === 'en' ? 5 : 5.5,
      text: 'pollen-pdf-hover-point-19',
    },
    {
      top: 77.7,
      left: 12,
      text: 'pollen-pdf-hover-point-20',
    },
  ];

  sending: boolean = false;

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
    private formService: FromDataService,
    public dialog: MatDialog,
    private router: Router,
    private applicationStateService: ApplicationStateService,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    // Scroll to top
    window.scroll({ top: 0, left: 0 });

    this.translateService.onLangChange.subscribe((lang) => {
      this.currentLanguage = lang.lang;
    });

    this.currentLanguage =
      this.translateService.currentLang === undefined
        ? 'es'
        : this.translateService.currentLang;
    this.isMobileResolucion =
      this.applicationStateService.getIsMobileResolution();
  }

  onChangeCheck() {
    const acceptTerms =
      this.contactUsForm.get('acceptTerms')?.value === true ? false : true;
    this.contactUsForm.get('acceptTerms')?.setValue(acceptTerms);
  }

  onSendForm() {
    this.contactUsForm.markAllAsTouched();

    if (this.contactUsForm.invalid) {
      this.errorForm = true;
      return;
    }
    this.errorForm = false;
    // this.openDialog();

    this.sending = true;
    send('service_zh5k08e', 'template_4o0pqif', {
      ...this.contactUsForm.value,      
      form_name: 'Analysis Request',
    }).then(
      () => {
        this.sending = false;
        this.openDialog();
        console.log('Contact data has been submitted');
      },
      (error) => {
        this.sending = false;
        alert(
          this.languageService.currentLang === 'es'
            ? 'Ha ocurrido de un error y no se han enviado los datos. Inténtalo de nuevo o envía un correo a info@honey-ai.com'
            : 'An error has occurred and the data has not been sent. Please try again or send an email to info@honey-ai.com'
        );
        console.log('Error submitting:', (error as EmailJSResponseStatus).text);
      }
    );
  }

  openDialog() {
    const data = {
      data: {
        title:
          this.currentLanguage === 'en'
            ? 'Thank you for completing our analysis form'
            : 'Gracias por completar nuestro formulario de análisis',
        body:
          this.currentLanguage === 'en'
            ? 'With the information provided, we will send you an offer very soon!'
            : 'Con la información proporcionada, ¡le enviaremos una oferta muy pronto!',
        textButton:
          this.currentLanguage === 'en' ? 'Back to home' : 'Volver a inicio',
      },
    };
    this.dialog.open(SubmissionPopupComponent, data);
  }

  pressButtonShowForm() {
    this.hideForm = false;
    setTimeout(() => {
      this.panel.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'start',
      });
    });
  }

  @HostListener('click', ['$event'])
  processLinks(e) {
    const element: HTMLElement = e.target;
    if (element.nodeName === 'A') {
      if (
        element.textContent === 'Política de Privacidad' ||
        element.textContent === 'Privacy Policy'
      ) {
        e.preventDefault();
        this.dialog.open(TermsConditionsComponent);
      } else if (element.textContent === 'FAQs') {
        e.preventDefault();
        this.router.navigate(['/FAQs']);
      }
    }
  }

  getPointStyle(data: MicroHoverPointData) {
    return {
      position: 'absolute',
      top: `${data.top}%`,
      left: `${this.currentLanguage === 'en' ? data.left : data.left}%`,
    };
  }

  getPdfPath(): string {
    return `../../../../assets/pdf/MultiEspectrum-${this.currentLanguage}.pdf`;
  }

  getMultiespectrumPdfPath(page: number): string {
    return `../../../../assets/pollen-analysis/MultiEspectrum-${this.currentLanguage}-pdf-page-00${page}.png`;
  }
}
