import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeTabComponent } from './components/tabs/home-tab/home-tab.component';
import { ContactUsComponent } from './components/tabs/contact-us/contact-us.component';
import { TabsbarComponent } from './components/tabs/tabsbar/tabsbar.component';
import { PageNotFoundComponent } from './components/commons/page-not-found/page-not-found.component';
import { AboutUsTabComponent } from './components/tabs/about-us-tab/about-us-tab.component';
import { PricingTabComponent } from './components/tabs/pricing-tab/pricing-tab.component';
import { BlogTabComponent } from './components/tabs/blog-tab/blog-tab.component';
import { OrdersTabComponent } from './components/tabs/orders-tab/orders-tab.component';
import { FAQsComponent } from './components/faqs/faqs.component';
import { FAQsContentComponent } from './components/faqs/faqs-content/faqs-content.component';
import { NewsTabComponent } from './components/tabs/news-tab/news-tab.component';
import { TechPollenAnalysisComponent } from './components/tabs/tech-pollen-analysis/tech-pollen-analysis.component';
import { TechHoneyaiWholeComponent } from './components/tabs/tech-honeyai-whole/tech-honeyai-whole.component';
import { TechCrystallizationDegreeComponent } from './components/tabs/tech-crystallization-degree/tech-crystallization-degree.component';
import { TechPfundColorGradingComponent } from './components/tabs/tech-pfund-color-grading/tech-pfund-color-grading.component';
import { TechYeastCountingComponent } from './components/tabs/tech-yeast-counting/tech-yeast-counting.component';
import { TechConductivityComponent } from './components/tabs/tech-conductivity/tech-conductivity.component';
import { TechHoneydewAnalysisComponent } from './components/tabs/tech-honeydew-analysis/tech-honeydew-analysis.component';
import { TechYourCustomApplicationComponent } from './components/tabs/tech-your-custom-application/tech-your-custom-application.component';
import { SingupComponent } from './components/tabs/singup/singup.component';
import { TechBeeHealthComponent } from './tabs/tech-bee-health/tech-bee-health.component';
import { AppComponent } from './app.component';

const routes: Routes = [
  {
    path: ':lang',
    component: AppComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home',
        component: TabsbarComponent,
        children: [
          {
            path: '',
            component: HomeTabComponent,
          },
        ],
      },
      {
        path: 'technology',
        component: TabsbarComponent,
        children: [
          { path: 'honey-ai-whole', component: TechHoneyaiWholeComponent },
          { path: 'pollen-analysis', component: TechPollenAnalysisComponent },
          {
            path: 'crystallization-degree',
            component: TechCrystallizationDegreeComponent,
          },
          {
            path: 'pfund-color-grading',
            component: TechPfundColorGradingComponent,
          },
          { path: 'yeast-counting', component: TechYeastCountingComponent },
          { path: 'conductivity', component: TechConductivityComponent },
          {
            path: 'honeydew-analysis',
            component: TechHoneydewAnalysisComponent,
          },
          { path: 'bee-health', component: TechBeeHealthComponent },
          {
            path: 'your-custom-application',
            component: TechYourCustomApplicationComponent,
          },
        ],
      },
      {
        path: 'our-technology',
        component: TechHoneyaiWholeComponent,
        children: [
          {
            path: '',
            component: TechPollenAnalysisComponent,
          },
        ],
      },
      {
        path: 'pricing',
        component: TabsbarComponent,
        children: [
          {
            path: '',
            component: PricingTabComponent,
          },
        ],
      },
      {
        path: 'pre-orders',
        component: TabsbarComponent,
        children: [
          {
            path: '',
            component: OrdersTabComponent,
          },
        ],
      },
      {
        path: 'sing-up',
        component: TabsbarComponent,
        children: [
          {
            path: 'company/:companyId',
            component: SingupComponent,
          },
        ],
      },
      {
        path: 'about-us',
        component: TabsbarComponent,
        children: [
          {
            path: '',
            component: AboutUsTabComponent,
          },
        ],
      },
      {
        path: 'blog',
        component: TabsbarComponent,
        children: [
          {
            path: '',
            component: BlogTabComponent,
          },
        ],
      },
      {
        path: 'news-events',
        component: TabsbarComponent,
        children: [
          {
            path: '',
            component: NewsTabComponent,
          },
        ],
      },
      { path: 'contact', component: ContactUsComponent },
      { path: 'FAQs', component: FAQsComponent },
      { path: 'FAQs/content', component: FAQsContentComponent },
      { path: '**', component: PageNotFoundComponent }, // Wildcard route for a 404 page
    ],
  },
  { path: '', redirectTo: '/es/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
