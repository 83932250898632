import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-faqs-content-template',
  templateUrl: './faqs-content-template.component.html',
  styleUrls: ['./faqs-content-template.component.css'],
})
export class FAQsContentTemplateComponent implements OnInit {

  @Input() faqId: string;

  constructor() { }

  ngOnInit(): void {
  }

}
