import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  currentLang: string = 'en';

  // Observable string sources
  private componentMethodCallSource = new Subject<any>();

  // Observable string streams
  componentMethodCalled$ = this.componentMethodCallSource.asObservable();

  // Service message commands
  switchLang(lang: string) {
    this.currentLang = lang;
    this.componentMethodCallSource.next();
  }

  getPath(path: string) {
    return `/${this.currentLang}/${
      path.startsWith('/') ? path.substring(1) : path
    }`;
  }
}
