import {
  Component,
  HostListener,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  FLIP_CARD_PARAGRAPH_TYPE,
  FlipCardData,
} from 'src/app/components/commons/flip-card/flip-card.component';
import { ApplicationStateService } from 'src/app/service/application-state.service';
import { LanguageService } from 'src/app/service/LanguageService.service';

@Component({
  selector: 'app-tech-bee-health',
  templateUrl: './tech-bee-health.component.html',
  styleUrls: ['./tech-bee-health.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TechBeeHealthComponent implements OnInit {
  public isMobileResolution: boolean;
  public actualCarouselPhoto: number = 1;

  vFlipCardData: FlipCardData[] = [
    {
      iconName: 'increaase-bees-care.png',
      cardName: 'nosem-ai-flip-card-title-1',
      vString: [
        {
          text: 'nosem-ai-flip-card-1-text-1',
          type: FLIP_CARD_PARAGRAPH_TYPE,
        },
        {
          text: 'nosem-ai-flip-card-1-text-2',
          type: FLIP_CARD_PARAGRAPH_TYPE,
        },
        {
          text: 'nosem-ai-flip-card-1-text-3',
          type: FLIP_CARD_PARAGRAPH_TYPE,
        },
      ],
    },
    {
      iconName: 'evolution-of-disease.png',
      cardName: 'nosem-ai-flip-card-title-2',
      vString: [
        {
          text: 'nosem-ai-flip-card-2-text-1',
          type: FLIP_CARD_PARAGRAPH_TYPE,
        },
        {
          text: 'nosem-ai-flip-card-2-text-2',
          type: FLIP_CARD_PARAGRAPH_TYPE,
        },
      ],
    },
    {
      iconName: 'ensure-honeys-production.png',
      cardName: 'nosem-ai-flip-card-title-3',
      vString: [
        {
          text: 'nosem-ai-flip-card-3-text-1',
          type: FLIP_CARD_PARAGRAPH_TYPE,
        },
        {
          text: 'nosem-ai-flip-card-3-text-2',
          type: FLIP_CARD_PARAGRAPH_TYPE,
        },
      ],
    },
  ];
  hexNumbers: number[] = [1, 2, 3, 4, 5];

  constructor(
    private applicationStateService: ApplicationStateService,
    public languageService: LanguageService
  ) {
    this.isMobileResolution = applicationStateService.getIsMobileResolution();
    setInterval(() => {
      this.actualCarouselPhoto =
        this.actualCarouselPhoto === 6 ? 1 : this.actualCarouselPhoto + 1;
    }, 3000);
  }

  ngOnInit(): void {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobileResolution = event.target.innerWidth <= 736;
  }

  getHeaderImgPath(): string {
    return `../../../assets/nosem-ai/nosem-ai-header-${
      this.isMobileResolution ? 'mobile' : 'desktop'
    }.png`;
  }

  getCarouselImgPath(pos: number): string {
    return `../../../assets/nosem-ai/carousel/nosem-ai-carousel-${pos}.png`;
  }

  getHexData(pos: number) {
    return {
      text: `nosem-hex-text-${pos}`,
      subtitle: `nosem-hex-subtitle-${pos}`,
      path: `../../../assets/nosem-ai/hex-icon-${pos}.svg`,
    };
  }
}
