
<div class="upper-footer" role="banner">

  <div class="upper-footer_top">

    <div class="upper-footer_top_logo">
      <img
        width="166px"
        alt="Honey.AI"
        src="assets/home/logo-honey-ai.svg">
      <div class="upper-footer-social-div">
        <div class="upper-footer-social">
          <a href="https://www.linkedin.com/showcase/honey-ai/?viewAsMember=true" target="_blank">
            <img
              width="32px"
              alt="Linkedin"
              src="../assets/home/footer/linkedin.svg">
          </a>
        </div>
        <div class="upper-footer-social spacer-ig">
          <a href="https://www.instagram.com/honey.ai.miel/" target="_blank">
            <img
              width="32px"
              alt="instagram"
              src="../assets/home/footer/ig.png">
          </a>
        </div>
      </div>
    </div >
    <div class="upper-footer_top_links" style="min-width: 130px;">
      <a [routerLink]="languageService.getPath('/home')" style="text-decoration: none;"><p style="color: black"><b> {{'Honey.AI' | translate}} </b></p></a>
      <!-- <p style="color: black"> {{'our-technology' | translate}} </p> -->
      <a [routerLink]="languageService.getPath('/our-technology')" style="text-decoration: none;"><p style="color: black"> {{'our-technology' | translate}} </p></a>
      <a [routerLink]="languageService.getPath('/pricing')" style="text-decoration: none;"><p style="color: black"> {{'Pricing' | translate}} </p></a>
      <a [routerLink]="languageService.getPath('/pre-orders')" style="text-decoration: none;"><p style="color: black"> {{'pre-orders' | translate}} </p></a>
      <a [routerLink]="languageService.getPath('/about-us')" style="text-decoration: none;"><p style="color: black"> {{'About-us' | translate}} </p></a>
      <a [routerLink]="languageService.getPath('/news-events')" style="text-decoration: none;"><p style="color: black"> {{'news-events' | translate}} </p></a>
    </div>
    <div class="upper-footer_top_links">
      <p><b> {{'Help' | translate}} </b></p>
      <a [routerLink]="languageService.getPath('/contact')" style="text-decoration: none;"><p style="color: black"> {{'Contact' | translate}} </p></a>
      <a [routerLink]="languageService.getPath('/FAQs')" style="text-decoration: none;"> <p style="color: black"> {{'FAQs' | translate}} </p> </a>
    </div>

  </div>

  <div class="separator"></div>

  <div class="upper-footer_bottom">
    <div class="upper-footer_bottom_col">
      <div class="footer-support-accio">
        <p>Amb el suport d’ACCIÓ</p>
        <img
              alt="Eit Food"
              src="../assets/home/footer/accio-logo.png">
      </div>

      <div class="footer-support-eu text-spacer">
        <p>{{'financing-eu' | translate}}</p>
        <img alt="Eit Food" src="../assets/home/footer/financing-eu.png">
      </div>
    </div>

    <div class="upper-footer_bottom_col">
      <div class="footer-innovation">
        <p>{{'footer-innovation' | translate}}</p>
        <img
              width="250"
              alt="CDTI"
              src="../assets/home/footer/cdti.jpg">
      </div>

      <div class="upper-footer-subdiv-agrobo footer-text-agrobo">
        <p> {{'funding-explanation' | translate}} </p>
        <div class="upper-footer-agrobo-div">
          <div class="upper-footer-agrobo-div__logos" style="padding-top:20px;padding-left:10px;">
            <img
              width="150px"
              alt="Eit Food"
              src="../assets/home/footer/eitlogo.png">
              <img
              width="100px"
              alt="European Comission"
              src="../assets/home/footer/eu.svg">
          </div>
        </div>
      </div>
    </div>

  </div>

</div>


<div class="footer" role="banner">
  <div>
    <span class="footer-text"> {{ 'honey-rights' | translate }}</span>
  </div>
  <div>
    <button class="no-style-button" (click)="showPrivacyPolicy()">
      <span class="footer-text ">{{'privacy-policy' | translate }} </span>
    </button>
  </div>
</div>
