import { AfterViewInit, Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SubmissionPopupComponent } from '../../../commons/submission-popup/submission-popup.component';
import { FromDataService } from '../../../../service/FromDataService.service';
import { HttpClient } from '@angular/common/http';
import { ApplicationStateService } from '../../../../service/application-state.service';
import { TermsConditionsComponent } from '../../../commons/footer/terms-conditions/terms-conditions.component';

import { EmailJSResponseStatus, send } from 'emailjs-com';
import { LanguageService } from 'src/app/service/LanguageService.service';

@Component({
  selector: 'app-pre-orders-form',
  templateUrl: './pre-orders-form.component.html',
  styleUrls: ['./pre-orders-form.component.css'],
})
export class PreOrdersFormComponent implements AfterViewInit {
  public preOrdersForm: FormGroup;
  formService: FromDataService | any;
  selected: null;
  public isMobileResolution: boolean;

  sending: boolean = false;

  constructor(
    private _httpClient: HttpClient,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private applicationStateService: ApplicationStateService,
    private languageService: LanguageService
  ) {
    this.isMobileResolution = applicationStateService.getIsMobileResolution();
    this.preOrdersForm = this.formBuilder.group({
      name: '',
      surname: '',
      email: '',
      phone: '',
      company: '',
      country: '',
      deliveryAddr: '',
      rangeYear: '',
    });
  }

  disableSendButton() {
    return this.sending;
  }

  ngAfterViewInit(): void {
    this.formService = new FromDataService(this._httpClient);
  }

  onSubmit(preOrdersData: any) {
    this.sending = true;
    send('service_zh5k08e', 'template_4o0pqif', {
      ...preOrdersData,
      rangeYear: this.selected,
      form_name: 'PreOrder Form',
    }).then(
      () => {
        this.sending = false;
        this.openDialog();
        console.log('Contact data has been submitted');
      },
      (error) => {
        this.sending = false;
        alert(
          this.languageService.currentLang === 'es'
            ? 'Ha ocurrido de un error y no se han enviado los datos. Inténtalo de nuevo o envía un correo a info@honey-ai.com'
            : 'An error has occurred and the data has not been sent. Please try again or send an email to info@honey-ai.com'
        );
        console.log('Error submitting:', (error as EmailJSResponseStatus).text);
      }
    );
  }

  openDialog() {
    const data = {
      data:
        this.languageService.currentLang === 'es'
          ? {
              title: 'Gracias por completar nuestro formulario de PreOrden',
              body: '¡Te enviaremos una oferta muy pronto con la información facilitada!',
              textButton: 'Volver a inicio',
            }
          : {
              title: 'Thank you for completing our pre-order form',
              body: 'With the information provided, we will send you an offer very soon!',
              textButton: 'Back to home',
            },
    };
    this.dialog.open(SubmissionPopupComponent, data);
  }

  showPrivacyPolicy() {
    this.dialog.open(TermsConditionsComponent);
  }
}
