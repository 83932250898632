<mat-dialog-content>
  <div  [class]="!isMobileResolution? 'flex': 'mobile-popup'">
    <div>
      <img style="width: 170px" src="assets/about-us/team-members/{{data.id}}.png">
    </div>
    <div [class]="!isMobileResolution? 'padding-content': ''">
      <div style="display: inline-flex">
        <p class="body-text-bold" style="padding-right: 10px; margin-bottom: 0px; margin-top: 5px;">{{data.name}}</p>
        <a style="place-self: center;" href="{{data.linkedin}}" target="_blank"><img src="../../../../../assets/about-us/linkedin-color.svg"></a>
      </div>
      <p class="body-text" style="margin-top: 0px;">{{data.title | translate}}</p>
      <p class="body-text">{{data.description | translate}}</p>
    </div>
  </div>
</mat-dialog-content>
