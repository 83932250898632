import { Component, OnInit } from '@angular/core';
import { FLIP_CARD_LIST_TYPE, FLIP_CARD_PARAGRAPH_TYPE, FlipCardData } from '../../commons/flip-card/flip-card.component';

@Component({
  selector: 'app-tech-honeydew-analysis',
  templateUrl: './tech-honeydew-analysis.component.html',
  styleUrls: ['./tech-honeydew-analysis.component.css']
})
export class TechHoneydewAnalysisComponent implements OnInit {

  // Flip Cards
  vFlipCardData: FlipCardData[] = [
    {
      iconName: 'circle-icon-correcta-clasificacion.png',
      cardName: 'honeydew-analysis-flip-card-1-title',
      vString: [
        { text: 'honeydew-analysis-flip-card-1-text-1', type: FLIP_CARD_PARAGRAPH_TYPE }
      ]
    },
    {
      iconName: 'circle-icon-satisfaccion-consumidor.png',
      cardName: 'honeydew-analysis-flip-card-2-title',
      vString: [
        { text: 'honeydew-analysis-flip-card-2-text-1', type: FLIP_CARD_PARAGRAPH_TYPE },
      ]
    },
    {
      iconName: 'circle-icon-identificacion-otras-particulas.png',
      cardName: 'honeydew-analysis-flip-card-3-title',
      vString: [
        { text: 'honeydew-analysis-flip-card-3-text-1', type: FLIP_CARD_PARAGRAPH_TYPE },
        { text: 'honeydew-analysis-flip-card-3-text-2', type: FLIP_CARD_LIST_TYPE },
        { text: 'honeydew-analysis-flip-card-3-text-3', type: FLIP_CARD_LIST_TYPE },
        { text: 'honeydew-analysis-flip-card-3-text-4', type: FLIP_CARD_LIST_TYPE },
      ]
    }
  ]

  constructor() { }

  ngOnInit(): void {
    // Scroll to top
    window.scroll({ top: 0, left: 0 });
  }

}
