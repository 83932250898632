import { Component, OnInit } from '@angular/core';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-carousel-our-technology',
  templateUrl: './carousel-our-technology.component.html',
  styleUrls: ['./carousel-our-technology.component.css']
})
export class CarouselOurTechnologyComponent implements OnInit {

  constructor() { }

  subscription: Subscription;

  ngOnInit(): void {
    const timeInterval = interval(3000);
    this.subscription = timeInterval.subscribe(() => {
      this.onNextClick();
    })
  }


  vNumImages: number[] = [ 1, 2, 3, 4, 5 ];
  pathAssets = '../../../../../assets/tech-honeyai-whole/carousel/';
  numImages = 5;
  currentSlide = 1;

  onPreviousClick () {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 1 ? this.numImages : previous;
    this.subscription.unsubscribe();
  }

  onNextClick () {
    const next = this.currentSlide + 1;
    this.currentSlide = next > this.numImages ? 1 : next;
  }

  getImgPath (i: number): string {
    return `${this.pathAssets}/how-use-honey-ai-${i}.png`;
  }
}
