<!-- header -->
<div class="header" *ngIf="!isMobileResolution; else mobileHeader">
  <div class="margin-left">
    <button class="lang-button" disabled>
      <img src="../assets/home/language-button-icon.svg">
      <span class="button-text-lang">
        <div class="desktop">
          <mat-select panelClass="example-panel-yellow"
                      [(value)]="defaultLang" #selectedLang (selectionChange)="switchLang(selectedLang.value)" style="width:60px">
              <mat-option *ngFor="let language of translate.getLangs()" [value]="language">
                {{ language | translate }}
                <img style="padding-left: 25px;"
                     src="assets/home/header/{{language}}.svg">
              </mat-option>
            </mat-select>
        </div>

        </span>
    </button>
  </div>

  <div class="push-div margin-right">
    <a href="{{privateUrl}}">
      <button id="account-button">
        <span class="button-text-account"> {{'my-account' | translate }} </span>
      </button>
    </a>
    <a [routerLink]="langService.getPath('/contact')">
      <button mat-button id="contact-button" >
        <span class="button-text-contact"> {{'contact' | translate }} </span>
      </button>
    </a>
  </div>
</div>

<ng-template #mobileHeader>
  <div class="mobile-header">
    <div style="width: 100%;">
      <a [routerLink]="langService.getPath('/home')">
        <img
          height="40px"
          alt="Honey.AI Logo"
          src="../../../../assets/home/logo-honey-ai.svg">
      </a>
    </div>
    <div class="lang-button-div-mobile lang-button mobile">
      <mat-select #selectedLang (selectionChange)="switchLang(selectedLang.value)">
        <mat-option *ngFor="let language of translate.getLangs()" [value]="language">
          {{ language | translate }}
          <img src="assets/home/header/{{language}}.svg">
        </mat-option>
      </mat-select>
    </div>

    <div class="account-button-div-mobile">
      <a href="{{privateUrl}}">
        <button class="button-header-mobile">
          <img src="assets/home/header/account-icon.svg">
        </button>
      </a>
    </div>
  </div>
</ng-template>
