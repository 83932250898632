<div class="container-nosem-ai">
  <!-- Header container -->
  <div class="header-container">
    <div class="icon-container">
      <img
        src="../../../assets/tech-bee-health/Nosem-ai-logo.png"
        alt="Nosem AI Logo"
      />
    </div>
    <div class="title-header-nosem-ai">
      {{ "nosem-ai-header-title" | translate }}
    </div>
    <div
      class="text-header-nosem-ai"
      [innerHTML]="'nosem-ai-header-text' | translate"
    ></div>
    <div class="image-container">
      <img [src]="getHeaderImgPath()" alt="Header NosemAI" />
    </div>
  </div>

  <!-- Diagnosis and treatment container -->
  <div class="diagnosis-treatment-container">
    <div class="left-side">
      <div class="text-container">
        <div class="subtitle">
          {{ "nosem-ai-subtitle-1" | translate }}
        </div>
        <div class="text" [innerHTML]="'nosem-ai-text-1' | translate"></div>
      </div>
    </div>

    <div class="carousel-container">
      <img [src]="getCarouselImgPath(actualCarouselPhoto)" alt="Carousel Img" />
    </div>
  </div>

  <!-- Flip card sections -->
  <div class="flip-card-section">
    <div class="subtitle" [innerHTML]="'nosem-ai-subtitle-2' | translate"></div>

    <div class="flip-cards-container">
      <app-flip-card
        *ngFor="let cardData of vFlipCardData"
        [itemFlipCard]="cardData"
      ></app-flip-card>
    </div>
  </div>

  <!-- Slider container -->
  <div class="how-can-help-subtitle">
    {{ "nosem-ai-how-can-help" | translate }}
  </div>

  <div class="slider-container">
    <div class="left-side">
      <img-comparison-slider class="img-comparator">
        <img
          class="img-slider-item"
          slot="first"
          src="../../../assets/nosem-ai/nosem-ai-slider-img-before.png"
        />
        <img
          class="img-slider-item"
          slot="second"
          src="../../../assets/nosem-ai/nosem-ai-slider-img-after.png"
        />
        <img
          slot="handle"
          width="40"
          src="../../../assets/nosem-ai/white-slider-arrow.svg"
        />
      </img-comparison-slider>
      <div class="arrow-text-container">
        <div class="arrow-container">
          <img src="../../../assets/nosem-ai/nosema-arrow.svg" />
        </div>
        <div class="text-container">
          {{ "nosem-ai-arrow-text" | translate }}
        </div>
      </div>
    </div>

    <div class="right-side">
      <div class="text" [innerHTML]="'nosem-ai-text-2' | translate"></div>
    </div>
  </div>

  <!-- Download Example -->
  <div class="download-example-nosem-ai">
    <div class="text-download-example-content">
      {{ "nosem-ai-download-text" | translate }}
    </div>

    <div class="button-download-content">
      <a
        class="button-download-pdf"
        href="../../../assets/pdf/pdf_ejemplo_nosema.pdf"
        download
        target="_blank"
      >
        {{ "download-pdf-here" | translate }}
      </a>
    </div>
  </div>

  <!-- Hex container -->
  <div class="hex-container">
    <div class="hex-icons-row">
      <div class="item-hexagon" *ngFor="let i of hexNumbers">
        <div class="top">
          <div class="icon">
            <img [src]="getHexData(i).path" />
          </div>
          <div class="title">
            {{ getHexData(i).subtitle | translate }}
          </div>
        </div>
        <div class="text">
          {{ getHexData(i).text | translate }}
        </div>
      </div>
    </div>
    <div class="nosem-ai-icon">
      <img
        src="../../../assets/nosem-ai/nosem-ai-hex-logo.png"
        alt="Nosem AI Icon"
      />
    </div>
  </div>

  <!-- Reasons to trus NosemAI -->
  <div class="reasons-to-trust">
    <div class="subtitle">
      {{ "nosem-ai-subtitle-3" | translate }}
    </div>

    <div class="reasons-container">
      <!-- Column -->
      <div class="column">
        <div class="text">
          <div
            class="header-column"
            [innerHTML]="'nosem-ai-text-3' | translate"
          ></div>
          <div
            class="content-text"
            [innerHTML]="'nosem-ai-text-4' | translate"
          ></div>
        </div>
        <div class="icons">
          <a href="https://www.iti.es/" target="_blank">
            <img src="../../../assets/nosem-ai/iti-nosema.png" />
          </a>
        </div>
      </div>

      <!-- Column -->
      <div class="column">
        <div class="text">
          <div
            class="header-column"
            [innerHTML]="'nosem-ai-text-5' | translate"
          ></div>
          <div
            class="content-text"
            [innerHTML]="'nosem-ai-text-6' | translate"
          ></div>
        </div>
        <div class="icons">
          <a href="https://www.accio.gencat.cat/ca/inici" target="_blank">
            <img src="../../../assets/nosem-ai/accio-nosema.png" />
          </a>
          <a href="https://www.synergisteic.eu/" target="_blank">
            <img src="../../../assets/nosem-ai/synergistEIC.png" />
          </a>
          <a href="https://eismea.ec.europa.eu/about-eismea_en" target="_blank">
            <img src="../../../assets/nosem-ai/financiado-eu-nosema.png" />
          </a>
        </div>
      </div>

      <!-- Column -->
      <div class="column">
        <div class="text">
          <div
            class="header-column"
            [innerHTML]="'nosem-ai-text-7' | translate"
          ></div>
          <div
            class="content-text"
            [innerHTML]="'nosem-ai-text-8' | translate"
          ></div>
        </div>
        <div class="icons">
          <a href="https://www.honey-ai.com/" target="_blank">
            <img src="../../../assets/home/logo-honey-ai.svg" />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
