<div class="pfund-container">

    <app-technology-header-container 
        [title]="pFundTitle" 
        [subtitle]="pFundSubTitle" 
        [vText]="pFundVText"
        [vCarousel]="pFundVCarousel">
    </app-technology-header-container>

    <div class="pfund-reasons">

        <h3 class="subtitle">
            {{ 'pfund-flip-cards-title' | translate }}
        </h3>

        <div class="benefits-cards">

            <!-- Cards -->
            <app-flip-card *ngFor="let cardData of vFlipCardData" [itemFlipCard]="cardData"></app-flip-card>

        </div>
    </div>

    <div class="pfund-how-honey-ai-helps">
        <div class="subtitle">
            {{ 'pfund-how-helps-title' | translate }}
        </div>

        <div class="text" [innerHTML]="'pfund-how-helps-text-1' | translate"></div>

        <div class="text" [innerHTML]="'pfund-how-helps-text-2' | translate"></div>

        <div class="hex-colors-container"></div>

        <div class="text-arrow-content">
            <div class="arrow">
                <img src="../../../../assets/commons/curved-arrow.png" />
            </div>
            <div class="text">
                {{ 'pfund-how-helps-text-3' | translate }}
            </div>
        </div>
    </div>


    <div class="pfund-download-example">

        <div class="text-download-example-content">
            {{ 'pfund-want-to-see-example' | translate }}
        </div>

        <div class="button-download-content">
            <a class="button-download-pdf"
                href="../../../../assets/pdf/Boletin_Color.pdf"
                download target="_blank">
                {{ 'download-pdf-here' | translate }}
            </a>
        </div>

    </div>

    <div class="pfund-generalitat-content">

        <div class="right-side-mobile">
            <img src="../../../../assets/pfund-color-grading/patent-pending.png" />
        </div>

        <div class="left-side">
            <div class="left-side-container">
                <div class="text" [innerHTML]="'pfund-final-text' | translate"></div>
    
                <div class="icons">
                    <img src="../../../../assets/pfund-color-grading/generalitat-catalunya-logo.png" />
                    <img src="../../../../assets/pfund-color-grading/cvc-logo.png" />
                </div>
            </div>
        </div>

        <div class="right-side">
            <img src="../../../../assets/pfund-color-grading/patent-pending.png" />
        </div>
    </div>
</div>