import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';

import { FLIP_CARD_PARAGRAPH_TYPE, FlipCardData } from '../../commons/flip-card/flip-card.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tech-crystallization-degree',
  templateUrl: './tech-crystallization-degree.component.html',
  styleUrls: ['./tech-crystallization-degree.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TechCrystallizationDegreeComponent implements OnInit {

  // Flip Cards
  vFlipCardData: FlipCardData[] = [
    {
      iconName: 'circle-icon-estudiar-efectividad.png',
      cardName: 'crystallization-degree-flip-card-1-title',
      vString: [
        { text: 'crystallization-degree-flip-card-1-text-1', type: FLIP_CARD_PARAGRAPH_TYPE },
        { text: 'crystallization-degree-flip-card-1-text-2', type: FLIP_CARD_PARAGRAPH_TYPE },
      ]
    },
    {
      iconName: 'circle-icon-evitar-problemas-lineales.png',
      cardName: 'crystallization-degree-flip-card-2-title',
      vString: [
        { text: 'crystallization-degree-flip-card-2-text-1', type: FLIP_CARD_PARAGRAPH_TYPE },
        { text: 'crystallization-degree-flip-card-2-text-2', type: FLIP_CARD_PARAGRAPH_TYPE },
      ]
    },
    {
      iconName: 'circle-icon-estudiar-comparar-mieles.png',
      cardName: 'crystallization-degree-flip-card-3-title',
      vString: [
        { text: 'crystallization-degree-flip-card-3-text-1', type: FLIP_CARD_PARAGRAPH_TYPE }
      ]
    }
  ]

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    // Scroll to top
    window.scroll({ top: 0, left: 0 });
  }

  @HostListener('click', ['$event'])
  processLinks(e) {
    const element: HTMLElement = e.target;
    if (element.nodeName === 'A') {
      if (element.textContent === 'Pack de Inicio' || element.textContent === 'Starter Pack') {
        e.preventDefault();
        localStorage.setItem('goToStarterPack', 'true');
        this.router.navigate(['/pricing'], { fragment: 'starter-pack-panel'});
      }
    }
  }
}
