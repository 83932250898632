<div [class]="!isMobileResolution? 'page': 'page-mobile'">

  <!-- Header -->
  <!-- <app-header></app-header> -->

  <!-- Content -->
  <div class="content">
    <router-outlet (activate)="onActivate($event, outlet)" #outlet></router-outlet>
  </div>

  <!-- Footer -->
  <!-- <app-footer style="box-shadow: 0px 0px 10px rgb(200 200 200 / 55%);"></app-footer> -->

</div>
