import { Component, OnInit } from '@angular/core';
import { ApplicationStateService } from '../../../service/application-state.service';
import { LanguageService } from 'src/app/service/LanguageService.service';

@Component({
  selector: 'app-home-tab',
  templateUrl: './home-tab.component.html',
  styleUrls: ['./home-tab.component.css'],
})
export class HomeTabComponent implements OnInit {
  public isMobileResolution: boolean;

  constructor(
    private applicationStateService: ApplicationStateService,
    public languageService: LanguageService
  ) {
    this.isMobileResolution = applicationStateService.getIsMobileResolution();
  }

  ngOnInit(): void {
    window.scroll({
      top: 0,
      left: 0,
    });
  }
}
