import { NewsletterService } from './../../../../service/newsletter.service';
import { Component, OnInit } from '@angular/core';
import { ApplicationStateService } from '../../../../service/application-state.service';
import { FormBuilder } from '@angular/forms';
import { LanguageService } from 'src/app/service/LanguageService.service';

@Component({
  selector: 'app-contact-us-cards',
  templateUrl: './contact-us-cards.component.html',
  styleUrls: ['./contact-us-cards.component.css'],
})
export class ContactUsCardsComponent {
  public isMobileResolution: boolean;
  public newsletterForm = this.formBuilder.group({
    email: '',
  });
  public thanksSubmitNewsletterVisibility: string = 'hidden';

  constructor(
    private applicationStateService: ApplicationStateService,
    private formBuilder: FormBuilder,
    private newsletterService: NewsletterService,
    public languageService: LanguageService
  ) {
    this.isMobileResolution = applicationStateService.getIsMobileResolution();
  }

  onSubmitNewsletter(): void {
    const response = this.newsletterService.addContact(
      this.newsletterForm.value.email
    );
    response.subscribe(
      (data) => console.log('Correcto'),
      (error) => console.log('Error')
    );

    this.thanksSubmitNewsletterVisibility = 'visible';
    this.newsletterForm.reset();
  }
}
