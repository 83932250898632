import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-hexagon-message-item',
  templateUrl: './hexagon-message-item.component.html',
  styleUrls: ['./hexagon-message-item.component.css']
})
export class HexagonMessageItemComponent implements OnInit {

  @Input() messageHex!: string;

  constructor() { }

  ngOnInit(): void {
  }

}
