import {Component, Input, OnInit} from '@angular/core';
import {FAQsCardService} from "../service/FAQsCard.service";
import {ApplicationStateService} from "../../../service/application-state.service";

@Component({
  selector: 'app-faqs-card',
  templateUrl: './faqs-card.component.html',
  styleUrls: ['./faqs-card.component.css']
})
export class FAQsCardComponent implements OnInit {

  @Input() cardText: string;
  @Input() imageUrl: string;
  @Input() imageHeight: string;
  @Input() fontSize: string;
  @Input() compId: any = 1;

  public isMobileResolution: boolean;

  constructor(private applicationStateService: ApplicationStateService) {
    this.isMobileResolution = applicationStateService.getIsMobileResolution();
  }

  ngOnInit(): void { }

}
