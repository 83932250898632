import { Component, OnInit } from '@angular/core';
import {latLng, MapOptions, tileLayer, Map, Marker, icon} from 'leaflet';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {

  public map: Map;
  public mapOptions: MapOptions;

  constructor() { }

  ngOnInit(): void {
    this.initMap();
  }

  onMapReady(map: Map) {
    this.map = map;
    this.addSampleMarker();
  }

  private initMap() {
    this.mapOptions = {
      center: latLng(41.35987, 2.10852 ),
      zoom: 16,
      layers: [
        tileLayer(
          'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          {
            maxZoom: 18,
            attribution: 'Map data © OpenStreetMap contributors'
          })
      ],
    };
  }

  private addSampleMarker() {
    const marker = new Marker([41.35987, 2.10852])
      .setIcon(
        icon({
          iconSize: [35, 51],
          iconAnchor: [13, 41],
          iconUrl: 'assets/contact/marker-icon.png'
        }));
    marker.addTo(this.map);
  }

}
