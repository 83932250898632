import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

export interface TypeTextData {
  text: string;
  type: string;
}

export interface FlipCardData {
  iconName: string;
  cardName: string;
  vString: TypeTextData[];
}

export const FLIP_CARD_PARAGRAPH_TYPE = "PARAGRAPH";
export const FLIP_CARD_LIST_TYPE = "LIST";

@Component({
  selector: 'app-flip-card',
  templateUrl: './flip-card.component.html',
  styleUrls: ['./flip-card.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class FlipCardComponent {

  @Input() itemFlipCard: FlipCardData;

  reversed: boolean = false;
  circleIconPath: string = '../../../../assets/circle-icons/';

  constructor() { }

}
