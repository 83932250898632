import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageService } from 'src/app/service/LanguageService.service';

export interface HoneyDialog {
  title: string;
  body: string;
  textButton: string;
}

@Component({
  selector: 'app-submition-popup',
  templateUrl: './submission-popup.component.html',
  styleUrls: ['./submission-popup.component.css'],
})
export class SubmissionPopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: HoneyDialog,
    public languageService: LanguageService
  ) {}
}
