import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {TranslateService} from "@ngx-translate/core";
import {LanguageService} from "../../../../service/LanguageService.service";
import {ApplicationStateService} from "../../../../service/application-state.service";

@Component({
  selector: 'app-news-component-template',
  templateUrl: './news-component-template.component.html',
  styleUrls: ['./news-component-template.component.css']
})
export class NewsComponentTemplateComponent implements AfterViewInit, OnInit {

  @Input() newClass: string;
  @Input() index: string;
  @Input() href: string;
  @Input() hiddenButton: boolean;
  safeHtml: SafeHtml;

  public isMobileResolution: boolean;

  hiddenContent: boolean;

  constructor(private sanitizer: DomSanitizer,
              private translate: TranslateService,
              public langService: LanguageService,
              private applicationStateService: ApplicationStateService) {
    this.isMobileResolution = applicationStateService.getIsMobileResolution();
  }

  ngOnInit(): void {
    this.langService.componentMethodCalled$.subscribe(() => {
      this.loadNewsBodyData();
    });
    this.hiddenContent = true;
  }

  ngAfterViewInit(): void {
    this.loadNewsBodyData();
  }

  loadNewsBodyData(){
    this.translate.get('new-body-' + this.index).subscribe(
      (foo: string) => {
        this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(foo);
      });
  }

  showContent() {
    this.hiddenContent = false;
  }

  hideContent() {
    this.hiddenContent = true;
  }
}
