import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-starter-pack-item',
  templateUrl: './starter-pack-item.component.html',
  styleUrls: ['./starter-pack-item.component.css']
})
export class StarterPackItemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
