<div class="honeydew-analysis-container">

    <div class="honeydew-title">
        <h1> {{ 'honeydew-analysis-title' | translate }} </h1>
    </div>

    <div class="header-honeydew-container">

        <div class="image-mobile-side"></div>

        <div class="text-side">
            <h3 class="subtitle">
                {{ 'honeydew-analysis-subtitle' | translate }}
            </h3>

            <div class="text">
                {{ 'honeydew-analysis-text-1' | translate }}
            </div>

            <div class="text" [innerHTML]="'honeydew-analysis-text-2' | translate"> </div>

        </div>

        <div class="image-pc-side"></div>

    </div>

    <div class="benefits-honeydew-analysis">

        <h3 class="subtitle">
            {{ 'honeydew-analysis-flip-cards-title' | translate }}
        </h3>

        <div class="benefits-cards">
            
            <!-- Cards -->
            <app-flip-card *ngFor="let cardData of vFlipCardData"
                [itemFlipCard]="cardData"
            ></app-flip-card>

        </div>
    </div>


    <div class="how-it-works-honeydew-analysis">

        <div class="left-side">
            <div class="gif-content">
                <img src="../../../../assets/hex-carousel-images/mielatos-carousel-2.png" />
            </div>
            <div class="arrow-text-content">
                <div class="arrow">
                    <img src="../../../../assets/commons/curved-arrow-2.png" />
                </div>
                <div class="text">
                    {{ 'honeydew-analysis-text-9' | translate }}
                </div>
            </div>
        </div>

        <div class="right-side">
            <div class="subtitle">
                {{ 'honeydew-analysis-text-4' | translate }}
            </div>

            <div class="text">
                {{ 'honeydew-analysis-text-5' | translate }}
            </div>

            <div class="text">
                {{ 'honeydew-analysis-text-6' | translate }}
            </div>

            <div class="text" [innerHTML]="'honeydew-analysis-text-7' | translate"> </div>

            <div class="text">
                {{ 'honeydew-analysis-text-8' | translate }}
            </div>
        </div>

    </div>

    <div class="download-example-honeydew-analysis">

        <div class="text-download-example-content">
            {{ 'honeydew-analysis-want-to-see-example' | translate }}
        </div>

        <div class="button-download-content">
            <a class="button-download-pdf"
                href="../../../../assets/pdf/pollen_analysis_ENG.pdf"
                target="_blank" download>
                {{ 'download-pdf-here' | translate }}
            </a>
        </div>

    </div>

</div>