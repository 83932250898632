import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/service/LanguageService.service';

@Component({
  selector: 'app-tech-your-custom-application',
  templateUrl: './tech-your-custom-application.component.html',
  styleUrls: ['./tech-your-custom-application.component.css']
})
export class TechYourCustomApplicationComponent implements OnInit {

  vApplications: string [] = [
    'your-custom-application-text-5',
    'your-custom-application-text-6',
    'your-custom-application-text-7',
    'your-custom-application-text-8',
    'your-custom-application-text-9',
    'your-custom-application-text-10',
  ]

  imgPath: string = '../../../../assets/your-custom-application/';

  vListItems: any [] = [
    {
      bolderText: 'your-custom-application-text-12',
      normalText: 'your-custom-application-text-13',
      iconNumber: 'icon-number-01.png'
    },
    {
      bolderText: 'your-custom-application-text-14',
      normalText: 'your-custom-application-text-15',
      iconNumber: 'icon-number-02.png'
    },
    {
      bolderText: 'your-custom-application-text-16',
      normalText: 'your-custom-application-text-17',
      iconNumber: 'icon-number-03.png'
    },
  ]

  constructor(public languageService: LanguageService) { }

  ngOnInit(): void {
  }

}
