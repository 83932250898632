import { Component, OnInit } from '@angular/core';
import { ApplicationStateService } from '../../../../service/application-state.service';
import { LanguageService } from 'src/app/service/LanguageService.service';

@Component({
  selector: 'app-faqs-content-orders-pricing',
  templateUrl: './faqs-content-orders-pricing.component.html',
  styleUrls: ['./faqs-content-orders-pricing.component.css'],
})
export class FAQsContentOrdersPricingComponent {
  public isMobileResolution: boolean;

  constructor(
    private applicationStateService: ApplicationStateService,
    public languageService: LanguageService
  ) {
    this.isMobileResolution = applicationStateService.getIsMobileResolution();
  }
}
