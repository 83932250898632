<mat-dialog-content>
  <div style="text-align-last: center;">
    <p style="color: black; font-size: 18px"><b>{{'terms-title' | translate}}</b></p>
    <a style="color:black;" href="https://www.honey-ai.com">www.honey-ai.com</a><br><br><br>
  </div>

  <div style="text-align-last: center;"><b>{{'terms-and-conditions-general-info' | translate}}</b></div>
  <div [innerHtml]="safeHtmlGeneral"></div>
  <div style="text-align-last: center;"><b>{{'terms-and-conditions-statements' | translate}}</b></div>
  <div [innerHtml]="safeHtmlStatements"></div>

  <div [innerHtml]="safeHtmlBody"></div>
</mat-dialog-content>
