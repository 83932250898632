import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hexagons-messages',
  templateUrl: './hexagons-messages.component.html',
  styleUrls: ['./hexagons-messages.component.css']
})
export class HexagonsMessagesComponent implements OnInit {

  vSteps=[1, 2, 3, 4, 5];

  constructor() { }

  ngOnInit(): void {
  }

  getHexagonMessage(i: number) {
    return `honey-ai-whole-hex-msg-${i}`;
  }

}
