import { Component, Input, OnInit } from '@angular/core';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-technology-header-container',
  templateUrl: './technology-header-container.component.html',
  styleUrls: ['./technology-header-container.component.css']
})
export class TechnologyHeaderContainerComponent implements OnInit {

  @Input() title: string;
  @Input() subtitle: string;
  @Input() vText: string[];
  @Input() vCarousel: string[];

  pathImages: string = '../../../../assets/hex-carousel-images/';

  currentImage = 0;
  subscription: Subscription;


  constructor() { }

  ngOnInit(): void {
    const timeInterval = interval(3000);
    this.subscription = timeInterval.subscribe(() => this.onNextImage())
  }

  onPrevImage() {
    const prev = this.currentImage - 1;
    this.currentImage = prev < 0 ? this.vCarousel.length - 1 : prev;
  }

  onNextImage() {
    const next = this.currentImage + 1;
    this.currentImage = next > this.vCarousel.length - 1 ? 0 : next;
  }
}
