<div *ngIf="!isMobileResolution; else mobilePricingContent">
  <div class="centered-honey-text-div flex margin-top">
    <p class="text-title-honey text-align-center">
      {{'pricing-information'| translate}}
    </p>
    <p class="text-content-honey text-align-center margin-top-text">
      {{'pricing-title-body-text'| translate}}
    </p>
  </div>

  <!-- Pricing cards -->
  <div class="divs-horizontally">
    <div class="pricing-steps margin-left ">
      <div class="card-image">
        <img src="../../../../assets/pricing/pricing-card-1.svg">
      </div>
      <p class="text-subtitle-honey text-align-center card-title-text">
        {{'become-client'| translate}}
      </p>
      <p class="body-text text-align-center card-body-text">
        {{'become-client-body-1'| translate}}<button class="no-style-button" style="font-weight: bold; padding: 0px;" (click)="pressInitPack()"> {{'become-client-body-2'| translate}}</button>{{'become-client-body-3'| translate}}
        <a [routerLink]="languageService.getPath('/pre-orders')" style="color: black">{{'(Request a Quote)' | translate}}</a>
      </p>
    </div>
    <div style="padding-top: 40px;">
      <img src="../../../../assets/pricing/pricing-arrow.svg">
    </div>
    <div class="pricing-steps">
      <div class="card-image">
        <img src="../../../../assets/pricing/pricing-card-2.svg">
      </div>
      <p class="text-subtitle-honey text-align-center card-title-text">
        {{'fill-wallet'| translate}}
      </p>
      <p class="body-text text-align-center card-body-text">
        {{'fill-wallet-body'| translate}}
      </p>
      <p class="body-text text-align-center card-body-text-sub">
        {{'fill-wallet-body-sub'| translate}}
      </p>
    </div>
    <div style="padding-top: 40px;">
      <img src="../../../../assets/pricing/pricing-arrow.svg">
    </div>
    <div class="pricing-steps">
      <div class="card-image">
        <img src="../../../../assets/pricing/pricing-card-3.svg">
      </div>
      <p class="text-subtitle-honey text-align-center card-title-text">
        {{'enjoy-honey-ai'| translate}}
      </p>
      <p class="body-text text-align-center card-body-text">
        {{'enjoy-honey-ai-body'| translate}}
      </p>
    </div>
  </div>

  <!-- Info pricing -->
  <div style="position: relative; text-align: center;">
    <img style="width: 100%;"
         src="assets/pricing/pricing-backgroung.jpg"/>
    <div class="text-background-grey">
      <div class="pricing-info-on-image">
        <p style="color: #F3D02F; font-weight: bold;">
          {{'FAQs'| translate}}
        </p>
        <p style="color: white; padding-top: 20px; padding-bottom: 20px; font-size: 20px;">
          {{'info-pricing-1' | translate}}
        </p>
        <div style="background: white; height: 1px;"></div>
        <p style="padding-top: 20px;"><a style="color: white; font-weight: bold; text-underline-position: under;" [routerLink]="languageService.getPath('/FAQs')"> {{'info-pricing-1-link' | translate}}</a></p>
      </div>
      <div class="pricing-info-on-image">
        <p style="color: #F3D02F; font-weight: bold;">
          {{'Orders'| translate}}
        </p>
        <p style="color: white; padding-top: 20px; padding-bottom: 20px; font-size: 20px;">
          {{'info-pricing-2' | translate}}
        </p>
        <div style="background: white; height: 1px;"></div>
        <p style="padding-top: 20px;"><a style="color: white; font-weight: bold; text-underline-position: under;" [routerLink]="languageService.getPath('/pre-orders')"> {{'info-pricing-2-link' | translate}}</a></p>
      </div>
    </div>

  </div>

  <div id="starter-pack-panel" #panel class="divs-horizontally">
    <div style="width: 100%; align-self: center; margin-right: 2%;">
      <img
        style="max-width: 100%;"
        src="assets/pricing/client-app-{{'lang' | translate}}.png">
    </div>
    <div style="width: 100%; margin-left: 2%;">
      <div class="pricing-card">
        <p class="pricing-card-text-1" style="padding-bottom: 30px;">{{'init-pack' | translate}}<span style="color: #E9BD02"> {{'price-init' | translate}}</span></p>
        <p class="pricing-card-text-2" style="font-weight: bold;">{{'includes' | translate}}</p>

        <ul class="pricing-card-text-2">
          <li>{{'Honey.AI device' | translate}}</li>
          <li>{{'Centrifuge' | translate}}</li>
          <li>{{'1x Polarizer' | translate}}</li>
          <li>{{'A calibration glass slide (for the commissioning)' | translate}}</li>
          <li>{{'A verification glass slide with sample(for the commissioning)' | translate}}</li>
          <li>{{'3x custom glass slides for Crystallization rate measurements' | translate}}</li>
          <li>{{'1x custom slides for measuring Pfund color' | translate}}</li>
          <li>{{'1x Glass slides box, 50 units' | translate}}</li>
          <li>{{'1x cover box, 50 units' | translate}}</li>
          <li>{{'1x box of color measuring cuvettes (100 units)' | translate}}</li>
          <li>{{'1x pipette 25 ul' | translate}}</li>
          <li>{{'1x pipette 50 ul' | translate}}</li>
          <li>{{'Chemicals/Reagents for sample preparation' | translate}}</li>
          <li>{{'Each customer will have access to our customer support team (2-hour installation course)' | translate}}</li>
        </ul>
        <div style="height: 2px; margin-top: 30px; margin-bottom: 30px; padding-right: 30px; padding-left: 30px; background: #E9BD02"></div>
      </div>
      <div style="text-align: center; transform: translateY(-30px);">
        <a [routerLink]="languageService.getPath('/pre-orders')">
          <button class="yellow-honey-button">
            {{'pre-orders-button' | translate}}
          </button>
        </a>
      </div>
      <p style="text-align: center;">*{{'Prices don’t include taxes. Prices don’t include shipping costs and customs taxes or fares.' | translate}}</p>
    </div>
  </div>

  <div class="divs-horizontally">
    <div class="pricing-card" style="width: 100%; margin-right: 2%; text-align: center;">
      <img src="../../../../assets/pricing/pricing-money-icon.svg">
      <p class="pricing-card-text-1">{{'On-demand Credits' | translate}}</p>

      <div style="display: flex; justify-content: space-between;">
        <section>
          <mat-checkbox [checked]="true" [disabled]="true">{{'credits-1' | translate}}</mat-checkbox>
        </section>
        <p class="pricing-card-text-3">{{'credits-1-price'| translate}}</p>
      </div>
      <div style="display: flex; justify-content: space-between;">
        <section>
          <mat-checkbox [checked]="true" [disabled]="true">{{'credits-2' | translate}}</mat-checkbox>
        </section>
        <p class="pricing-card-text-3">{{'credits-2-price'| translate}}</p>
      </div>
      <div style="display: flex; justify-content: space-between;">
        <section>
          <mat-checkbox [checked]="true" [disabled]="true">{{'credits-3' | translate}}</mat-checkbox>
        </section>
        <p class="pricing-card-text-3">{{'credits-3-price'| translate}}</p>
      </div>
      <div style="display: flex; justify-content: space-between;">
        <section>
          <mat-checkbox [checked]="true" [disabled]="true">{{'credits-4' | translate}}</mat-checkbox>
        </section>
        <p class="pricing-card-text-3">{{'credits-4-price'| translate}}</p>
      </div>
      <div style="display: flex; justify-content: space-between;">
        <section>
          <mat-checkbox [checked]="true" [disabled]="true">{{'credits-5' | translate}}</mat-checkbox>
        </section>
        <p class="pricing-card-text-3">{{'credits-5-price'| translate}}</p>
      </div>
      <div style="background: #F5F5F5; border-radius: 6px; padding: 4%; margin-top: 20px;">
        <p class="pricing-card-text-2" style="font-weight: bold">{{'With 100 credits you can get 12 pollen tests or 10 crystals tests or a mix of both.' | translate}}</p>
      </div>
      <div style="height: 2px; margin-top: 30px; margin-bottom: 30px; padding-right: 30px; padding-left: 30px; background: #E9BD02"></div>

      <p style="font-weight: bold">{{'A subscription plan is also available.' | translate}}</p>
      <p>
        <a href="{{privateUrl}}" style="color: #E9BD02">{{'Log in' | translate}}</a>
        {{'to your account and gain access to all the pricing information or '| translate}}
        <a [routerLink]="languageService.getPath('/pre-orders')" style="color: #E9BD02">{{'Request Pre-orders Info.' | translate}}</a>
      </p>
      <br>
      <p>*{{'Prices don’t include taxes. Credits are valid for one year.' | translate}}</p>

    </div>
    <div class="pricing-card" style="width: 100%; margin-left: 2%; text-align: center;">
      <img src="../../../../assets/pricing/accesories-icon.svg">
      <p class="pricing-card-text-1">{{'Accessories' | translate}}</p>

      <div class="divs-horizontally">
        <div style="display: inline-flex; width: 100%;">
          <img
            width="50px"
            src="../../../../assets/pricing/reactivos.png">
          <div style="padding-left: 20px; text-align: left;">
            <p>{{'Reactives' | translate}}</p>
            <p class="pricing-card-text-3">{{'reactives-price' | translate}}</p>
          </div>
        </div>

        <div style="display: inline-flex; width: 100%;">
          <img
            width="50px"
            src="../../../../assets/pricing/slice.png">
          <div style="padding-left: 20px; text-align: left;">
            <p>{{'Slice' | translate}}</p>
            <p class="pricing-card-text-3">{{'slice-price' | translate}}</p>
          </div>
        </div>
      </div>

      <div class="divs-horizontally">

          <div style="display: inline-flex; width: 100%;">
            <img
              width="50px"
              src="../../../../assets/pricing/agitator.jpg">
            <div style="padding-left: 20px; text-align: left;">
              <p>{{'Thermal stirrer' | translate}}</p>
              <p class="pricing-card-text-3">{{'Thermal stirrer-price' | translate}}</p>
            </div>
          </div>
      </div>

      <div style="height: 2px; margin-top: 30px; margin-bottom: 30px; padding-right: 30px; padding-left: 30px; background: #E9BD02"></div>

      <p>
        <a href="{{privateUrl}}" style="color: #E9BD02">{{'Log in' | translate}}</a>
        {{'to your account and gain access to all the pricing information or '| translate}}
        <a [routerLink]="languageService.getPath('/pre-orders')" style="color: #E9BD02">{{'Request Pre-orders Info.' | translate}}</a>
      </p>
      <br>
      <p>*{{'Prices don’t include taxes. Prices don’t include shipping costs and customs taxes or fares.' | translate}}</p>

    </div>
  </div>
</div>


<ng-template #mobilePricingContent>

  <div class="info-div-mobile">
      <p class="home-info-title-mobile">
        {{'pricing-information'| translate}}
      </p>
      <p class="home-info-body-mobile">
        {{'pricing-title-body-text'| translate}}
      </p>
  </div>

  <!-- Pricing cards -->
  <div class="info-div-mobile">
    <!--  Card 1  -->
    <div class="card-image" style="top: -15px;">
          <img src="../../../../assets/pricing/pricing-card-1.svg">
    </div>
    <p class="text-subtitle-honey text-align-center card-title-text">
          {{'become-client'| translate}}
    </p>
    <p class="body-text text-align-center card-body-text">
          {{'become-client-body-1'| translate}}<button class="no-style-button" style="font-weight: bold; padding: 0px;" (click)="pressInitPack()">{{'become-client-body-2'| translate}}</button> {{'become-client-body-3'| translate}}
          <a [routerLink]="languageService.getPath('/pre-orders')" style="color: black">{{'(Request a Quote)' | translate}}</a>
    </p>
    <div style="padding-top: 40px;">
      <img src="../../../../assets/pricing/pricing-arrow-mobile.svg">
    </div>
    <!--  Card 2  -->
    <div class="card-image" style="top: -15px;">
      <img src="../../../../assets/pricing/pricing-card-2.svg">
    </div>
    <p class="text-subtitle-honey text-align-center card-title-text">
      {{'fill-wallet'| translate}}
    </p>
    <p class="body-text text-align-center card-body-text">
      {{'fill-wallet-body'| translate}}
    </p>
    <p class="body-text text-align-center card-body-text-sub">
      {{'fill-wallet-body-sub'| translate}}
    </p>
    <div style="padding-top: 40px;">
      <img src="../../../../assets/pricing/pricing-arrow-mobile.svg">
    </div>
    <!--  Card 3  -->
    <div class="card-image" style="top: -15px;">
    <img src="../../../../assets/pricing/pricing-card-3.svg">
  </div>
    <p class="text-subtitle-honey text-align-center card-title-text">
      {{'enjoy-honey-ai'| translate}}
    </p>
    <p class="body-text text-align-center card-body-text">
      {{'enjoy-honey-ai-body'| translate}}
    </p>
  </div>

  <!-- Info pricing -->
  <div class="info-pricing-background-mobile" style="
  background: url('assets/pricing/pricing-backgroung.jpg');
  min-height: 600px;
  background-position: 46% 76%;
  background-size: cover;
">
    <div class="pricing-info-on-image pricing-back-content-mobile">
      <p style="color: #F3D02F; font-weight: bold; font-size: 22px;">
        {{'FAQs'| translate}}
      </p>
      <p style="color: white; padding-top: 10px; padding-bottom: 10px; font-size: 20px;">
        {{'info-pricing-1' | translate}}
      </p>
      <div style="background: white; height: 1px;"></div>
      <p style="padding-top: 20px;"><a style="color: white; font-weight: bold; text-underline-position: under;" [routerLink]="languageService.getPath('/FAQs')"> {{'info-pricing-1-link' | translate}}</a></p>

      <p style="color: #F3D02F; font-weight: bold; font-size: 22px; margin-top: 30px;">
        {{'Orders'| translate}}
      </p>
      <p style="color: white; padding-top: 10px; padding-bottom: 10px; font-size: 20px;">
        {{'info-pricing-2' | translate}}
      </p>
      <div style="background: white; height: 1px;"></div>
      <p style="padding-top: 20px;"><a style="color: white; font-weight: bold; text-underline-position: under;" [routerLink]="languageService.getPath('/pre-orders')"> {{'info-pricing-2-link' | translate}}</a></p>
    </div>
  </div>

  <!-- Info boxes pricing -->
  <div id="starter-pack-panel" class="info-div-mobile">
    <div style="width: 100%; align-self: center;">
      <img
        style="max-width: 100%;"
        src="assets/pricing/client-app-{{'lang' | translate}}.png">
    </div>

    <div style="margin: 20px;">
      <div class="pricing-card">
        <p class="pricing-card-text-1" style="padding-bottom: 30px;">{{'init-pack' | translate}}<span style="color: #E9BD02"> {{'price-init' | translate}}</span></p>
        <p class="pricing-card-text-2" style="font-weight: bold;">{{'includes' | translate}}</p>

        <ul class="pricing-card-text-2" style=" text-align: left;">
          <li>{{'Honey.AI device' | translate}}</li>
          <li>{{'Centrifuge' | translate}}</li>
          <li>{{'1x Polarizer' | translate}}</li>
          <li>{{'A calibration glass slide (for the commissioning)' | translate}}</li>
          <li>{{'A verification glass slide with sample(for the commissioning)' | translate}}</li>
          <li>{{'3x custom glass slides for Crystallization rate measurements' | translate}}</li>
          <li>{{'1x custom slides for measuring Pfund color' | translate}}</li>
          <li>{{'1x Glass slides box, 50 units' | translate}}</li>
          <li>{{'1x cover box, 50 units' | translate}}</li>
          <li>{{'1x Pasteur Pipette' | translate}}</li>
          <li>{{'1x laboratory spatula' | translate}}</li>
          <li>{{'Chemicals/Reagents for sample preparation' | translate}}</li>
          <li>{{'User Manual' | translate}}</li>
          <li>{{'Each customer will have access to our customer support team (2-hour installation course)' | translate}}</li>
        </ul>
        <div style="height: 2px; margin-top: 30px; margin-bottom: 30px; padding-right: 30px; padding-left: 30px; background: #E9BD02"></div>
        <p class="pricing-card-text-2" style="font-weight: bold;">{{'Optional' | translate}}:</p>
        <div style="display: flex; justify-content: space-between;">
          <p style="color: grey; text-align: left;">{{'Thermal stirrer' | translate}}</p>
          <p class="pricing-card-text-3" style="min-width: 45px;">+ {{'Thermal stirrer-price'| translate}}</p>
        </div>
      </div>
      <div style="text-align: center; transform: translateY(-30px);">
        <a [routerLink]="languageService.getPath('/pre-orders')">
          <button class="yellow-honey-button">
            {{'pre-orders-button' | translate}}
          </button>
        </a>
      </div>
      <p style="text-align: center;">*{{'Prices don’t include taxes. Prices don’t include shipping costs and customs taxes or fares.' | translate}}</p>
    </div>

    <div class="pricing-card" style="margin: 20px; text-align: center;">
      <img src="../../../../assets/pricing/pricing-money-icon.svg">
      <p class="pricing-card-text-1">{{'On-demand Credits' | translate}}</p>

      <div style="display: flex; justify-content: space-between;">
        <section>
          <mat-checkbox [checked]="true" [disabled]="true">{{'credits-1' | translate}}</mat-checkbox>
        </section>
        <p class="pricing-card-text-3">{{'credits-1-price'| translate}}</p>
      </div>
      <div style="display: flex; justify-content: space-between;">
        <section>
          <mat-checkbox [checked]="true" [disabled]="true">{{'credits-2' | translate}}</mat-checkbox>
        </section>
        <p class="pricing-card-text-3">{{'credits-2-price'| translate}}</p>
      </div>
      <div style="display: flex; justify-content: space-between;">
        <section>
          <mat-checkbox [checked]="true" [disabled]="true">{{'credits-3' | translate}}</mat-checkbox>
        </section>
        <p class="pricing-card-text-3">{{'credits-3-price'| translate}}</p>
      </div>
      <div style="display: flex; justify-content: space-between;">
        <section>
          <mat-checkbox [checked]="true" [disabled]="true">{{'credits-4' | translate}}</mat-checkbox>
        </section>
        <p class="pricing-card-text-3">{{'credits-4-price'| translate}}</p>
      </div>
      <div style="display: flex; justify-content: space-between;">
        <section>
          <mat-checkbox [checked]="true" [disabled]="true">{{'credits-5' | translate}}</mat-checkbox>
        </section>
        <p class="pricing-card-text-3">{{'credits-5-price'| translate}}</p>
      </div>
      <div style="background: #F5F5F5; border-radius: 6px; padding: 4%; margin-top: 20px;">
        <p class="pricing-card-text-2" style="font-weight: bold">{{'With 100 credits you can get 12 pollen tests or 10 crystals tests or a mix of both.' | translate}}</p>
      </div>
      <div style="height: 2px; margin-top: 30px; margin-bottom: 30px; padding-right: 30px; padding-left: 30px; background: #E9BD02"></div>

      <p style="font-weight: bold">{{'A subscription plan is also available.' | translate}}</p>
      <p>
        <a href="{{privateUrl}}" style="color: #E9BD02">{{'Log in' | translate}}</a>
        {{'to your account and gain access to all the pricing information or '| translate}}
        <a [routerLink]="languageService.getPath('/pre-orders')" style="color: #E9BD02">{{'Request Pre-orders Info.' | translate}}</a>
      </p>
      <br>
      <p>*{{'Prices don’t include taxes. Credits are valid for one year.' | translate}}</p>
    </div>

    <div class="pricing-card" style="margin: 20px; text-align: center;">
      <img src="../../../../assets/pricing/accesories-icon.svg">
      <p class="pricing-card-text-1">{{'Accessories' | translate}}</p>
        <div style="display: inline-flex; width: 100%;">
          <img
            width="50px"
            src="../../../../assets/pricing/reactivos.png">
          <div style="padding-left: 20px; text-align: left;">
            <p>{{'Reactives' | translate}}</p>
            <p class="pricing-card-text-3">{{'reactives-price' | translate}}</p>
          </div>
        </div>

        <div style="display: inline-flex; width: 100%;">
          <img
            width="50px"
            src="../../../../assets/pricing/slice.png">
          <div style="padding-left: 20px; text-align: left;">
            <p>{{'Slice' | translate}}</p>
            <p class="pricing-card-text-3">{{'slice-price' | translate}}</p>
          </div>
        </div>

        <div style="display: inline-flex; width: 100%;">
          <img
            width="50px"
            src="../../../../assets/pricing/agitator.jpg">
          <div style="padding-left: 20px; text-align: left;">
            <p>{{'Thermal stirrer' | translate}}</p>
            <p class="pricing-card-text-3">{{'Thermal stirrer-price' | translate}}</p>
          </div>
        </div>

      <div style="height: 2px; margin-top: 30px; margin-bottom: 30px; padding-right: 30px; padding-left: 30px; background: #E9BD02"></div>

      <p>
        <a href="{{privateUrl}}" style="color: #E9BD02">{{'Log in' | translate}}</a>
        {{'to your account and gain access to all the pricing information or '| translate}}
        <a [routerLink]="languageService.getPath('/pre-orders')" style="color: #E9BD02">{{'Request Pre-orders Info.' | translate}}</a>
      </p>
      <br>
      <p>*{{'Prices don’t include taxes. Prices don’t include shipping costs and customs taxes or fares.' | translate}}</p>
    </div>
  </div>

</ng-template>
