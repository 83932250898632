<mat-accordion>
  <mat-expansion-panel (closed)="panelOpenState1 = '#c8c8c8'"
                       (opened)="panelOpenState1 = '#ffffff'"
                       class="home-what-is-expansion-panel margin-top mat-elevation-z0"
                       style="border-bottom-color: {{panelOpenState1}};">
    <mat-expansion-panel-header  class=" toggle-specific-class mat-expansion-panel-header-card">
      <mat-panel-title>
        <img
             src="assets/home/what-is-button-1.svg">
        <span class="accordion-header-text" style="font-size: {{titleFont}}px"> {{'An automated scanning light microscope'| translate}} </span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p class="what-is-expansion-panel-content" [style.font-size]="isMobileResolution? '15px': ''">{{ 'Honey.AI system works with a design-owned light microscope robot that is capable to automatically scan the whole surface of the glass slide, which cointains the honey sediment. The equipment is completely autonomous, and includes additional accessories to allow the visualization of sugar crystals within the honey, and yeasts / bacteria. Honey.AI equipment is very accurate and reliable, with mechanical movement precision up to 1 micron.' | translate}}</p>
  </mat-expansion-panel>
  <mat-expansion-panel (closed)="panelOpenState2 = '#c8c8c8'"
                       (opened)="panelOpenState2 = '#ffffff'"
                       class="home-what-is-expansion-panel mat-elevation-z0"
                       style="border-bottom-color: {{panelOpenState2}};">
    <mat-expansion-panel-header class=" toggle-specific-class mat-expansion-panel-header-card">
      <mat-panel-title>
        <img
             src="assets/home/what-is-button-2.svg">
        <span class="accordion-header-text" style="font-size: {{titleFont}}px"> {{'Image Processing and Artificial Intelligence'| translate}} </span>
      </mat-panel-title>
    </mat-expansion-panel-header>
      <p class="what-is-expansion-panel-content" [style.font-size]="isMobileResolution? '15px': ''">{{'Honey.AI works thanks to the inclusion of image processing algorithms and different deep learning neural networks. The artifical intelligence of Honey.AI has been widely trained since 2019 with hundreds of honey samples from different geographical and botanical origins to be able to detect, classify and count the pollen grains contained in the sample. The recognition expertise of Honey.AI is improved every day with new botanical species from all over the world. Visit our FAQs section to know the available species already trained.' | translate }} 
      <a href="https://honey-ai.com/FAQs">{{ 'Frequently Asked Questions' | translate }}</a>
      {{'Honey.AI works thanks 2' | translate }} 
      <a href="mailto:info@honey-ai.com">{{'Honey.AI works thanks 3' | translate }}</a>
    </p>
  </mat-expansion-panel>
  <mat-expansion-panel (closed)="panelOpenState3 = '#c8c8c8'"
                       (opened)="panelOpenState3 = '#ffffff'"
                       class="home-what-is-expansion-panel mat-elevation-z0"
                       style="border-bottom-color: {{panelOpenState3}};">
    <mat-expansion-panel-header class=" toggle-specific-class mat-expansion-panel-header-card">
      <mat-panel-title>
        <img
             src="assets/home/what-is-button-3.svg">
        <span class="accordion-header-text" style="font-size: {{titleFont}}px"> {{'Types of honey analysis'| translate}} </span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p class="what-is-expansion-panel-content" [style.font-size]="isMobileResolution? '15px': ''">
      {{'what-is-types-of-honey-1' | translate }}

      <a target="_blank" href="https://www.linkedin.com/company/microfy-systems">LinkedIn</a>
      
      {{'what-is-types-of-honey-2' | translate }}
    </p>
  </mat-expansion-panel>
  <mat-expansion-panel (closed)="panelOpenState4 = '#c8c8c8'"
                       (opened)="panelOpenState4 = '#ffffff'"
                       class="home-what-is-expansion-panel mat-elevation-z0"
                       style="border-bottom-color: {{panelOpenState4}};">
    <mat-expansion-panel-header class=" toggle-specific-class mat-expansion-panel-header-card">
      <mat-panel-title>
        <img
             src="assets/home/what-is-button-4.svg">
        <span class="accordion-header-text" style="font-size: {{titleFont}}px"> {{'Overall performance of the system, validated by an accredited expert laboratory'| translate}} </span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p class="what-is-expansion-panel-content" [style.font-size]="isMobileResolution? '15px': ''">{{ 'Honey.AI is a very easy-to-use system. No need of an expert, nor a lab technician is required. The only manual step required is the sample preparation, which is carried out in less than 15 minutes with a simple mixing and centrifugation (the centrifuge is included within the Honey.AI \"Welcome Pack\"). The repeteability of the system assures 0 to 2% variation of pollen counting between two consecutive readings. The overall precision of the results provided by Honey.AI is being validated by the Polytechnic University of Valencia (Spain), a worldwide reference laboratory in Honey quality matters. The detailed technical report with conclusions will be available soon in our website for direct public download.' | translate }}
      
      <a href="https://honey-ai.com/pricing">Welcome Pack</a>
      {{ 'Honey.AI is a very easy-to-use 2' | translate }}
      <a target="_blank" href="https://www.aoac.org/">AOAC International.</a>
    </p>
  </mat-expansion-panel>
</mat-accordion>
