<div class="carousel">

    <div class="hex-container-image">
        <!-- <div class="image-showing" [ngStyle]="{'background-image': 'url(' + getImgPath(currentSlide) + ')'}"> -->
            <img [src]="getImgPath(currentSlide)" />
    </div>

    <div class="buttons-container">
        <div class="button" (click)="onNextClick()">
            <img src="../../../../../assets/commons/icons/left-yellow-arrow.png" />
        </div>
        <div class="button" (click)="onPreviousClick()">
            <img src="../../../../../assets/commons/icons/right-yellow-arrow.png" />
        </div>
    </div>
</div>