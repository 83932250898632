<div *ngIf="!isMobileResolution; else mobileNewsContent">
  <div class="news-header">
    <div>
      <div class="news-header-text-div">
        <img style="max-width: 401px;"
             src="assets/news-events/header-text-{{'lang' | translate}}.svg">
        <p style="margin-top: 40px;font-weight: 600; font-size: 20px;line-height: 140%; color: #000000; white-space: pre-line;">{{'news-header' | translate}}</p>
      </div>
      <div >
        <img src="assets/news-events/header-decorator.svg">
      </div>
    </div>
    <div style="width: 100%;">
      <img style="float: right; max-height: 100%; max-width: 100%;"
        src="assets/news-events/header-images.png">
    </div>

    <div class="newsletter-container">

      <div class="newsletter-container__info">
        <div class="newsletter-container__info-img">
          <img src="assets/commons/contact-us/newsletter.png" width="40" alt="Newsletter - icon">
        </div>
        <div class="newsletter-container__info-texts">
          <p>{{ 'knowMoreAboutHoney' | translate}}</p>
          <p class="newsletter-container__info-texts__stayTuned">{{ 'stayTuned' | translate}}</p>
        </div>
      </div>

      <div class="newsletter-container__form">
        <form class="newsletter-form" [formGroup]="newsletterForm" (ngSubmit)="onSubmitNewsletter()">
          <input class="newsletter-form__input" placeholder="E-mail" type="email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$" formControlName="email" required>
          <button class="newsletter-form__button" [disabled]="!newsletterForm.valid" type="submit">{{ 'Send' | translate}}</button>
          <label [style.visibility]="thanksSubmitNewsletterVisibility" class="newsletter-form__thanks" for="checkbox">{{'thanks!' | translate}}</label>
        </form>
      </div>

    </div>
  </div>

  <div>
    <div *ngFor="let newEvent of news;">
      <app-news-component-template
        [index]="newEvent.index"
        [href]="newEvent.href"
        [hiddenButton]="newEvent.hiddenButton">
      </app-news-component-template>
    </div>
  </div>
</div>

<ng-template #mobileNewsContent>
  <div style="background: #F3D02F;">
    <img
      style="width: 100%;"
      src="assets/news-events/header-mobile-{{'lang' | translate}}.jpg">
  </div>

  <div *ngFor="let newEvent of news;">
      <app-news-component-template
        [index]="newEvent.index"
        [href]="newEvent.href"
        [hiddenButton]="newEvent.hiddenButton">
      </app-news-component-template>
  </div>

</ng-template>
