import { Component, OnInit } from '@angular/core';
import { TeamMemberPopupComponent } from './team-member-popup/team-member-popup.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicationStateService } from '../../../service/application-state.service';
import { LanguageService } from 'src/app/service/LanguageService.service';

export interface DialogData {
  id: string;
  name: string;
  title: string;
  description: string;
  linkedin: string;
}

@Component({
  selector: 'app-about-us-tab',
  templateUrl: './about-us-tab.component.html',
  styleUrls: ['./about-us-tab.component.css'],
})
export class AboutUsTabComponent implements OnInit {
  imgPartnersPath = '../../../../assets/about-us/collaborations-and-partners/';
  partnersAssets = [
    { path: 'vicomtech.png', url: 'https://www.vicomtech.org/es/' },
    { path: 'ikerlan.png', url: 'https://www.ikerlan.es/' },
    { path: 'cvc.png', url: 'https://www.cvc.uab.es/' },
    { path: 'iti.png', url: 'https://www.iti.es/' },
    { path: 'accio.png', url: 'https://www.accio.gencat.cat/ca/inici' },
    { path: 'nvidia.png', url: 'https://www.nvidia.com/en-gb/startups/' },
    { path: 'cdti.png', url: 'https://www.cdti.es/' },
    { path: 'aws.png', url: 'https://aws-startup-lofts.com/emea/' },
    {
      path: 'european-commision.png',
      url: 'https://eismea.ec.europa.eu/about-eismea_en',
    },
    {
      path: 'bioeconomy-ventures.png',
      url: 'https://www.bioeconomyventures.eu/',
    },
    {
      path: 'cluster-alimentario-de-galicia.png',
      url: 'https://www.clusteralimentariodegalicia.org/',
    },
    { path: 'forward-fooding.png', url: 'https://forwardfooding.com/' },
    { path: 'agrobofood.png', url: 'https://agrobofood.eu/' },
    {
      path: 'iqs-next-tech.png',
      url: 'https://iqs.edu/es/iqs/campus-life/iqs-tech-factory/',
    },
    { path: 'eit-food.png', url: 'https://www.eitfood.eu/' },
    { path: 'eurecat.png', url: 'https://eurecat.org/es/' },
  ];

  dictMembers = {
    2: {
      id: 'iratxe',
      name: 'Iratxe Perales',
      linkedin: 'https://www.linkedin.com/in/iratxe-perales-arce-/',
    },
    3: {
      id: 'edu',
      name: 'Eduard López',
      linkedin: 'https://www.linkedin.com/company/microfy-systems/',
    },
    6: {
      id: 'asma',
      name: 'Asma Ghorab',
      linkedin: 'https://www.linkedin.com/in/asma-ghorab-02797092/',
    },
    7: {
      id: 'alvaro',
      name: 'Álvaro Suárez',
      linkedin: 'https://es.linkedin.com/in/%C3%A1lvaro-su%C3%A1rez-marrero-9b3b621a1',
    },
    8: {
      id: 'julian',
      name: 'Julian Rodriguez',
      linkedin: 'https://www.linkedin.com/in/julianromendoza/',
    },
    9: {
      id: 'alberto',
      name: 'Alberto Fernández',
      linkedin: 'https://www.linkedin.com/in/alberto-fernández-álvarez-98b42b288',
    },
    10: {
      id: 'miguel',
      name: 'Miguel Moreno',
      linkedin: 'https://es.linkedin.com/in/miguel-moreno-342b74182',
    },
    11: {
      id: 'naoufal',
      name: 'Naoufal Amrani',
      linkedin: 'https://www.linkedin.com/in/naoufal-amrani-8a262359',
    },
  };

  public isMobileResolution: boolean;

  constructor(
    public dialog: MatDialog,
    public languageService: LanguageService,
    private applicationStateService: ApplicationStateService
  ) {
    this.isMobileResolution = applicationStateService.getIsMobileResolution();
  }

  ngOnInit(): void {
    // Scroll to top
    window.scroll({ top: 0, left: 0 });
  }

  openDialog(memberId: number) {
    const config = {
      data: {
        id: this.dictMembers[memberId]['id'],
        name: this.dictMembers[memberId]['name'],
        title: this.dictMembers[memberId]['id'] + '-title',
        description: this.dictMembers[memberId]['id'] + '-description',
        linkedin: this.dictMembers[memberId]['linkedin'],
      },
    };

    this.dialog.open(TeamMemberPopupComponent, config);
  }
}
