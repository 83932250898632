import { Component, OnInit } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {ApplicationStateService} from "../../../service/application-state.service";

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  publicUrl = environment.publicUrl;
  public isMobileResolution: boolean;

  constructor(private applicationStateService: ApplicationStateService) {
    this.isMobileResolution = applicationStateService.getIsMobileResolution();
  }

  ngOnInit(): void {
    // Scroll to top
    window.scroll({ top: 0, left: 0 });
  }

}
